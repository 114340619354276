import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import moment from 'moment';
import styled from 'styled-components';

import subSTR from '../../../utils/hideText';
import currencyTransform from '../../../utils/currency';
import Breadcrumb from '../../../components/Breadcrumb/BreadcrumbType';
import DefaultTable from '../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../components/FilterBuilder/FilterMember/FilterMember';
import MemberBadge from '../../../components/Badge/MemberBadge/MemberBadge';
import currencyColor from "../../../utils/numericColor";
import { getSession } from '../../../utils/login';
import config from "config/default.json"

const TableContain = styled.div``;

const CustomTable = styled.table`
    font-size: 12px;
    border-collapse: separate;
    border-spacing: 0 5px;
    thead {
        background-color: #313131;
        color: #FFF;
    }
    thead th {
        border-top: unset;
        border-bottom: unset;
    }
    th {
        text-align: center;
        padding: 5px;
        vertical-align: middle !important;
    }
    
    tbody tr {
        background-color: #FFF;
    }

    thead tr:first-child th:first-child {
        border-radius: 0.5em 0 0 0.5em;
    }
    thead tr:first-child th:last-child {
        border-radius: 0 0.5em 0.5em 0;
    }
    tbody tr td:first-child{
        border-radius: 0.5em 0 0 0.5em;
    }
    tbody tr td:last-child {
        border-radius: 0 0.5em 0.5em 0;
    }

    tbody tr td {
        vertical-align: middle;
        text-align: center;
        padding: .35rem;
        border-top: unset;
    }

    th:nth-child(4)
    {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        border-right: 1px solid;
    }

    th:nth-child(5)
    {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
    }

    th:nth-child(6)
    {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        border-left: 1px solid;
    }

    th.th-radius{
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        border: 1px solid;
    }
`;

const TrTotal = styled.tr`
    line-height: 2;
    td {
        border-top: 1px solid #af8c50 !important;
        border-bottom: 1px solid #af8c50;
    }
    td:first-child {
        color: #af8c50;
        font-weight: 500;
        border-top: 1px solid #af8c50;
        border-bottom: 1px solid #af8c50;
        border-left: 1px solid #af8c50;
    }
    td:last-child {
        border-top: 1px solid #af8c50;
        border-bottom: 1px solid #af8c50;
        border-right: 1px solid #af8c50;
    }
    
`;

function MemberReport({ t }) {

    const [memberList, setMemberList] = useState([]);
    const [dataTotal, setDataTotal] = useState([]);
    const [stats, setStats] = useState([]);
    const [month, setMonth] = useState(moment().format('MMYYYY'));
    const [sortType, setSortType] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [search, setSearch] = useState('');
    const [tabActive, setTabActive] = useState('HIALLBET')
    // const [ page, setPage ] = useState(0);

    useEffect(function () {
        const session = getSession();
        if (session) {
            _fetchMemberList(session.agentCode);
        }
    }, [month, sortType, search]);

    async function _fetchMemberList(agentCode) {
        console.log(month)
        const bodySet = {
            agentCode: agentCode,
            date: month,
            sortBy: sortBy,
            sortType: sortType,
            search: search
        };
        const response = await fetch(config.URL_API + '/getMemberListReport', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            if (data.response_result) {
                const result = data.response_data.memberList;
                if (result) {
                    const total = data.response_data.total;
                    setDataTotal(total)
                    setMemberList(result);
                } else {
                    setMemberList(new Array());
                }
            }
        }
    }

    const actionMonth = (e) => {
        setMonth(e.target.value)
    }

    function _searchText(keyword) {
        if (keyword.length > 2) {
            setSearch(keyword)
        } else if (keyword.length === 0) {
            setSearch("")
        }
    }

    const sortby = (type) => {
        const sort = sortType === 1 ? -1 : 1
        setSortType(sort)
        setSortBy(type)
    }

    const activeBtn = (e) => {
        setTabActive(e)
        // console.log(e)
        // if (e === "HIALLBET") {
        //   _fetchDataList(agentCode)
        // } else if (e === "HISLOT") {
        //   _fetchDataListSlot(agentCode)
        // }
    }

    return (
        <div>
            <Breadcrumb title={'Member Report'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
            <FilterMember data={stats} isShowDate={false} isShowLevel={true} isShowMonth={true} actionMonth={actionMonth} search={(keyword) => _searchText(keyword)} t={t} />
            <TableContain className={'table-responsive'}>
                <CustomTable className={'table'}>
                    <thead className="text-center">
                        <tr>
                            <th rowSpan="2" width={'50px'}>{t('no')}</th>
                            <th rowSpan="2">{t('level')}</th>
                            <th rowSpan="2">{t('user')}</th>
                            <th rowSpan="2" onClick={() => sortby('deposit')}>{t('deposit')}</th>
                            <th colSpan="4">{t('winloss')}</th>
                            <th rowSpan="2">{t('bonus')}</th>
                            <th rowSpan="2">{t('winloss-return')}</th>
                            <th rowSpan="2" onClick={() => sortby('total')}>{t('total')}</th>
                            <th rowSpan="2">{t('reward')}</th>
                        </tr>
                        <tr>
                            <th className="th-radius" onClick={() => sortby('winloseSport')}>{t('sport')}</th>
                            <th className="th-radius" onClick={() => sortby('winloseCasino')}>{t('casino')}</th>
                            <th className="th-radius" onClick={() => sortby('winloseGame')}>{t('games')}</th>
                            <th className="th-radius" onClick={() => sortby('winloseEsport')}>{t('esport')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            memberList.map((m, mIndex) => (
                                <tr key={mIndex}>
                                    <td>{mIndex + 1}</td>
                                    <td><MemberBadge text={m.level} color={m.levelColor} /></td>
                                    <td className={'border-left border-right'}>{subSTR(m.username)}</td>
                                    <td className={m.deposit < 0 ? 'text__danger' : ''} >{currencyTransform(m.deposit)}</td>
                                    <td className={m.winloseSport < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseSport)}</td>
                                    <td className={m.winloseCasino < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseCasino)}</td>
                                    <td className={m.winloseGame < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseGame)}</td>
                                    <td className={m.winloseEsport < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseEsport)}</td>
                                    <td>{currencyTransform(m.bonus)}</td>
                                    <td className={m.winloseReturn < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseReturn)}</td>
                                    <td className={m.total < 0 ? 'text__danger' : ''} >{currencyTransform(m.total)}</td>
                                    <td>{currencyTransform(m.reward)}</td>
                                </tr>
                            ))
                        }
                        <TrTotal key={'total'}>
                            <td colSpan={2}>{t('total')}</td>
                            <td>{dataTotal.memberShip}</td>
                            <td className={dataTotal.deposit < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.deposit)}</td>
                            <td className={dataTotal.winloseSport < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseSport)}</td>
                            <td className={dataTotal.winloseCasino < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseCasino)}</td>
                            <td className={dataTotal.winloseGame < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseGame)}</td>
                            <td className={dataTotal.winloseEsport < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseEsport)}</td>
                            <td>{currencyTransform(dataTotal.bonus)}</td>
                            <td className={dataTotal.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseReturn)}</td>
                            <td className={dataTotal.total < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.total)}</td>
                            <td>{currencyTransform(dataTotal.reward)}</td>
                        </TrTotal>
                    </tbody>
                </CustomTable>
            </TableContain >
            {/* <DefaultTable t={t} headers={columnList}>
                {
                    memberList.map((m, mIndex) => (
                        <tr key={mIndex}>
                            <td>{mIndex + 1}</td>
                            <td><MemberBadge text={m.level} color={m.levelColor} /></td>
                            <td className={'border-left border-right'}>{subSTR(m.username)}</td>
                            <td className={m.deposit < 0 ? 'text__danger' : ''} >{currencyTransform(m.deposit)}</td>
                            <td className={m.winloseSport < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseSport)}</td>
                            <td className={m.winloseCasino < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseCasino)}</td>
                            <td className={m.winloseGame < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseGame)}</td>
                            <td className={m.winloseEsport < 0 ? 'text__danger' : ''} >{currencyTransform(m.winloseEsport)}</td>
                            <td>{currencyTransform(m.bonus)}</td>
                            <td>{currencyTransform(m.reward)}</td>
                            <td className={m.total < 0 ? 'text__danger' : ''} >{currencyTransform(m.total)}</td>
                        </tr>
                    ))
                }
                <TrTotal key={'total'}>
                    <td colSpan={2}>{t('total')}</td>
                    <td>{dataTotal.memberShip}</td>
                    <td className={dataTotal.deposit < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.deposit)}</td>
                    <td className={dataTotal.winloseSport < 0 ? 'text__danger' : ''}>{currencyColor(dataTotal.winloseSport)}</td>
                    <td className={dataTotal.winloseCasino < 0 ? 'text__danger' : ''}>{currencyColor(dataTotal.winloseCasino)}</td>
                    <td className={dataTotal.winloseGame < 0 ? 'text__danger' : ''}>{currencyColor(dataTotal.winloseGame)}</td>
                    <td className={dataTotal.winloseEsport < 0 ? 'text__danger' : ''}>{currencyColor(dataTotal.winloseEsport)}</td>
                    <td>{currencyTransform(dataTotal.bonus)}</td>
                    <td>{currencyTransform(dataTotal.reward)}</td>
                    <td className={dataTotal.total < 0 ? 'text__danger' : ''}>{currencyColor(dataTotal.total)}</td>
                </TrTotal>
            </DefaultTable> */}
        </div>
    );
}

export default MemberReport;