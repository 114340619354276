import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { detailQuestion } from '../../utils/question';
import SearchBox from '../../components/SearchBox/SearchBox';
import config from "config/default.json";

const BannerHelp = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  background-image: linear-gradient(#fff, transparent, #bdbdbd80);
  border-radius: .25rem;
  margin-top: 10px;
  @media (max-width: 767px) {
    display: block;
    background-image: unset;
    height: 100%;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding-bottom: 30px;
    margin-bottom: 20px;
  }
`;

const ImgBanner = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  > img {
    width: 40%;
  }
  @media (max-width: 767px) {
    > img {
      width: 100%;
    }
  }
`;

const TextBanner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  z-index: 1;
  > div input {
    margin: 5px 0px 0 0;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    // width: 100%;
  }
  @media (max-width: 767px) {
    position: relative;
    width: 100%;
  }
`;

const BannerHelpText = styled.div`
  font-size: 36px;
`;

const Lineadd = styled.div`
  display: none;
`;

const CustomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  width: 140px;
  margin-top: 15px;
  border-radius: 1rem;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
  padding: 5px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;

const RowMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 3px;
  border-bottom: 1px solid rgba(0,0,0,.1);
  margin-bottom: 15px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const ButtonBack = styled.div`
  margin-right: 20px;
`;

const CustomButtonBack = styled.button`
  font-size: 14px;
  color: #828282;
  border-color: transparent;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.15);
  margin-left: 2px;
  height: 100%;
  padding: 0.35rem 0.75rem;
  img {
    width: 6px;
    transform: rotate(180deg);
    margin-right: 10px;
    margin-top: -2px;
  }
`;

const TopicDetail = styled.div`
  font-size: 20px;
`;

const ListTopicDetail = styled.div`
  font-size: 17px;
  text-indent: 20px;
`;

const DetailText = styled.div`
  text-indent: 20px;
  font-size: 16px;
  line-height: 1.8;
`;

const ColLeft = styled.div`
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
  }
`;

const ColRight = styled.div`
  font-size: 11px;
  text-align: center;
  margin: auto;
  img {
    width: 80%;
  }
  @media(min-width: 768px) and (max-width: 1023px) {
    flex: 0 0 100%;
    max-width: 70%;
    margin-top: 50px;
    padding-top: 40px;
    border-top: 1px solid #e2e2e2;
    img {
      width: 40%;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const ColRightText = styled.div`
  margin-top: 10px;
`;

const ColBackMB = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    padding: 20px 3px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-bottom: 15px;
  }
`;

function DetailQuestion({ t, language }) {
  const [data, setData] = useState('')
  const [indexs, setIndex] = useState(0)
  const [no, setNo] = useState(0)
  const [lang, setLang] = useState('')
  const [title, setTitle] = useState('')
  const [list, setList] = useState('')

  useEffect(function () {
    getSearch(window.location.search)

  }, []);

  function getSearch(text) {
    const data = text.split("&");
    const id = data[0].split("?id=");
    const no = data[1].split("no=");
    const index = detailQuestion.findIndex(m => m.id === id[1])
    setData(detailQuestion[index].contentDetail[parseInt(no[1])][language])
    setNo(parseInt(no[1]))
    setIndex(index)
    setLang(language)
    setTitle(detailQuestion[index].nameTopic)
    setList(detailQuestion[index].listTopic[parseInt(no[1])])
  }

  const _openLink = type => {
    if (type === 'line') {
      window.open(config.CONTACT_LINE, '_blank');
      return;
    }
  };

  function createMarkup(htmls) {
    return { __html: htmls };
  }

  function changedLang() {
    setData(detailQuestion[indexs].contentDetail[no][language])
    setLang(language)
    setTitle(detailQuestion[indexs].nameTopic)
    setList(detailQuestion[indexs].listTopic[no])
  }

  if (language !== lang) {
    changedLang()
  }

  return (
    <div className={'overflowpage'}>
      <BannerHelp>
        <ImgBanner><img src={"/support/banner.png"} alt={"support"} /></ImgBanner>
        <TextBanner>
          <div>
            <BannerHelpText>
              <span>{t('help-support')}</span>
            </BannerHelpText>
            <SearchBox placeholder={t('search')} />
          </div>
        </TextBanner>
        <Lineadd>
          <CustomButton onClick={() => _openLink('line')}>
            <img src={'/icon/icon_line.svg'} alt={'icon'} width={'25px'} className="mr-2" />
            <span>@hiaff</span>
          </CustomButton>
        </Lineadd>
      </BannerHelp>
      <RowMenu>
        <div className={'d-flex'}>
          <ButtonBack>
            <Link to={'/support'}>
              <CustomButtonBack type={'button'} className={'btn'} >
                <img src={'/support/icon/arow.svg'} alt={"icon-support"} />
                {t('back')}
              </CustomButtonBack>
            </Link>
          </ButtonBack>
        </div>
      </RowMenu>

      <div className={'container'}>
        <div className={'row p-0'}>
          <ColLeft className={'col-md-9'}>
            <TopicDetail>
              {t(title)}
            </TopicDetail>
            <ListTopicDetail>
              {t(list)}
            </ListTopicDetail>
            <DetailText dangerouslySetInnerHTML={createMarkup(data)} />
          </ColLeft>
          <ColRight className={'col-md-3'}>
            <div>
              <img src={'/support/qrcode.png'} alt={"qrcode"} className={'img-fluid'} />
            </div>
            <ColRightText>
              <div className={'col-md-12'}>Add LINE Friends via QR Code</div>
              <div className={'col-md-12'}>Open the friends tab in your LINE app,
              tap the add friends icon in the top right, select "QR Code",
              and then scan this QR Code</div>
            </ColRightText>
          </ColRight>

          <ColBackMB className={'col-12'}>
            <ButtonBack>
              <Link to={'/support'}>
                <CustomButtonBack type={'button'} className={'btn'} >
                  <img src={'/support/icon/arow.svg'} alt={"icon-support"} />
                  {t('back')}
                </CustomButtonBack>
              </Link>
            </ButtonBack>
            <div>
              <CustomButton onClick={() => _openLink('line')}>
                <img src={'/icon/icon_line.svg'} alt={'icon'} width={'25px'} className="mr-2" />
                <span>@hiaff</span>
              </CustomButton>
            </div>
          </ColBackMB>
        </div>
      </div>
    </div>

  )
}

export default DetailQuestion;