import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import styled from 'styled-components';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import DefaultTable from '../../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../../components/FilterBuilder/FilterMember/FilterMember';
import { getSession } from '../../../../utils/login';
import currencyTransform from "../../../../utils/currency";
import currencyColor from "../../../../utils/numericColor";
import Back from '../../../../components/Button/Back/Back';
import { menuAgent } from '../../../../utils/menuList'
import config from "config/default.json"

const TrTotal = styled.tr`
  line-height: 2;
  td {
      border-top: 1px solid #af8c50 !important;
      border-bottom: 1px solid #af8c50;
  }
  td:first-child {
      color: #af8c50;
      font-weight: 500;
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-left: 1px solid #af8c50;
  }
  td:last-child {
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-right: 1px solid #af8c50;
  }
`;

const columnList = [
  { title: "no", width: "auto" },
  { title: "web", width: "auto" },
  { title: "trans-in", width: "auto" },
  { title: "trans-out", width: "auto" },
  { title: "turnover", width: "auto" },
  { title: "winloss", width: "auto" },
  { title: "bonus", width: "auto" },
  { title: "winloss-return", width: "auto" },
  { title: "total", width: "auto" }
];

function SubAffList ({t}) {

  const [ dataList, setDataList ] = useState([]);
  const [ dataTotal, setDataTotal ] = useState([]);
  const [ stats, setStats ] = useState([]);
  const [ date, setDate ] = useState([]);
  const [ filter, setFilter ] = useState([]);
  const [ agentCode, setAgentCode ] = useState('');
  const [menu, setMenu] = useState('');
  // const [ page, setPage ] = useState(0);

  useEffect(() => {
    const session = getSession();
    if (session) {
      setFilter(session)
      _fetchDataList()
    }
  }, []);

  async function _fetchDataList () {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let username = params.get('username');
    let agentCode = params.get('agentCode');
    let date = params.get('date');
    setMenu(menuAgent(search , false))
    setAgentCode(agentCode)
    setDate(date)
    const bodySet = {
        agentCode: agentCode,
        username: username,
        dateDetail: date
      };
    const response =  await fetch(config.URL_API + '/getDetailActivityReportWebDetail', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.webList;
        const total = data.response_data.total;
        setDataList(result);
        setDataTotal(total)
      }
    }
  }
  console.log(agentCode)
  return (
      <div>
        <Breadcrumb title={'Detailed Activity Report'}  menu={menu}/>
        <FilterMember t={t} data={stats} isShowDate={false} isShowLevel={true}/>
        <Back text={t('back')} link={`/report/sub-affiliate-activity-eachdate?agentCode=${agentCode}&date=${date}`} />
        <DefaultTable t={t} headers={columnList}>
          {
            dataList.map((m, mIndex) => (
                <tr key={mIndex}>
                  <td>{mIndex+1}</td>
                  <td><img src={m.webImage} className="rounded" width="70px"/></td>
                  <td>{currencyTransform(m.transferIn)}</td>
                  <td>{currencyTransform(m.transferOut)}</td>
                  <td>{currencyTransform(m.turnover)}</td>
                  <td>{currencyColor(m.winlose)}</td>
                  <td>{currencyColor(m.bonus)}</td>
                  <td>{currencyColor(m.winloseReturn)}</td>
                  <td>{currencyColor(m.total)}</td>
                </tr>
            ))
          }
        <TrTotal>
            <td colSpan={2}>{t('total')}</td>
            <td>{currencyTransform(dataTotal.transferIn)}</td>
            <td>{currencyTransform(dataTotal.transferOut)}</td>
            <td>{currencyTransform(dataTotal.turnover)}</td>
            <td>{currencyColor(dataTotal.winlose)}</td>
            <td>{currencyColor(dataTotal.bonus)}</td>
            <td>{currencyColor(dataTotal.winloseReturn)}</td>
            <td>{currencyColor(dataTotal.total)}</td>
          </TrTotal>
        </DefaultTable>
      </div>
  );
}

export default SubAffList;