import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';

const TableContain = styled.div``;

const CustomTable = styled.table`
    font-size: 12px;
    border-collapse: separate;
    border-spacing: 0 5px;
    thead {
        background-color: #313131;
        color: #FFF;
    }
    thead th {
        border-top: unset;
        border-bottom: unset;
    }
    th {
        text-align: center;
        padding: 5px;
        vertical-align: middle !important;
    }
    
    tbody tr {
        background-color: #FFF;
    }

    thead tr:first-child th:first-child {
        border-radius: 0.5em 0 0 0.5em;
    }
    thead tr:first-child th:last-child {
        border-radius: 0 0.5em 0.5em 0;
    }
    tbody tr td:first-child{
        border-radius: 0.5em 0 0 0.5em;
    }
    tbody tr td:last-child {
        border-radius: 0 0.5em 0.5em 0;
    }

    tbody tr td {
        vertical-align: middle;
        text-align: center;
        padding: .35rem;
        border-top: unset;
    }

    th:nth-child(6)
    {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        border-right: 1px solid;
    }

    th:nth-child(7)
    {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
    }

    th:nth-child(8)
    {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        border-left: 1px solid;
    }

    th.th-radius{
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        border: 1px solid;
    }
`;

function ReportDepositTable({ children, t }) {
    return (
        <TableContain className={'table-responsive'}>
            <CustomTable className={'table'}>
                <thead className="text-center">
                    <tr>
                        <th rowSpan="2">{t('date')}</th>
                        <th rowSpan="2">{t('hits')}</th>
                        <th rowSpan="2">{t('signup')}</th>
                        <th rowSpan="2">{t('first-time-depositors')}</th>
                        <th rowSpan="2">{t('active-players')}</th>
                        <th rowSpan="2">{t('deposit')}</th>
                        <th colSpan="4">{t('winloss')}</th>
                        <th rowSpan="2">{t('bonus')}</th>
                        <th rowSpan="2">{t('winloss-return')}</th>
                        <th rowSpan="2">{t('net-winloss')}</th>
                        <th rowSpan="2">{t('first-deposit-revenue')}</th>
                        <th rowSpan="2">{t('revenue')}</th>
                        <th rowSpan="2">{t('reward')}</th>
                    </tr>
                    <tr>
                        <th className="th-radius">{t('sport')}</th>
                        <th className="th-radius">{t('casino')}</th>
                        <th className="th-radius">{t('games')}</th>
                        <th className="th-radius">{t('esport')}</th>
                    </tr>
                </thead>

                <tbody>
                    {children}
                </tbody>
            </CustomTable>
        </TableContain>
    );
}

ReportDepositTable.propTypes = {
    children: PropType.node,
    headers: PropType.array.isRequired
};

export default ReportDepositTable;