import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const DateTitle = styled.div`
    font-size: 11px;
    padding-right: 10px;
    @media (max-width: 1023px) {
        font-size: 13px;
    }
`;

const DateHighlight = styled.div`
    color: #AF8C50;
    margin-right: 15px;
    @media (max-width: 1023px) {
        margin-right: 0px;
    }
`;

function DateBox () {

    const [ dateState, setDateState ] = useState('');
    const [ timeState, setTimeState ] = useState('');

    useEffect(function() {
        let date = new Date();
        let time = moment().format("HH:mm:ss Z");
        setDateState(date.toDateString());
        setTimeState(time);
    }, []);

    return (
        <DateTitle>
            <DateHighlight>{ dateState }</DateHighlight>
            <div>{ timeState }</div>
        </DateTitle>
    )
}

export default DateBox;