import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { isLogin } from '../../utils/login';
import styled from 'styled-components';
import LanguageBox from "../../components/Navbar/LanguageBox/LanguageBox";
import ModalContact from '../../components/Modal/ModalContact/ModalContact';

import { login } from '../../utils/login';
import config from "config/default.json";

const LoginContain = styled.div`
    height: 530px;
    width: 900px;
    margin: 200px auto 50px auto;
    background: url(/login_bg/bg_modal.png) no-repeat center center;
    background-size: cover;
    @media screen and (max-width: 900px) and (min-width: 600px) {
        width: 650px;
        height: 630px;
        background: url(/login_bg/bg_modal02.png) no-repeat center center;
    }
    @media screen and (max-width: 599px) {
        background: unset;
        width: 100%;
        height: 100vh;
        margin: auto;
    }
`;

const LoginImage = styled.div`
    position: relative;
    z-index: 1;
`;

const LoginLogo = styled.div`
    text-align: center;
    margin: 20px auto 70px -30px;
    @media screen and (max-width: 900px) and (min-width: 600px) {
        margin: 20px auto 120px -30px;
    }
    @media screen and (max-width: 599px) and (min-width: 320px) {
        margin: 35px auto 100px -30px;
    }
`;

const LoginHL = styled.div`
    position: absolute;
    left: -60px;
    top: 70px;
    width: 492px;
    height: 428px;
    background: url(/login_bg/login_01.png) no-repeat center center;
    background-size: cover;
    @media screen and (max-width: 900px) {
        display: none;
    }
`;

const CustomForm = styled.div`
    padding: 10px 25px;
    @media screen and (max-width: 599px) {
        padding: 25px 25px;
    }
`;

const CustomInputGroup = styled.div`
    position: relative;
    margin-bottom: 25px;
    text-align: ${ props => props.center ? 'center' : 'inherit' };
`;

const CustomInputIcon = styled.div`
    position: absolute;
    width: 50px;
    height: 50px;
`;

const CustomInput = styled.input`
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #828282;
    border-radius: 0;
    padding-left: 25px;
    font-size: 13px;
    color: #FFF;

    &:focus {
        color: #FFF;
        box-shadow: none !important;
        outline: none !important;
        background-color: transparent !important;
        border-bottom: 1px solid #828282;;
    }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
`;

const CustomButton = styled.button`
    background-color: #D5A95C;
    width: 140px;
    margin-top: 15px;
    color: #FFF;
    border-radius: 2rem;
    text-transform: uppercase;
`;

const ForgotText = styled.div`
    color: #D5A95C;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 20px;
`;

const ErrorText = styled.div`
    font-size: 13px;
    color: #FFF;
    text-align: center;
`;

const CustomLink = styled(NavLink)`
    color: rgba(255, 255, 255, .54);
    font-size: 20px;
    text-transform: uppercase;
    margin: 15px;
    padding-top: 25px;
    
    &:hover {
        color: #D5A95C;
        text-decoration: none;
    }
    &.active {
        color: #D5A95C;
        border-bottom: 1px solid rgba(213, 169, 92, 0.8);
    }
`;

const BTNshowpassword = styled.div`
    position: absolute;
    width: 25px;
    height: 25px;
    right: 0;
    top: 0;
    img {
        width: 20px;
        cursor: pointer;
    }
`;

const CustomInputCheckbox = styled.input`
    position: relative;
    cursor: pointer;
    margin-right: 7px;
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        border: 1px solid #D5A95C;
        border-radius: 3px;
        background-color: #242424;
    }
    &:checked:after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        border: solid #D5A95C;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 6px;
    }
`;

const RememberText = styled.label`
    color: #D5A95C;
    font-size: 13px;
`;

const ColumnForm = styled.div`
    @media screen and (max-width: 900px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
`;

const ColumnMenu = styled.div`
    margin-top: 1.5rem;
    margin-left: .5rem;
    @media screen and (max-width: 900px) {
        position: absolute;
        margin-top: 160px;
        top: 50%;
    }
`;


const LangCustom = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    > div {
        border-left: unset;
        min-width: 8rem;
        height: 55px;
        line-height: 55px;
        .dropdown {
            > span {
                color: #fff;
            }
        }
    }
`

function Login ({i18n,t}) {

    let history = useHistory();
    const [ usernameState, setUsernameState ] = useState('');
    const [ passwordState, setPasswordState ] = useState('');
    const [ loadingState, setLoadingState ] = useState(false);
    const [ isShowpassword, setIsShowpassword ] = useState(true);
    const [ pathState, setPathState ] = useState('');
    const [open, setOpen] = useState(false);
    const toggleShow = () => setIsShowpassword(!isShowpassword);
    const [ errorState, setErrorState ] = useState({
        invalid: false,
        message: ''
    });

    useEffect(() => {
        document.body.style.background = "url('/login_bg/bg_desktop.png') no-repeat center top";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.height = "100%";
        if (isLogin()) {
            document.body.style.background = "";
            document.body.style.backgroundSize = "";
            document.body.style.backgroundAttachment = "";
            history.push('/');
        }   
        const query_string = window.location.search.substr(1);
        const searchParams = new URLSearchParams(window.location.search)
        if(query_string){
            if (searchParams.get('lang')) {
                let lang = caseLang(searchParams.get('lang'))
                i18n.changeLanguage(lang);
                localStorage.setItem('lng', lang);
            }
            setPathState(`${window.location.search}`)
            // history.push(`${window.location.search}`)
        }
    }, []);

    function caseLang(l){
        if(l === 'en'){
            return "English" 
        }else if(l === 'th'){
            return "Thai" 
        }else if (l === 'mm'){
            return "Myanmar" 
        }else if (l === 'cn'){
            return "China" 
        }
    }

    function _setLogin () {
        if (usernameState.length === 0 || passwordState.length === 0 ) {
            setErrorState({ ...errorState, ...{
                invalid: true,
                message: 'error-user-login'
            }});
            return;
        }
        setLoadingState(true);

        setErrorState({ ...errorState, ...{
            invalid: false,
            message: ''
        }});

        setTimeout(function() {
            login(usernameState, passwordState).then(res => {
                setTimeout(() => {
                    if (!res.status) {
                        setErrorState({ ...errorState, ...{
                            invalid: true,
                            message: 'error-user-login'
                        }}); 
                        setLoadingState(false);
                        return;
                    }
                    setLoadingState(false);
                    setErrorState({ ...errorState, ...{
                        invalid: false,
                        message: ''
                    }});
                    document.body.style.background = "";
                    document.body.style.backgroundSize = "";
                    document.body.style.backgroundAttachment = "";
                    history.push('/');
                }, 1000); 
            });
        }, 1500);
    }
    
    function _linkForgot () {
        if(config.LINE){
            window.open(config.CONTACT_LINE);
        }else if(config.VIBER){
            setOpen(true)
        }
    }

    const _enterHandler = (e) => {
        if (e.key === 'Enter') {
            _setLogin();
        }
    };

    return (
        <>
        <LangCustom>
            <LanguageBox i18n={i18n} />
        </LangCustom>
        <ModalContact open={open} onCloseModal={()=>setOpen(false)} />
        <LoginContain>
            <div className={'row m-0'}>
                <LoginImage className={'col-12 col-md-6'}>
                    <ColumnMenu>
                        <CustomLink activeClassName={'active'} to={`/signup${pathState}`}>{t('signup')}</CustomLink>
                        <CustomLink activeClassName={'active'} to={`/login${pathState}`}>{t('login')}</CustomLink>
                    </ColumnMenu>
                    <LoginHL />
                </LoginImage>
                <ColumnForm className={'col-6'}>
                    <LoginLogo>
                        <img src={config.LOGO_AFF} alt={'hiaffiliate'} width="250px" />
                    </LoginLogo>
                    <CustomForm>
                        <CustomInputGroup>
                            <CustomInputIcon>
                                <img src={'/login_bg/icon/user.svg'} alt={'user'} />
                            </CustomInputIcon>
                            <CustomInput
                                type={'text'}
                                className={'form-control'}
                                placeholder={t('placeholder-phonenumber')}
                                value={usernameState}
                                onChange={({ target }) => setUsernameState( target.value )} />
                        </CustomInputGroup>
                        <CustomInputGroup>
                            <CustomInputIcon>
                                <img src={'/login_bg/icon/unlock.svg'} alt={'password'} />
                            </CustomInputIcon>
                            <CustomInput
                                type={isShowpassword ? 'password' : 'text'}
                                className={'form-control'}
                                placeholder={t('placeholder-password')}
                                value={passwordState}
                                onChange={({ target }) => setPasswordState( target.value )}
                                onKeyDown={(e) => _enterHandler(e)}
                            />
                            <BTNshowpassword onClick={() => toggleShow()}>
                                <img src={'/icon/eye_slash.svg'} alt={'eye_slash'} />
                            </BTNshowpassword>
                        </CustomInputGroup>
                        <div className={'row justify-content-between m-0'}>
                            <ForgotText onClick={() => _linkForgot()}>
                                <img src={'/icon/key.svg'} alt={''} />{' '}
                                {t('forgot-password')} ?
                            </ForgotText>
                            {/* <div>
                                <CustomInputCheckbox type="checkbox" value="lsRememberMe" id="rememberMe" onChange={checkRemember}/>
                                <RememberText>Remember me</RememberText>
                            </div> */}
                        </div>
                        <ErrorText>{t(errorState.message)}</ErrorText>
                        <CustomInputGroup center>
                            <button
                                type={'button'}
                                disabled={loadingState}
                                onClick={() => _setLogin()}
                                className={'btn btn__login'}>{ t(loadingState ? 'loading' : 'login') }</button>
                        </CustomInputGroup>
                    </CustomForm>
                </ColumnForm>
            </div>
        </LoginContain>
    </>)
}

export default Login;