import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import fetch from 'isomorphic-unfetch';
import { getSession } from '../../utils/login';
import Breadcrumb from '../../components/Breadcrumb/BreadcrumbType';
import currencyTransform from '../../utils/currency';
import EarningChart from '../../components/Chart/EarningChart/EarningChart'
import EarningTable from '../../components/Table/FinancesTable/EarningTable'
import FilterFinances from '../../components/FilterBuilder/FilterFinances/FilterFinances'
import config from "config/default.json"

const TitleBar = styled.div`
    background-color: #313131;
    height: 35px;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 5px;
    color: #FFFFFF;
    border-radius: 5px;
    margin-top: 10px;
    @media (max-width: 767px) {
        margin-top: 80px;
    }
`;

const ItemBox = styled.div`
    background-color: #FFF;
    display: flex;
    height: ${props => props.primary ? '46.45px' : '46.45px'};
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    align-items: center;
    font-size: 14px;

    @media (max-width: 767px) {
        font-size: 11px;
        padding: 10px;
    }
`;

const ItemLeft = styled.div`
    color: #131212;
    width: 50%;
    text-align: right;
    margin-right: 15px;
`;

const ItemRight = styled.div`
    border: 1px solid #dee2e6;
    width: 150px;
    text-align: center;
    border-radius: 5px;
    height: 23px;
    color: ${ props => props.color};
`;

const ItemTotal = styled.div`
    background-color: #FFF;
    height: 35px;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    align-items: center;
    font-size: 14px;
`;

const ItemTotalText = styled.div`
  color: ${props => props.color};
`;

const BlogState = styled.div`
    background-color: #FFF;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
`;


function Finances( {t} ) {

    const [financesData, setData] = useState({
        affCode: "",
        carryForwardRevenue: "",
        carryForwardWinloss: "",
        level: "",
        member: "",
        memberBonus: 0,
        netInCome: 0,
        netWinloss: 0,
        newFirstDeposit: 0,
        revenueFirsutDeposit: 0,
        revenueShare: 0,
        salary: 0,
        subAff: 0,
        subMember: 0,
        subNetWinloss: 0,
        subRevenue: 0,
        winloss: 0,
        reward: 0,
        revenue: 0
    });
    const [chart, setChart] = useState([])
    const [contentState, setContentState] = useState('')
    const [earningState, setEarningState] = useState('chart')
    const [monthState, setMonthState] = useState(3)
    const [tabActive, setTabActive] = useState('HIALLBET')

    useEffect(()=>{
        const session = getSession();
        if (session) {
            _fetchData(session.agentCode);
        }
    }, [earningState,monthState]);

    async function _fetchData(agentCode) {
        const bodySet = {
            agentCode: agentCode,
            date: moment().format('MMYYYY')
        };
        const response = await fetch(config.URL_API + '/getEarningSummary', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            let arrayData = {};
            if (data.response_result) {
                const result = data.response_data;
                for (let key in result) {
                    arrayData[key] = result[key];
                }
                setData(arrayData);
                _fetchChart(agentCode)
            }
        }
    }

    async function _fetchChart(agentCode) {
        const bodySet = {
            agentCode: agentCode,
            size: monthState
        };
        const response = await fetch(config.URL_API + '/getEarningState', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data)
            if (data.response_result) {
                let chartState = earningState === "chart" ? <BlogState><EarningChart data={data.response_data.listMonth} /></BlogState> : <EarningTable data={data.response_data.listMonth} />
                setContentState(chartState)
                setChart(data.response_data.listMonth)
            }
        }
    }

    function _setTextColor(value) {
        if (value < 0) {
            return '#D13C3C';
        } else if (value > 0) {
            return '#26B556';
        } else if (value === 0) {
            return '#AF8C50';
        } else {
            return '#AF8C50';
        }
    }

    const changedState = (text) => {
        setEarningState(text)
    }
    
    const changedMonth = text =>{
        setMonthState(text)
    }

    const activeBtn = (e) => {
        setTabActive(e)
        // console.log(e)
        // if (e === "HIALLBET") {
        //   _fetchDataList(agentCode)
        // } else if (e === "HISLOT") {
        //   _fetchDataListSlot(agentCode)
        // }
    }

    return (
        <div>
            <Breadcrumb title={'Earning Summary'} onClick={(e) => activeBtn(e)} tabActive={tabActive}></Breadcrumb>
            <TitleBar>{t('current-month')}</TitleBar>
            <div className={'row no-gutters'}>
                <div className={'col-6 col-md-6 pr-1'}>
                    <ItemBox primary={true}>
                        <ItemLeft className={'text-capitalize'}>{t('affiliate-code')}:</ItemLeft>
                        <ItemRight color={'#AF8C50'}>{financesData.affCode}</ItemRight> 
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('percent-revenue-share')}:</ItemLeft>
                        <ItemRight color={'#AF8C50'}>{financesData.revenueShare}</ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('winloss')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.winloss)}>
                            {currencyTransform(financesData.winloss)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('carry-forward-winloss')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.carryForwardWinloss)}>
                            {currencyTransform(financesData.carryForwardWinloss)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox primary={true}>
                        <ItemLeft className={'text-capitalize'}>{t('net-winloss')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.netWinloss)}>
                            {currencyTransform(financesData.netWinloss)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox primary={true}>
                        <ItemLeft className={'text-capitalize'}>{t('sub-affiliate')}:</ItemLeft>
                        <ItemRight color={'#AF8C50'}>{financesData.subAff}</ItemRight>
                    </ItemBox>
                    <ItemBox primary={true}>
                        <ItemLeft className={'text-capitalize'}>{t('sub-net-winloss')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.subNetWinloss)}>
                            {currencyTransform(financesData.subNetWinloss)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox primary={true}>
                        <ItemLeft className={'text-capitalize'}>{t('first-time-depositors')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.newFirstDeposit)}>
                            {currencyTransform(financesData.newFirstDeposit)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('salary')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.salary)}>
                            {currencyTransform(financesData.salary)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('carry-forward-revenue')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.carryForwardRevenue)}>
                            {currencyTransform(financesData.carryForwardRevenue)}
                        </ItemRight>
                    </ItemBox>
                </div>
                <div className={'col-6 col-md-6 pl-1'}>
                    <ItemBox primary={true}>
                        <ItemLeft className={'text-capitalize'}>{t('level')}:</ItemLeft>
                        <ItemRight color={'#AF8C50'}>{financesData.level}</ItemRight>
                    </ItemBox>
                    <ItemBox primary={true}>
                        <ItemLeft className={'text-capitalize'}>{t('member')}:</ItemLeft>
                        <ItemRight color={'#AF8C50'}>{financesData.member}</ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('member-bonus')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.memberBonus)}>
                            {currencyTransform(financesData.memberBonus)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('winloss-return')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.winloseReturn)}>
                            {currencyTransform(financesData.winloseReturn)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('revenue')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.revenue)}>
                            {currencyTransform(financesData.revenue)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox primary={true}>
                        <ItemLeft className={'text-capitalize'}>{t('sub-member')}:</ItemLeft>
                        <ItemRight color={'#AF8C50'}>{financesData.subMember}</ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('subaff-revenue')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.subRevenue)}>
                            {currencyTransform(financesData.subRevenue)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('revenue-first-deposit')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.revenueFirsutDeposit)}>
                            {currencyTransform(financesData.revenueFirsutDeposit)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('member-reward')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.reward)}>
                            {currencyTransform(financesData.reward)}
                        </ItemRight>
                    </ItemBox>
                    <ItemBox>
                        <ItemLeft className={'text-capitalize'}>{t('net-income')}:</ItemLeft>
                        <ItemRight color={_setTextColor(financesData.netInCome)}>
                            {currencyTransform(financesData.netInCome)}
                        </ItemRight>
                    </ItemBox>
                </div>
                {/* <div className={'col-12 col-md-12'}>
                    <ItemTotal className={'row'}>
                        <ItemTotalText
                            className={'col-6 text-center'}
                            color={'#131212'}
                        >{t('net-income')}:</ItemTotalText>
                        <ItemTotalText
                            className={'col-6 text-center'}
                            color={_setTextColor(financesData.netInCome)}
                        >{currencyTransform(financesData.netInCome)}</ItemTotalText>
                    </ItemTotal>
                </div> */}
            </div>
            <div className="mt-3 mb-5">
                <FilterFinances changedState={(text) => changedState(text)} changedMonth={(text) => changedMonth(text)} monthState={monthState} EarningState={earningState} title={t('earning-stats')} t={t}/>
                {contentState}
            </div>
        </div>
    );
}

export default Finances;