import React from 'react';
import styled from 'styled-components';
import { Input } from 'reactstrap';

const BlogFilter = styled.div`
    display: -webkit-box;
    align-items: center;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    margin-top: 5px;
    font-size: 13px;
    width: 100%;
    overflow: auto;
    flex-wrap: nowrap;
    @media (max-width: 767px) {
        margin-top: 80px;
    }
`

const TitleGallery = styled.div`
    border-right: 1px solid #d0d0d0;
    padding: 15px 20px;
    width: 150px;
`

const TitleFilter = styled.div`
    display: flex;
    select {
        font-size : 11px;
        height: 25px;
        padding: .275rem .75rem;
        width: 100px;
    }
`

const BTNSearch = styled.button`
    font-size : 12px;
`

const ContentFilter = styled.div`
    display: -webkit-box;
`;

function GalleryFilter({
    t,
    filterMedia,
    filterType,
    filterProduct,
    filterOffer,
    filterSize,
    filterLang,
    filterSort,
    product,
    offer
}) {

    const productSelect = (product).map((d, i) => {
        return (<option value={d.name} key={i}>{d.name}</option>)
    })

    const offerSelect = (offer).map((d, i) => {
        return (<option value={d} key={i}>{d}</option>)
    })

    return (
        <BlogFilter>
            <TitleGallery>
                {t('gallery-filter')}
            </TitleGallery>
            <ContentFilter>
                <TitleFilter className="ml-4">
                    <div className="my-auto mr-1">
                        {t('sort')} :
                </div>
                    <Input type="select" name="sort" id="Sort" onChange={filterSort}>
                        <option value={''}>{t('all')}</option>
                        <option value={'topmdia'}>{t('Top')}</option>
                        <option value={'newmedia'}>{t('New')}</option>
                    </Input>
                </TitleFilter>
                <TitleFilter className="ml-4">
                    <div className="my-auto mr-1">
                        {t('formats')} :
                </div>
                    <Input type="select" name="select" id="Formats" onChange={filterMedia}>
                        <option value={''}>{t('all')}</option>
                        <option value={'image'}>{t('image')}</option>
                        <option value={'gif'}>{t('gif')}</option>
                        <option value={'vdo'}>{t('vdo')}</option>
                    </Input>
                </TitleFilter>
                <TitleFilter className="ml-4">
                    <div className="my-auto mr-1">
                        {t('type')} :
                </div>
                    <Input type="select" name="select" id="Type" onChange={filterType}>
                        <option value="">{t('all')}</option>
                        <option value={'Casino'}>{t('casino')}</option>
                        <option value={'Slot'}>{t('slot')}</option>
                        <option value={'Sport'}>{t('sport')}</option>
                        <option value={'Esport'}>{t('esport')}</option>
                        <option value={'Hiaffiliate'}>{t('hiaffiliate')}</option>
                        <option value={'Hireward'}>{t('hireward')}</option>
                        <option value={'Hiallbet'}>{t('hiallbet')}</option>
                    </Input>
                </TitleFilter>
                <TitleFilter className="ml-4">
                    <div className="my-auto mr-1">
                        {t('product')} :
                </div>
                    <Input type="select" name="select" id="Product" onChange={filterProduct}>
                        <option value={''}>{t('all')}</option>
                        {productSelect}
                    </Input>
                </TitleFilter>
                <TitleFilter className="ml-4">
                    <div className="my-auto mr-1">
                        {t('offers')} :
                </div>
                    <Input type="select" name="select" id="Offers" onChange={filterOffer}>
                        <option value="">{t('all')}</option>
                        {offerSelect}
                    </Input>
                </TitleFilter>
                <TitleFilter className="ml-4" onChange={filterSize}>
                    <div className="my-auto mr-1">
                        {t('size')} :
                </div>
                    <Input type="select" name="select" id="Size">
                        <option value="">{t('all')}</option>
                        <option value="2560*1440">2560*1440</option>
                        <option value="1920*1080">1920*1080</option>
                        <option value="1500*500">1500*500</option>
                        <option value="1280*720">1280*720</option>
                        <option value="1200*717">1200*717</option>
                        <option value="1200*630">1200*630</option>
                        <option value="1200*628">1200*628</option>
                        <option value="1200*675">1200*675</option>
                        <option value="1080*1920">1080*1920</option>
                        <option value="1080*1350">1080*1350</option>
                        <option value="1080*1080">1080*1080</option>
                        <option value="1080*566">1080*566</option>
                        <option value="1040*1040">1040*1040</option>
                        <option value="1000*1000">1000*1000</option>
                        <option value="1000*1500">1000*1500</option>
                        <option value="1000*2100">1000*2100</option>
                        <option value="900*900">900*900</option>
                        <option value="820*320">820*320</option>
                        <option value="800*800">800*800</option>
                        <option value="600*600">600*600</option>
                        <option value="420*654">420*654</option>
                        <option value="400*400">400*400</option>
                        <option value="180*180">180*180</option>
                    </Input>
                </TitleFilter>
                <TitleFilter className="ml-4">
                    <div className="my-auto mr-1">
                        {t('language')} :
                </div>
                    <Input type="select" name="select" id="Language" onChange={filterLang}>
                        <option value="th">TH</option>
                        <option value="en">EN</option>
                        <option value="cn">CN</option>
                    </Input>
                </TitleFilter>
                {/* <TitleFilter className="ml-2">
                <BTNSearch
                    type={'button'}
                    className={'btn btn-success btn-sm font-weight-bold'}
                >{t('search')}</BTNSearch>
            </TitleFilter> */}
            </ContentFilter>
        </BlogFilter>
    )
}

export default GalleryFilter;