import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { getSession } from '../../utils/login';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DefaultTable from '../../components/Table/DefaultTable/DefaultTable';

const BreadcrumbContain = styled.div`
  display: flex;
  align-items: center;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
  padding: 7px 15px;
  margin: 10px 0px;
  > div > img {
      margin-top: -6px;
  }
  > div > span {
      font-weight: 500;
      padding-left: 7px;
      font-size: 18px;
  }
`;

const LeftBar = styled.div`
  font-weight: 600;
  margin-left: 7px;
`;

const columnList = [
  { title: "date", width: "200px" },
  { title: "time", width: "200px" },
  { title: "ip-address", width: "auto" },
];

function LoginHistory({t}) {

  return (
    <div>
      <BreadcrumbContain>
        <img src={'icon/login_history.svg'} alt={'icon'} width={'25px'} height={'20px'} />
        <LeftBar>Login History</LeftBar>
      </BreadcrumbContain>
      <DefaultTable t={t} headers={columnList}>
        {/* {
          memberList.map((m, mIndex) => (
            <tr key={mIndex}>
              <td>{m.lastLogin}</td>
            </tr>
          ))
        } */}
      </DefaultTable>
    </div>
  );
}

export default LoginHistory;