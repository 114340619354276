import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import moment from 'moment';
import styled from 'styled-components';

import Breadcrumb from '../../../../components/Breadcrumb/BreadcrumbType';
import DefaultTable from '../../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../../components/FilterBuilder/FilterMember/FilterMember';
import { getSession } from '../../../../utils/login';
import currencyTransform from "../../../../utils/currency";
import currencyColor from "../../../../utils/numericColor";
import DefaultBadge from '../../../../components/Badge/DefaultBadge/DefaultBadge';
import config from "config/default.json"

const TrTotal = styled.tr`
  line-height: 2;
  td {
      border-top: 1px solid #af8c50 !important;
      border-bottom: 1px solid #af8c50;
  }
  td:first-child {
      color: #af8c50;
      font-weight: 500;
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-left: 1px solid #af8c50;
  }
  td:last-child {
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-right: 1px solid #af8c50;
  }
`;

const columnList = [
  { title: "product", width: "120px" },
  { title: "membership", width: "auto" },
  { title: "winloss", width: "auto" },
  { title: "bonus", width: "auto" },
  { title: "winloss-return", width: "auto" },
  { title: "total", width: "auto" }
];

function Member ({t}) {
  const [ dataList, setDataList ] = useState([]);
  const [ dataTotal, setDataTotal ] = useState([]);
  const [ stats, setStats ] = useState([]);
  const [ agentCode, setAgentCode ] = useState([]);
  const [ filter, setFilter ] = useState([]);
  const [tabActive, setTabActive] = useState('HIALLBET')
  const [ date, setDate ] = useState({
    startDate: '01' + moment().format('MMYYYY'),
    endDate: moment().add(1,'days').format('DDMMYYYY'),
  });
  // const [ page, setPage ] = useState(0);

   useEffect(() => {
    const session = getSession();
    if (session) {
      _fetchDataList(session.agentCode);
      setFilter(session)
    }
  }, [date]);

  async function _fetchDataList (agentCode) {
    setAgentCode(agentCode)
    const bodySet = {
        agentCode: agentCode,
        startDate: date.startDate,
        endDate: date.endDate,
      };
    const response =  await fetch(config.URL_API + '/getProductReportMemberList', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.productList;
        const total = data.response_data.total;
        setDataList(result);
        setDataTotal(total)
      }
    }
  }

  const activeBtn = (e) => {
    setTabActive(e)
    // console.log(e)
    // if (e === "HIALLBET") {
    //   _fetchDataList(agentCode)
    // } else if (e === "HISLOT") {
    //   _fetchDataListSlot(agentCode)
    // }
  }

  const handleLink = async(productId) => {
    window.open(`/report/member-by-product?productId=${productId}&start=${date.startDate}&end=${date.endDate}`,"_self")
  };

  const _callbackDateRang = async(date) => {
      setDate({
        startDate: moment(date[0]).format('DDMMYYYY'),
        endDate: moment(date[1]).format('DDMMYYYY'),
      })
  }
  
  return (
      <div>
      <Breadcrumb title={'Product Report'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
        <FilterMember t={t} data={stats} isShowDate={false} isShowLevel={true} isShowDateRang={true} cbDateRang={_callbackDateRang}/>
        <DefaultTable t={t} headers={columnList}>
          {
            dataList.map((m, mIndex) => (
                <tr key={mIndex}>
                  <td onClick={() => handleLink(m.productId)}><DefaultBadge><img src={m.productImage} width="100px"/></DefaultBadge></td>
                  <td>{m.memberShip}</td>
                  <td className={m.winloss < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloss)}</td>
                  <td>{currencyColor(m.bonus,'text__danger')}</td>
                  <td className={m.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseReturn)}</td>
                  <td className={m.total < 0 ? 'text__danger' : ''}>{currencyTransform(m.total)}</td>
                </tr>
            ))
          }
          <TrTotal key={'total'}>
            <td>{t('total')}</td>
            <td>{dataTotal.memberShip}</td>
            <td className={dataTotal.winloss < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloss)}</td>
            <td>{currencyColor(dataTotal.bonus,'text__danger')}</td>
            <td className={dataTotal.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseReturn)}</td>
            <td className={dataTotal.total < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.total)}</td>
          </TrTotal>
        </DefaultTable>
      </div>
  );
}

export default Member;