import React from 'react';
// import LiveChat from 'react-livechat'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import './App.css';
import { useTranslation } from 'react-i18next';
import DefaultLayout from './layouts/DefaultLayout';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import Login from './pages/Login/Login';
import MemberList from './pages/Member/MemberList/MemberList';
import MemberDeposit from './pages/Member/MemberDeposit/MemberDeposit';
import MemberReport from './pages/Member/MemberReport/MemberReport';
import Finances from './pages/Finances/Finances';
import Expenses from './pages/Finances/Expenses/Expenses';
import SubAffList from "./pages/SubAff/SubAffList/SubAffList";
import Breakdown from "./pages/Report/Breakdowns/Breakdown/Breakdown";
import AffActivity from "./pages/Report/AffActivitys/AffActivity/AffActivity";
import SubAffReport from "./pages/SubAff/SubAffReport/SubAffReport";
import DetailActivityReport from "./pages/Report/DetailActivitys/DetailActivityReport/DetailActivityReport";
import MonthlyEarningReport from "./pages/Report/MothhlyEarningReport/MothhlyEarningReport";
import PrivateRoute from './routes/PrivateRoute';
import Gallery from './pages/MarketingMedia/Gallery/Gallery';
import Link from './pages/MarketingMedia/Link/Link';
import EarningBreakdown from './pages/Report/EarningBreakdown/EarningBreakdown';
import BreakdownWebDetail from './pages/Report/Breakdowns/BreakdownWebDetail/BreakdownWebDetail';
import Signup from './pages/Signup/Signup';
import ExpensesListDetail from './pages/Report/ExpensesListDetail/ExpensesListDetail';
import ActivityEachdate from './pages/Report/DetailActivitys/DetailActivityEachdate/DetailActivityEachdate';
import ActivityWebDetail from './pages/Report/DetailActivitys/DetailActivityWebDetail/DetailActivityWebDetail';
import BreakdownActivity from './pages/Report/AffBreakdows/AffBreakdownActivityReport/AffBreakdownActivityReport';
import AffBreakdownWebDetail from './pages/Report/AffBreakdows/AffBreakdownWebDetail/AffBreakdownWebDetail';
import AffActivityEachdate from './pages/Report/AffActivitys/AffActivityEachdate/AffActivityEachdate';
import AffActivityWebDetail from './pages/Report/AffActivitys/AffActivityWebDetail/AffActivityWebDetail';
import AffActivityReportEachdate from './pages/Report/AffActivitys/AffActivityReportEachdate/AffActivityReportEachdate';
import ProductMember from "./pages/Report/Products/Member/Member";
import MemberByProduct from "./pages/Report/Products/MemberByProduct/MemberByProduct";
import ProductSubAff from "./pages/Report/Products/SubAff/SubAff";
import SubAffByProduct from "./pages/Report/Products/SubAffByProduct/SubAffByProduct";
import Support from './pages/Support/Support';
import Academy from './pages/Academy/Academy';

//support detail
import SupportHome from './pages/DetailInSupport/Home'
import DetailQuestion from './components/Question/DetailQuestion';
import LineAt from './components/LineAt/LineAt';
import Contact from "./components/Contact/Contact";

import config from "config/default.json";

function App() {
  const [t, i18n] = useTranslation();

  return (
    <>
    {config.LINE ? <LineAt /> : null}
    {config.VIBER ? <Contact /> : null}
    <Router>
      <Switch>
        <DefaultLayout t={t} i18n={i18n}>
          <Route path="/signup">
            <Signup i18n={i18n} t={t} />
          </Route>
          <Route path="/signup-aff/:affCode">
            <Signup  t={t} />
          </Route>
          <Route path="/login">
            <Login i18n={i18n} t={t} />
          </Route>
          <PrivateRoute exact path="/">
            <Profile t={t}/>
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/finances/summary">
            <Finances t={t} />
          </PrivateRoute>
          <PrivateRoute path="/member/member-list">
            <MemberList t={t} />
          </PrivateRoute>
          <PrivateRoute path="/member/member-deposit">
            <MemberDeposit t={t} />
          </PrivateRoute>
          <PrivateRoute path="/member/member-report">
            <MemberReport t={t} />
          </PrivateRoute>
          <PrivateRoute path="/sub-aff/sub-affiliate-list">
            <SubAffList t={t} />
          </PrivateRoute>
          <PrivateRoute path="/sub-aff/sub-affiliate-report">
            <SubAffReport t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/detail-activity-report">
            <DetailActivityReport t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/earning-monthly-report">
            <MonthlyEarningReport t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/earning-breakdown-report">
            <EarningBreakdown t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/earning-breakdown-report-webdetail">
            <BreakdownWebDetail t={t} />
          </PrivateRoute>
          <PrivateRoute path="/marketing-media/gallery">
            <Gallery t={t} />
          </PrivateRoute>
          <PrivateRoute path="/marketing-media/link">
            <Link t={t} />
          </PrivateRoute>
          <PrivateRoute path="/finances/proportional-expenses">
            <Expenses t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/sub-affiliate-breakdown">
            <Breakdown t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/sub-affiliate-breakdown-activity">
            <BreakdownActivity t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/sub-affiliate-breakdown-webdetail">
            <AffBreakdownWebDetail t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/sub-affiliate-activity">
            <AffActivity t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/activity-eachdate">
            <ActivityEachdate t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/activity-webdetail">
            <ActivityWebDetail t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/expenseslist-detail">
            <ExpensesListDetail t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/sub-affiliate-activity-report-eachdate">
            <AffActivityReportEachdate t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/sub-affiliate-activity-eachdate">
            <AffActivityEachdate t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/sub-affiliate-activity-webdetail">
            <AffActivityWebDetail t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/member-product">
            <ProductMember t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/member-by-product">
            <MemberByProduct t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/subaff-product">
            <ProductSubAff t={t} />
          </PrivateRoute>
          <PrivateRoute path="/report/subaff-by-product">
            <SubAffByProduct t={t}/>
          </PrivateRoute>
          <Route exact path="/support">
            <Support t={t} />
          </Route>
          <Route path="/support/detail">
            <SupportHome t={t}/>
          </Route>
          <Route path="/question/detail">
            <DetailQuestion t={t} language={i18n.language}/>
          </Route>
          <PrivateRoute exact path="/academy">
            <Academy />
          </PrivateRoute>
        </DefaultLayout>
      </Switch>
    </Router>
    </>
  );
}

export default (App);
