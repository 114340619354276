import React from 'react';
import Modal from 'react-responsive-modal';
import styled from 'styled-components';
import config from "config/default.json";

const ModalContent = styled.div`
  text-align: center;
`;

const HeaderLogo = styled.div`
  span {
    font-size: 17px;
    font-weight: 700;
  }
  img {
    margin-right: 10px;
  }
`;

const Qrcode = styled.div`
  margin: 15px;
`;

const TextNumber = styled.div`
  display: flex;
  justify-content: center;
    a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D5A95C;
    color: #fff;
    width: 40%;
    border-radius: 2rem;
    font-size: 14px;
    font-weight: 500;
    padding: .25rem;
      &:hover {
        background-color: #af8c50;
        text-decoration: none;
      }
      &:focus {
        outline: unset;
      }
  }
`;

const cssmodal = {
  overlay: {
      overflow: "hidden"
  },
  modal: {
      height: "auto",
      width: "100%",
      background: "url(/contact/frame.png) center center / 400px 265px no-repeat",
      maxWidth: "400px",
      boxShadow: "none",
      borderRadius: ".55rem",
  },
  closeIcon: {
      fill: "#D5A95C",
      width: "20px",
      height: "20px",
  },
}

function ModalContact({ open, onCloseModal }) {
  return (
    <Modal open={open} onClose={() => onCloseModal()} center styles={cssmodal} >
      <ModalContent>
        <HeaderLogo>
          <img src={'/contact/vibet.svg'} alt={'icon'} />
          <span>VIBER</span>
        </HeaderLogo>
        <Qrcode>
          <a href={config.CONTACT_VIBER}>
            <img src={config.CONTACT_QRCODE} alt={'qrcode'} width={'40%'} />
          </a>
        </Qrcode>
        <TextNumber>
          <a href={config.CONTACT_VIBER}>
            <span>{config.CONTACT_VIBER_ADD}</span>
          </a>
        </TextNumber>
      </ModalContent>
    </Modal>
  )
}

export default ModalContact;
