import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import styled from 'styled-components';
import moment from 'moment';
import { getSession } from '../../../utils/login';
import subSTR from '../../../utils/hideText';
import Breadcrumb from '../../../components/Breadcrumb/BreadcrumbType';
import DefaultTable from '../../../components/Table/DefaultTable/DefaultTable';
import currency from '../../../utils/currency';
import FilterMember from '../../../components/FilterBuilder/FilterMember/FilterMember';
import config from "config/default.json"

const TrTotal = styled.tr`
    line-height: 2;
    td {
        border-top: 1px solid #af8c50 !important;
        border-bottom: 1px solid #af8c50;
    }
    td:first-child {
        color: #af8c50;
        font-weight: 500;
        border-top: 1px solid #af8c50;
        border-bottom: 1px solid #af8c50;
        border-left: 1px solid #af8c50;
    }
    td:last-child {
        border-top: 1px solid #af8c50;
        border-bottom: 1px solid #af8c50;
        border-right: 1px solid #af8c50;
    }
    
`;

const columnList = [
    { title: "no", width: "100px" },
    { title: "user", width: "150px" },
    { title: "createat", width: "200px" },
    { title: "first-deposit", width: "150px" },
    { title: "bonus", width: "150px" },
    { title: "revenue", width: "150px" }
];

function MemberDeposit ({t}) {
    const [ memberList, setMemberList ] = useState([]);
    const [ dataTotal, setDataTotal ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ month, setMonth ] = useState(moment().format('MMYYYY'));
    const [ tabActive, setTabActive ] = useState('HIALLBET')
    // const [ page, setPage ] = useState(0);
    useEffect(function() {
        const session = getSession();
        if (session) {
            _fetchMemberList(session.agentCode);
        }
    }, [month,search]);

    async function _fetchMemberList (agentCode) {
        const bodySet = {
            agentCode: agentCode,
            date: month,
            search: search
        };
        const response =  await fetch(config.URL_API + '/getMemberListDeposit', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            if (data.response_result) {
                const result = data.response_data.memberList;
                if (result) {
                    const total = data.response_data.total;
                    setDataTotal(total)
                    setMemberList(result);
                } else {
                    setMemberList(new Array());
                }
            }
        }
    }
    
    function _searchText(keyword) {
        if (keyword.length > 2) {
            setSearch(keyword)
        }else if(keyword.length === 0){
            setSearch("")
        }
    }

    const actionMonth = (e)=>{
        setMonth(e.target.value)
    }

    const activeBtn = (e) => {
        setTabActive(e)
        // console.log(e)
        // if (e === "HIALLBET") {
        //   _fetchDataList(agentCode)
        // } else if (e === "HISLOT") {
        //   _fetchDataListSlot(agentCode)
        // }
    }


    return (
        <div>
            <Breadcrumb title={'Member Deposit'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
            <FilterMember
                t={t}
                isShowMonth={true} 
                actionMonth={actionMonth}
                isShowLevel={false}
                search={(keyword) => _searchText(keyword)}
            />
                <DefaultTable t={t} headers={columnList}>
                    {
                        memberList.map((m, mIndex) => (
                            <tr key={mIndex}>
                                <td>{mIndex + 1}</td>
                                <td className={'border-left border-right'}>{subSTR(m.username)}</td>
                                <td>{m.createdAt}</td>
                                <td>{currency(m.firstDepositAmount)}</td>
                                <td>{currency(m.firstDepositBonus)}</td>
                                <td className="text-success" style={{fontWeight: "500"}}>{m.revenue}</td>
                            </tr>
                        ))
                    }
                    <TrTotal key={'total'}>
                        <td colSpan={3}>{t('total')}</td>
                        <td>{currency(dataTotal.firstDepositAmount)}</td>
                        <td>{currency(dataTotal.firstDepositBonus)}</td>
                        <td className="text-success" style={{fontWeight: "500"}}>{currency(dataTotal.revenue)}</td>
                    </TrTotal>
                </DefaultTable>
        </div>
    );
}

export default MemberDeposit;