import React, { useState } from 'react';
import styled from 'styled-components';
import ModalContact from '../../components/Modal/ModalContact/ModalContact';

const ContactContain = styled.div`
  position: fixed;
  right: 10px;
  bottom: 15px;
  transition: all .5s ease 0s;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

function Contact() {
  const [open, setOpen] = useState(false);

  function onCloseModal() {
    setTimeout(() => {
      setOpen(false)
    }, 100);
  }

  function _openContact() {
    setTimeout(() => {
      setOpen(true)
    }, 100);
  }

  return <ContactContain>
    <div onClick={() => _openContact()}>
      <img src={"/icon/viber_contact.svg"} alt={"Aff Contact"} width={"50px"} height={"50px"} />
    </div>
    <ModalContact open={open} onCloseModal={onCloseModal} />
  </ContactContain>
}

export default Contact;