import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import styled from 'styled-components';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import DefaultTable from '../../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../../components/FilterBuilder/FilterMember/FilterMember';
import { getSession } from '../../../../utils/login';
import currencyTransform from "../../../../utils/currency";
import currencyColor from "../../../../utils/numericColor";
import Back from '../../../../components/Button/Back/Back';
import subSTR from '../../../../utils/hideText';
import MemberBadge from '../../../../components/Badge/MemberBadge/MemberBadge';
import config from "config/default.json"

const TrTotal = styled.tr`
  line-height: 2;
  td {
      border-top: 1px solid #af8c50 !important;
      border-bottom: 1px solid #af8c50;
  }
  td:first-child {
      color: #af8c50;
      font-weight: 500;
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-left: 1px solid #af8c50;
  }
  td:last-child {
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-right: 1px solid #af8c50;
  }
`;

const columnList = [
  { title: "no", width: "120px" },
  { title: "level", width: "auto" },
  { title: "user", width: "auto" },
  { title: "trans-in", width: "auto" },
  { title: "trans-out", width: "auto" },
  { title: "turnover", width: "auto" },
  { title: "winloss", width: "auto" },
  { title: "bonus", width: "auto" },
  { title: "winloss-return", width: "auto" },
  { title: "total", width: "auto" }
];

function Member ({t}) {
  const [ dataList, setDataList ] = useState([]);
  const [ dataTotal, setDataTotal ] = useState([]);
  const [ stats, setStats ] = useState([]);
  const [ filter, setFilter ] = useState([]);
  // const [ page, setPage ] = useState(0);

   useEffect(() => {
    const session = getSession();
    if (session) {
      _fetchDataList(session.agentCode);
      setFilter(session)
    }
  }, []);

  async function _fetchDataList (agentCode) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let start = params.get('start');
    let end = params.get('end');
    let productId = params.get('productId');
    const bodySet = {
        agentCode: agentCode,
        startDate: start,
        endDate: end,
        productId: productId
      };
    const response =  await fetch(config.URL_API + '/getProductReportMemberListByProduct', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.memberList;
        const total = data.response_data.total;
        setDataList(result);
        setDataTotal(total)
      }
    }
  }

  return (
      <div>
        <Breadcrumb title={'Product Report'} />
        <FilterMember t={t} data={stats} isShowDate={false} isShowLevel={true}/>
        <Back text={t('back')} link={'/report/member-product'} />
        <DefaultTable t={t} headers={columnList}>
          {
            dataList.map((m, mIndex) => (
                <tr key={mIndex}>
                  <td>{mIndex+1}</td>
                  <td><MemberBadge text={m.level} color={m.levelColor} /></td>
                  <td>{subSTR(m.username)}</td>
                  <td>{currencyTransform(m.transIn)}</td>
                  <td>{currencyTransform(m.transOut)}</td>
                  <td>{currencyTransform(m.turnover)}</td>
                  <td className={m.winloss < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloss)}</td>
                  <td>{currencyColor(m.bonus)}</td>
                  <td className={m.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseReturn)}</td>
                  <td className={m.total < 0 ? 'text__danger' : ''}>{currencyTransform(m.total)}</td>
                </tr>
            ))
          }
          <TrTotal key={'total'}>
            <td colSpan={3}>{t('total')}</td>
            <td>{currencyTransform(dataTotal.transIn)}</td>
            <td>{currencyTransform(dataTotal.transOut)}</td>
            <td>{currencyTransform(dataTotal.turnover)}</td>
            <td className={dataTotal.winloss < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloss)}</td>
            <td>{currencyColor(dataTotal.bonus)}</td>
            <td className={dataTotal.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseReturn)}</td>
            <td className={dataTotal.total < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.total)}</td>
          </TrTotal>
        </DefaultTable>
      </div>
  );
}

export default Member;