import React, { useState, Fragment } from 'react';
import PropsType from 'prop-types';
import styled from 'styled-components';

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px 0 20px 0;
    a {
        text-decoration: none;
        color: #131212;
        float: left;
        background-color: #fff;
        border-radius: 5rem;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
        margin: 5px;
        font-size: 13px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center; 
        padding: 0;
        &.active {
            background-color: #af8c50;
            color: #fff;
        }
    }
    
`;

function Paging ({ text }) {

    return (
        <Pagination className="pagination">
            <a href="#" className="active">1</a>
            <a href="#">2</a>
            <a href="#">3</a>
            <a href="#">4</a>
        </Pagination>
    );
}

Paging.propTypes = {
    text: PropsType.string
};

export default Paging;