export const menuSupportItem = [
  {
    id: 0,
    text: "home",
    link: "/support/detail?page=home",
    iconPath: "/support/icon/home.svg",
    width: "25px",
    banner: "/support/banner.png",
    qrcode: "/support/qrcode.png",
    statusFilter: true,
    isOpen: true
  },
  {
    id: 1,
    text: "dashboard",
    link: "/support/detail?page=dashboard",
    iconPath: "/support/icon/dashboard.svg",
    width: "25px",
    banner: "/support/banner.png",
    qrcode: "/support/qrcode.png",
    statusFilter: true,
    isOpen: false
  },
  {
    id: 2,
    text: "marketing-media",
    link: "/support/detail?page=marketing-media",
    iconPath: "/support/icon/marketing_media.svg",
    width: "25px",
    banner: "/support/banner.png",
    qrcode: "/support/qrcode.png",
    statusFilter: true,
    isOpen: true
  },
  {
    id: 3,
    text: "finances",
    link: "/support/detail?page=finances",
    iconPath: "/support/icon/finaces.svg",
    width: "25px",
    banner: "/support/banner.png",
    qrcode: "/support/qrcode.png",
    statusFilter: true,
    isOpen: true
  },
  {
    id: 4,
    text: "member",
    link: "/support/detail?page=member",
    iconPath: "/support/icon/member_menu.svg",
    width: "20px",
    banner: "/support/banner.png",
    qrcode: "/support/qrcode.png",
    statusFilter: true,
    isOpen: true
  },
  {
    id: 5,
    text: "sub-affiliate",
    link: "/support/detail?page=sub-affiliate",
    iconPath: "/support/icon/member.svg",
    width: "30px",
    banner: "/support/banner.png",
    qrcode: "/support/qrcode.png",
    statusFilter: true,
    isOpen: true
  },
  {
    id: 6,
    text: "report",
    link: "/support/detail?page=report",
    iconPath: "/support/icon/reports.svg",
    width: "25px",
    banner: "/support/banner.png",
    qrcode: "/support/qrcode.png",
    statusFilter: true,
    isOpen: true
  },
  {
    id: 7,
    text: "reward",
    link: "/support/detail?page=reward",
    iconPath: "/support/icon/reward.svg",
    width: "30px",
    banner: "/support/banner.png",
    qrcode: "/support/qrcode.png",
    statusFilter: true,
    isOpen: false
  },
]

export const SubHome = [
  {
    name: "1",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "home_profile.mp4"
      },
    ],
  },
]

export const SubDashboard = [
  {
    name: "1",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: ""
      },
    ],
  },
]

export const SubMedia = [
  {
    name: "1",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "marketing_media_links.mp4"
      },
    ],
  },
  {
    name: "2",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "marketing_media_gallery.mp4"
      },
    ],
  }
]

export const SubFinances = [
  {
    name: "1",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "Finances_Earning_Summary.mp4"
      },
    ],
  },
  {
    name: "2",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "Finances_Proportional_Expenses.mp4"
      },
    ],
  }
]

export const SubMember = [
  {
    name: "1",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "member_member_list.mp4"
      },
    ],
  },
  {
    name: "2",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "member_member_report.mp4"
      }
    ],
  },
  {
    name: "3",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "member_member_deposit.mp4"
      }
    ],
  }
]

export const SubAffiliate = [
  {
    name: "1",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "sub-affiliate_sub-affiliate_list.mp4"
      },
    ],
  },
  {
    name: "2",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "sub-affiliate_sub-affiliate_report.mp4"
      },
    ],
  }
]

export const SubReport = [
  {
    name: "1",
    sub: [
      {
        title: "Detailed Activity Report",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "report_Detailed_Activity_Report.mp4"
      }
    ],
  },
  {
    name: "2",
    sub: [
      {
        title: "Monthly Earning Report",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "report_Monthly_Earning_Report.mp4"
      },
      {
        title: "Earnings Breakdown Report",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "report_Earnings_Breakdown_Report.mp4"
      },
      {
        title: "Proportional Expenses Detail",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "report_Proportional_Expenses_Detail.mp4"
      }
    ],
  },
  {
    name: "3",
    sub: [
      {
        title: "member",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "report_product_report_member.mp4"
      },
      {
        title: "sub-affiliate",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "report_product_report_sub-affiliate.mp4"
      },
    ],
  },
  {
    name: "4",
    sub: [
      {
        title: "Detailed Activity Report",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "report_Sub_Affiliate_Report_Detailed_Activity_Report.mp4"
      },
      {
        title: "Earnings Breakdown Report",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: "report_Sub_Affiliate_Report_Earnings_Breakdown_Report.mp4"
      },
    ],
  },
]

export const SubReward = [
  {
    name: "1",
    sub: [
      {
        title: "",
        banner: "/support/banner.png",
        qrcode: "/support/qrcode.png",
        vdo: ""
      },
    ],
  },
]