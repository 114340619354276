import React from 'react';
import Modal from 'react-responsive-modal';
import styled from 'styled-components';
import config from "config/default.json"
const ModalContent = styled.div`
    text-align: center;
    padding: 10px 50px 100px 50px;
    @media screen and (max-width: 767px) {
        padding: 10px 0px 100px 0px;
    }
`;
const LoginLogo = styled.div`
    img {
        width: 150px;
    }
`;
const CheckIcon = styled.div`
    padding: 90px 90px 20px 90px;
    img {
        width: 150px;
    }
    @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        padding: 70px 0 40px 0;
        img {
            width: 140px;
        }
    }
`;
const TextSuccess = styled.div`
    .text__register {
        color: #af8c50;
        font-size: 24px;
    }
    .text__approve {
        color: rgba(255,255,255);
        font-size: 22px;
    }
    padding-bottom: 20px;
`;
const ButtonLine = styled.div`
    display: flex;
    justify-content: center;
`;
const CustomButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #242424;
    background-color: #D5A95C;
    width: 200px;
    margin-top: 15px;
    border-radius: 2rem;
    font-weight: 700;
    &:focus {
        box-shadow: unset;
    }
    &:hover {
        background-color: #af8c50;
    }
`;

const cssmodal = {
    overlay: {
        overflow: "hidden"
    },
    modal: {
        height: "auto",
        width: "100%",
        background: "url(/login_bg/bg_modal02.png) center center / cover no-repeat",
        maxWidth: "max-content"
    },
    closeIcon: {
        fill: "#af8c50",
        display: "none"
    },
}

function ModalSingUp({ open, onCloseModal, notCloseModal, title, imgtitle, subtitle, buttonTitle }) {
    const _openLink = type => {
        if (type === 'livechat') {
            window.open(config.CONTACT_LINE, '_blank');
            onCloseModal()
            return;
        }
    };
    return (
        <Modal open={open} onClose={() => notCloseModal()} center styles={cssmodal} >
            <ModalContent>
                <LoginLogo>
                    <img src={config.LOGO_AFF} alt={'hiaffiliate'} width="250px" />
                </LoginLogo>
                <CheckIcon>
                    <img src={`/icon/${imgtitle}.svg`} alt={'icon'} />
                </CheckIcon>
                <TextSuccess>
                    <div className="text__register">{title}</div>
                    <div className="text__approve">{subtitle}</div>
                </TextSuccess>
                <ButtonLine>
                    <CustomButton type={'button'} className={'btn'} onClick={() => _openLink('livechat')}>
                        <img src={'/icon/telegram.svg'} alt={'icon'} width={'20px'} height={'30px'} className="mr-2" />
                        <span>{buttonTitle}</span>
                    </CustomButton>
                </ButtonLine>
            </ModalContent>
        </Modal>
    )
}

export default ModalSingUp;
