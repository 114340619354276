import React from 'react';
import styled from 'styled-components';

const Contain = styled.div`
    display: flex;
`;

function Wrapper ({ children }) {
    return (
        <Contain>{children}</Contain>
    );
}

export default Wrapper;