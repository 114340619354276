// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils/login';

function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
            isLogin() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}

export default PrivateRoute;