import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import moment from "moment";
import currencyTransform from '../../../utils/currency';
import styled from 'styled-components';

import Breadcrumb from '../../../components/Breadcrumb/BreadcrumbType';
import DefaultTable from '../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../components/FilterBuilder/FilterMember/FilterMember';
import MemberBadge from "../../../components/Badge/MemberBadge/MemberBadge";
import { getSession } from '../../../utils/login';
import subSTR from '../../../utils/hideText';
import currencyColor from "../../../utils/numericColor";
import DefaultBadge from '../../../components/Badge/DefaultBadge/DefaultBadge';
import config from "config/default.json"

const TrTotal = styled.tr`
  line-height: 2;
  td {
      border-top: 1px solid #af8c50 !important;
      border-bottom: 1px solid #af8c50;
  }
  td:first-child {
      color: #af8c50;
      font-weight: 500;
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-left: 1px solid #af8c50;
  }
  td:last-child {
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-right: 1px solid #af8c50;
  }
`;

function EarningBreakdown ({t}) {

  const [ dataList, setDataList ] = useState([]);
  const [ dataTotal, setDataTotal ] = useState([]);
  const [ sortType, setSortType] = useState(1);
  const [ sortBy, setSortBy ] = useState('');
  const [ search, setSearch ] = useState('');
  const [ month, setMonth ] = useState(moment().format('MMYYYY'));
  const [tabActive, setTabActive] = useState('HIALLBET')


   useEffect(() => {
    const session = getSession();
    if (session) {
      _fetchDataList(session.agentCode);
    }
  }, [month,sortType,search]);

  async function _fetchDataList (agentCode) {
    const bodySet = {
      agentCode: agentCode,
      sortBy: sortBy,
      sortType: sortType,
      search: search,
      date: month
    };
    const response =  await fetch(config.URL_API + '/getEarningBreakdown', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.memberList;
        if (result) {
          setDataList(result);
          const total = data.response_data.total;
          setDataTotal(total)
        } else {
          setDataList(new Array());
        }
      }
    }
  }

  const handleLink = async(username) => {
    window.open(`/report/earning-breakdown-report-webdetail?username=${username}&date=${month}`,"_self")
  };

  function _searchText(keyword) {
    if (keyword.length > 2) {
        setSearch(keyword)
    }else if(keyword.length === 0){
        setSearch("")
    }
  }

  const sortby = (type) => {
      const sort = sortType === 1 ? -1 : 1
      setSortType(sort)
      setSortBy(type)
  }
  
  const columnList = [
    { title: "no", width: "100px" },
    { title: "level", width: "auto" },
    { title: "user", width: "auto" },
    { title: "deposit", width: "auto", click: () => sortby('deposit') },
    { title: "turnover", width: "auto", click: () => sortby('turnOver') },
    { title: "winloss", width: "auto", click: () => sortby('winlose') },
    { title: "bonus", width: "auto", click: () => sortby('bonus') },
    { title: "winloss-return", width: "auto", click: () => sortby('winloseReturn') },
    { title: "net-winloss", width: "auto", click: () => sortby('total') },
    { title: "revenue", width: "auto", click: () => sortby('revenue') },
    { title: "reward", width: "auto" },
  ];

  const actionMonth = (e)=>{
    setMonth(e.target.value)
  }

  const activeBtn = (e) => {
    setTabActive(e)
    // console.log(e)
    // if (e === "HIALLBET") {
    //   _fetchDataList(agentCode)
    // } else if (e === "HISLOT") {
    //   _fetchDataListSlot(agentCode)
    // }
  }

  return (
    <div>
      <Breadcrumb title={'Earnings Breakdown Report'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
        <FilterMember t={t} isShowMonth={true} actionMonth={actionMonth} search={(keyword) => _searchText(keyword)}/>
        <DefaultTable t={t} headers={columnList}>
          { dataList.map((m, mIndex) => (
            <tr key={mIndex}>
              <td>{mIndex + 1}</td>
              <td><MemberBadge text={m.level} color={m.levelColor} /></td>
              <td onClick={ () => handleLink(m.username) }><DefaultBadge text={subSTR(m.username)} /></td>
              <td className={ m.deposit < 0 ? 'text__danger' : '' }>{currencyTransform(m.deposit)}</td>
              <td className={ m.turnOver < 0 ? 'text__danger' : '' }>{currencyTransform(m.turnOver)}</td>
              <td className={ m.winlose < 0 ? 'text__danger' : '' }>{currencyTransform(m.winlose)}</td>
              <td>{currencyColor(m.bonus, 'text__danger')}</td>
              <td>{currencyColor(m.winloseReturn)}</td>
              <td className={ m.total < 0 ? 'text__danger' : '' }>{currencyTransform(m.total)}</td>
              <td>{currencyColor(m.revenue)}</td>
              <td>{currencyColor(m.reward, 'text__danger')}</td>
            </tr>
            )) }
          <TrTotal>
            <td colSpan={3}>{t('total')}</td>
            <td>{currencyTransform(dataTotal.deposit)}</td>
            <td>{currencyTransform(dataTotal.turnOver)}</td>
            <td>{currencyColor(dataTotal.winlose)}</td>
            <td>{currencyColor(dataTotal.bonus, 'text__danger')}</td>
            <td>{currencyColor(dataTotal.winloseReturn)}</td>
            <td className={dataTotal.total < 0 ? 'text__danger' : '' }>{currencyTransform(dataTotal.total)}</td>
            <td>{currencyColor(dataTotal.revenue)}</td>
            <td>{currencyColor(dataTotal.reward, 'text__danger')}</td>
          </TrTotal>
        </DefaultTable>
      </div>
  );
}

export default EarningBreakdown;