import React, { useState, useEffect } from "react";
import { SubHome, SubDashboard, SubMedia, SubFinances, SubMember, SubAffiliate, SubReport, SubReward } from "../../../utils/supports"
import styled from 'styled-components';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";

const MenuItemVideo = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #FFF;
  width: auto;
  height: 100%;
  border-radius: 5px;
  padding: 0.35rem 0.75rem;
  font-size: 15px;
  line-height: 1.5;
  color: #828282;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    color: #000;
  }
  &.active {
    border: 1px solid #d5a95c;
  }
`;

const ColLeft = styled.div`
  text-align: center;
  margin: auto;
`;

const ColRight = styled.div`
  font-size: 11px;
  text-align: center;
  margin: auto;
  img {
    width: 80%;
  }
  @media (max-width: 768px) {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid #e2e2e2;
    img {
      width: 40%;
    }
  }
`;

const ColRightText = styled.div`
  margin-top: 10px;
`;

function SubSupports({ t, activeReport, page }) {
  const [data, setData] = useState([])
  const [activeSub, setActiveSub] = useState('')
  const [checkActive, setCheckActive] = useState('')
  const [checkPage, setCheckPage] = useState('')
  const [subData, setSubData] = useState('')
  const [vdo, setVdo] = useState('')

  useEffect(function () {
    setNewData(activeReport, page)
  }, []);

  function setNewData(v1, v2) {
    let ress = ''
    setCheckActive(v1)
    setCheckPage(v2)
    if (v2 === "home") {
      const index = SubHome.findIndex(m => m.name === v1)
      ress = SubHome[index].sub
    } else if (v2 === "dashboard") {
      const index = SubDashboard.findIndex(m => m.name === v1)
      ress = SubDashboard[index].sub
    } else if (v2 === "marketing-media") {
      const index = SubMedia.findIndex(m => m.name === v1)
      ress = SubMedia[index].sub
    } else if (v2 === "finances") {
      const index = SubFinances.findIndex(m => m.name === v1)
      ress = SubFinances[index].sub
    } else if (v2 === "member") {
      const index = SubMember.findIndex(m => m.name === v1)
      ress = SubMember[index].sub
    } else if (v2 === "sub-affiliate") {
      const index = SubAffiliate.findIndex(m => m.name === v1)
      ress = SubAffiliate[index].sub
    } else if (v2 === "report") {
      const index = SubReport.findIndex(m => m.name === v1)
      ress = SubReport[index].sub
      setActiveSub(ress[0].title)
    } else if (v2 === "reward") {
      const index = SubReward.findIndex(m => m.name === v1)
      ress = SubReward[index].sub
    }
    setData(ress)
    setSubData(ress[0])
    setVdo(ress[0].vdo)
  }

  function getActiveSub(text) {
    setActiveSub(text)
    const index = data.findIndex(m => m.title === text)
    setSubData(data[index])
    setVdo(data[index].vdo)
  }

  if (activeReport !== checkActive || checkPage !== page) {
    setNewData(activeReport, page)
  }

  return (
    <React.Fragment>
      <div className={'row mb-3 mt-3 text-center justify-content-center'}>
        {data.map((m, mIndex) => {
          const checkLenght = data.length > 1 ? <div className={'col-3 p-0'} >
            <MenuItemVideo className={activeSub === m.title ? 'active' : 'null'} onClick={(text) => getActiveSub(m.title)}>{m.title}</MenuItemVideo>
          </div> : null
          return (
            <React.Fragment key={mIndex}>
              {checkLenght}
            </React.Fragment>
          )
        })}
      </div>
      <div className={'row'}>
        <ColLeft className={'col-md-9'}>
          {/* <img src={subData.banner} alt={"support"} className={'img-fluid'} /> */}
          {vdo !== '' ? <Player width={'300'} src={`/support/vdo/${vdo}`} fluid={true} /> : null}
        </ColLeft>
        <ColRight className={'col-md-3'}>
          <div>
            <img src={subData.qrcode} alt={"qrcode"} className={'img-fluid'} />
          </div>
          <ColRightText>
            <div className={'col-md-12'}>Add LINE Friends via QR Code</div>
            <div className={'col-md-12'}>Open the friends tab in your LINE app,
            tap the add friends icon in the top right, select "QR Code",
              and then scan this QR Code</div>
          </ColRightText>
        </ColRight>
      </div>
    </React.Fragment>
  )
}

export default SubSupports;