import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import common_en from "./translations/en/common.json";
import common_th from "./translations/th/common.json";
import common_cn from "./translations/cn/common.json";
import common_my from "./translations/my/common.json";

const tagManagerArgs = { gtmId: 'GTM-TLZH86C' };
TagManager.initialize(tagManagerArgs);

ReactGA.initialize('UA-154239131-1');
ReactGA.pageview(window.location.pathname + window.location.search);

let lng = localStorage.getItem("lng");

i18next.init({
  interpolation: { escapeValue: false },
  lng: lng || "English",
  fallbackLng: lng || "English",
  resources: {
    English: { translation: common_en },
    Thai: { translation: common_th },
    China: { translation: common_cn },
    Myanmar: { translation: common_my }
  }
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);
