import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { menuSupportItem } from '../../utils/supports';
import SearchBox from '../../components/SearchBox/SearchBox';
import Question from '../Question/Question';
import config from "config/default.json";

const BannerHelp = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  background-image: linear-gradient(#fff, transparent, #bdbdbd80);
  border-radius: .25rem;
  margin-top: 10px;
  @media (max-width: 767px) {
    display: block;
    background-image: unset;
    height: 100%;
  }
`;

const ImgBanner = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  > img {
    width: 40%;
  }
  @media (max-width: 767px) {
    > img {
      width: 100%;
    }
  }
`;

const TextBanner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  z-index: 1;
  > div input {
    margin: 5px 0px 0 0;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
  }
  @media (max-width: 767px) {
    position: relative;
    width: 100%;
  }
`;

const BannerHelpText = styled.div`
  font-size: 36px;
`;

const MenuItemGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  // border-bottom: 1px solid #e2e2e2;
`;

const ItemBox = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #FFF;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: 5px 0;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
  padding: 20px 10px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
    transition: all .3s ease-in-out;
  }
`;

const ColItembox = styled.div`
  margin-bottom: 15px;
  a {
    text-decoration: none;
  }
`;

const Lineadd = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
`;

const CustomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  width: 140px;
  margin-top: 15px;
  border-radius: 1rem;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
  padding: 5px;
`;

function Support({ t }) {

  const _openLink = type => {
    if (type === 'line') {
      window.open(config.CONTACT_LINE, '_blank');
      return;
    }
  };

  return (
    <div className={'overflowpage'}>
      <BannerHelp>
        <ImgBanner><img src={"/support/banner.png"} alt={"support"} /></ImgBanner>
        <TextBanner>
          <div>
            <BannerHelpText>
              <span>{t('help-support')}</span>
            </BannerHelpText>
            <SearchBox placeholder={t('search')} />
          </div>
        </TextBanner>
        <Lineadd>
          <CustomButton onClick={() => _openLink('line')}>
            <img src={'/icon/icon_line.svg'} alt={'icon'} width={'25px'} className="mr-2" />
            <span>@hiaff</span>
          </CustomButton>
        </Lineadd>
      </BannerHelp>
      <MenuItemGroup className={'container'}>
        {menuSupportItem.map((m, mIndex) => {
          return (
            <ColItembox className={'col-6 col-md-3'} key={mIndex}>
              {m.isOpen ?
                <Link to={m.link}>
                  <ItemBox>
                    <div>
                      <img src={m.iconPath} alt={"icon-support"} width={m.width} height={"30px"} />
                      <div>{t(m.text)}</div>
                    </div>
                  </ItemBox>
                </Link>
                :
                <ItemBox style={{ cursor: "no-drop" }}>
                  <div>
                    <img src={m.iconPath} alt={"icon-support"} width={m.width} height={"30px"} />
                    <div>{t(m.text)}</div>
                  </div>
                </ItemBox>
              }
            </ColItembox>
          )
        })}
      </MenuItemGroup>
      <hr />

      <Question t={t} />

    </div>
  )
}

export default Support;