import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';

function Academy() {

  const [ loading, setLoading ] = useState(true);
  const [ errorMsg, setErrorMsg ] = useState('');

  useEffect(() => {
    let isCancelled = false;
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const userObject = JSON.parse(user);
    const callURL = async () => {
      const response = await fetch('https://applied89.hiaff.com/api/post-to-academy?token=' + token, {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      });
      if (!isCancelled) {
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          if (!data.status) {
            setErrorMsg('ข้อมูลผิดพลาด กรุณา login ใหม่');
            setLoading(false);
          } else {
            setErrorMsg('รอสักครู่..., ระบบกำลังนำทางไปสู่ URL ของ Academy');
            setLoading(false);
            window.open(data.url, '_blank');
          }
        }
      }
    }
    if (userObject.level && userObject.level !== '') {
      callURL();
    } else {
      setErrorMsg('ระดับของคุณไม่สามารถเข้าใช้งาน Academy ได้');
      setLoading(false);
    }
    return () => isCancelled = true;
  },[]);

  return (
    <div className={'overflowpage'}>
      <h3 className="mt-5 text-center">{ loading ? 'Please wait...' : errorMsg }</h3>
      {/* <div className="jumbotron mt-3">
        <h1 className="display-4">Academy</h1>
      </div> */}
    </div>
  )
}

export default Academy;