import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { isLogin } from "../utils/login";

import Sidebar from "../components/Sidebar/Sidebar";
import Wrapper from "../components/Wrapper/Wrapper";
import Navbar from "../components/Navbar/Navbar";
import NavbarMobile from "../components/Navbar/NavbarMobile";
import Footer from "../components/footer/footer";

const ContentContain = styled.div`
  display: flex;
  margin-right: 15px;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  @media (max-width: 767px) {
    margin-right: 0px;
  }
`;

const ContentCustom = styled.div`
  @media (max-width: 767px) {
    > div {
      margin-top: 80px;
      padding: 0 10px;
      width: 150vh;
      // max-width: max-content;
    }

    .overflowpage {
      width: 100%
      max-width: unset;
    }
  }
`;

function DefaultLayout({ children, t, i18n }) {
  // const Navbars = window.innerWidth > 767 ? <Navbar t={t} i18n={i18n} /> : <NavbarMobile t={t} i18n={i18n} />
  const [Nav, setNav] = useState(true);
  const [maxWidth, setmaxWidth] = useState(true);

  function hideNavBar() {
    setNav(true);
  }
  
  return isLogin() ? (
    <Wrapper>
      <Sidebar t={t} i18n={i18n} Nav={Nav} setNav={setNav}/>
      <ContentContain onClick={hideNavBar}>
        <Navbar t={t} i18n={i18n} />
        <ContentCustom>{children}</ContentCustom>
        <Footer />
      </ContentContain>
    </Wrapper>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
}

export default DefaultLayout;
