import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import moment from "moment";
import styled from 'styled-components';
import currencyTransform from '../../../utils/currency';

import Breadcrumb from '../../../components/Breadcrumb/BreadcrumbType';
import DefaultTable from '../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../components/FilterBuilder/FilterMember/FilterMember';
import MemberBadge from '../../../components/Badge/MemberBadge/MemberBadge';
import { getSession } from '../../../utils/login';
import currencyColor from "../../../utils/numericColor";
import config from "config/default.json"

const TrTotal = styled.tr`
  line-height: 2;
  td {
    border-top: 1px solid #af8c50 !important;
    border-bottom: 1px solid #af8c50;
  }
  td:first-child {
    color: #af8c50;
    font-weight: 500;
    border-top: 1px solid #af8c50;
    border-bottom: 1px solid #af8c50;
    border-left: 1px solid #af8c50;
  }
  td:last-child {
    border-top: 1px solid #af8c50;
    border-bottom: 1px solid #af8c50;
    border-right: 1px solid #af8c50;
  }
`;

function MonthlyEarningReport ({t}) {

  const [ dataList, setDataList ] = useState([]);
  const [ dataTotal, setDataTotal ] = useState([]);
  const [ year, setYear ] = useState(moment().format('YYYY'));
  const [tabActive, setTabActive] = useState('HIALLBET')

   useEffect(() => {
    const session = getSession();
    if (session) {
      _fetchDataList(session.agentCode);
    }
  }, [year]);

  async function _fetchDataList (agentCode, d) {
    const bodySet = {
      agentCode: agentCode,
      year: year
    };
    const response =  await fetch(config.URL_API + '/getMonthlyEarning', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.dateList;
        const total = data.response_data.total;
        setDataTotal(total)
        if (result) {
          setDataList(result);
        } else {
          setDataList(new Array());
        }
      }
    }
  }

  const activeBtn = (e) => {
    setTabActive(e)
    // console.log(e)
    // if (e === "HIALLBET") {
    //   _fetchDataList(agentCode)
    // } else if (e === "HISLOT") {
    //   _fetchDataListSlot(agentCode)
    // }
  }

  const actionYear = (e)=>{
    setYear(e.target.value)
  }
  function _setMonth (month)  {
    switch(month) {
      case "01": return "JAN"; 
      case "02": return "FEB"; 
      case "03": return "MAR"; 
      case "04": return "APR"; 
      case "05": return "MAY"; 
      case "06": return "JUN"; 
      case "07": return "JUY"; 
      case "08": return "AUG"; 
      case "09": return "SEP"; 
      case "10": return "OCT"; 
      case "11": return "NOV"; 
      case "12": return "DEC"; 
    }
  }

  const columnList = [
    { title: "month", width: "100px" },
    { title: "level", width: "100px" },
    { title: "membership", width: "auto" },
    { title: "carry-forward-winloss", width: "auto" },
    { title: "winloss", width: "auto" },
    { title: "bonus", width: "auto" },
    { title: "winloss-return", width: "auto" },
    { title: "net-winloss", width: "auto" },
    { title: "carry reward", width: "auto" },
    { title: "sub-net-winloss", width: "auto" },
    { title: "sub-revenue", width: "auto" },
    { title: "salary", width: "auto" },
    { title: "carry-forward-revenue", width: "auto" },
    { title: "first-deposit-revenue", width: "auto" },
    { title: "net-income", width: "auto" },
    { title: "reward", width: "auto" },
  ];

  return (
      <div>
      <Breadcrumb title={'Monthly Earning Report'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
        <FilterMember t={t} isShowYear={true} actionYear={actionYear}/>
        <DefaultTable t={t} headers={columnList}>
          { dataList.map((m, mIndex) => (
              <tr key={mIndex}>
                <td>
                  { _setMonth(m.month) + ' ' + m.year}
                </td>
                <td><MemberBadge text={m.level} color={m.levelColor} /></td>
                <td>{m.member}</td>
                <td className={m.carryForwardWinlose < 0 ? 'text__danger' : '' }>{currencyTransform(m.carryForwardWinlose)}</td>
                <td className={m.winloss < 0 ? 'text__danger' : '' }>{currencyTransform(m.winloss)}</td>
                <td>{currencyColor(m.bonus, 'text__danger')}</td>
                <td className={m.winloseReturn < 0 ? 'text__danger' : '' }>{currencyTransform(m.winloseReturn)}</td>
                <td className={m.netWinloss < 0 ? 'text__danger' : '' }>{currencyTransform(m.netWinloss)}</td>
                <td className={m.carryForwardReward < 0 ? 'text__danger' : '' }>{currencyTransform(m.carryForwardReward)}</td>
                <td className={m.subNetWinloss < 0 ? 'text__danger' : '' }>{currencyTransform(m.subNetWinloss)}</td>
                <td>{currencyColor(m.subRevenue)}</td>
                <td className={m.salary <= 0 ? '' : 'text__success'}>{currencyTransform(m.salary)}</td>
                <td className={m.carryForwardRevenue <= 0 ? '' : 'text__success'}>{currencyTransform(m.carryForwardRevenue)}</td>
                <td>{currencyColor(m.firstDepositRevenue)}</td>
                <td>{currencyColor(m.netInCome)}</td>
                <td>{currencyColor(m.reward, 'text__danger')}</td>
              </tr>
          )) }
          <TrTotal>
            <td colSpan={2}>{t('total')}</td>
            <td>{dataTotal.member}</td>
            <td className={dataTotal.carryForwardWinlose < 0 ? 'text__danger' : '' }>{currencyTransform(dataTotal.carryForwardWinlose)}</td>
            <td className={dataTotal.winloss < 0 ? 'text__danger' : '' }>{currencyTransform(dataTotal.winloss)}</td>
            <td>{currencyColor(dataTotal.bonus, 'text__danger')}</td>
            <td className={dataTotal.winloseReturn < 0 ? 'text__danger' : '' }>{currencyTransform(dataTotal.winloseReturn)}</td>
            <td className={dataTotal.netWinloss < 0 ? 'text__danger' : '' }>{currencyTransform(dataTotal.netWinloss)}</td>
            <td className={dataTotal.carryForwardReward < 0 ? 'text__danger' : '' }>{currencyColor(dataTotal.carryForwardReward)}</td>
            <td className={dataTotal.subNetWinloss < 0 ? 'text__danger' : '' }>{currencyColor(dataTotal.subNetWinloss)}</td>
            <td>{currencyColor(dataTotal.subRevenue)}</td>
            <td className={dataTotal.salary <= 0 ? '' : 'text__success'}>{currencyTransform(dataTotal.salary)}</td>
            <td className={dataTotal.carryForwardRevenue <= 0 ? '' : 'text__success'}>{currencyTransform(dataTotal.carryForwardRevenue)}</td>
            <td>{currencyColor(dataTotal.firstDepositRevenue)}</td>
            <td>{currencyColor(dataTotal.netInCome)}</td>
            <td>{currencyColor(dataTotal.reward, 'text__danger')}</td>
          </TrTotal>
        </DefaultTable>
      </div>
  );
}

export default MonthlyEarningReport;