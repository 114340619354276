import React from 'react';
import Modal from 'react-responsive-modal';
import styled from 'styled-components';
import config from "config/default.json";


const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  text-align: center;
  padding-top: 10px;
`;

const CloseIcon = styled.div`
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
  > img {
    width: 20px;
  }
`
const LogoIcon = styled.div`
  img {
    width: 80px;
  }
`;

const CheckIcon = styled.div`
  padding: 90px 90px 20px 90px;
  img {
      width: 150px;
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    padding: 70px 0 40px 0;
    img {
        width: 140px;
    }
  }
`;

const UpdateTitleText = styled.div`
  padding-top: 17px;
  .text__title {
    color: #dead58;
    font-size: 22px;
  }
  .text__subtitle {
    color: #4e5665;
    font-size: 16px;
    padding: 5px 0;
  }
  .text__contacttitle {
    color: #4e5665;
    font-size: 13px;
  }
  @media (max-width: 767px) {
    .text__title {
      font-size: 16px;
    }
    .text__subtitle {
      font-size: 13px;
    }
  }
`;

const ButtonLine = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #26b556;
  width: 200px;
  border-radius: .75rem;
  font-weight: 700;
  padding: 0.175rem .75rem;
  margin-top: 10px;
  &:focus {
    box-shadow: unset;
  }
  &:hover {
    color: #fff;
    background-color: #21a74e;
  }
`;

const cssmodal = {
  overlay: {
    overflow: "hidden"
  },
  modal: {
    height: "auto",
    width: "100%",
    borderRadius: "0.75rem",
    background: "#f3f3f3",
    maxWidth: "500px",
    padding: "2.5rem 1rem"
  },
  closeIcon: {
    fill: "#af8c50",
    display: "none"
  },
}

const TextNumber = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7px;
    a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D5A95C;
    color: #fff;
    width: 40%;
    border-radius: 2rem;
    font-size: 14px;
    font-weight: 500;
    padding: .25rem;
      &:hover {
        background-color: #af8c50;
        text-decoration: none;
      }
      &:focus {
        outline: unset;
      }
  }
`;

function ModalAlertProfile({ open, onCloseModal, notCloseModal, t }) {
  const _openLink = type => {
    if (type === 'livechat') {
        window.open(config.CONTACT_LINE, '_blank');
        // window.LC_API.open_chat_window();
        onCloseModal()
        return;
    }
};
  return (
    <Modal open={open} onClose={() => notCloseModal()} center styles={cssmodal} >
      <ModalContent>
        <CloseIcon onClick={onCloseModal}>
          <img src={'/icon/popup_cross.svg'} alt={'icon'} />
        </CloseIcon>
        <LogoIcon>
          <img src={'/icon/icon_alert.svg'} alt={'icon'} />
        </LogoIcon>
        <UpdateTitleText>
          <div className="text__title">{t('popup-profile-updated')}</div>
          <div className="text__subtitle">{t('popup-profile-verify')}</div>
          <div className="text__contacttitle">{t('popup-profile-contact')}</div>
        </UpdateTitleText>
        {config.LINE?<ButtonLine>
          <CustomButton type={'button'} className={'btn'} onClick={() => _openLink('livechat')}>
            <img src={'/icon/popup_line.svg'} alt={'icon'} width={'20px'} height={'30px'} className="mr-2" />
            <span>{config.CONTACT_LINE_ADD}</span>
          </CustomButton>
        </ButtonLine>:null}
        {config.VIBET ? <TextNumber>
          <a href={config.CONTACT_VIBER}>
          <span>{config.CONTACT_VIBER_ADD}</span>
          </a>
        </TextNumber>:null}
      </ModalContent>
    </Modal>
  )
}

export default ModalAlertProfile;
