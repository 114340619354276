import React from 'react';
import styled from 'styled-components';

const BlogSelectFilter = styled.div`
    justify-content: flex-end;
`

const TitleState = styled.div`
    display : flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: #313131;
    height: 38px;
    font-size: 14px;
    margin-bottom: 5px;
    color: #FFFFFF;
    border-radius: 5px;
    margin-top: 10px;
`;

const BTNState = styled.button`
    font-size: 12px;
    height: 30px;
    width: 90px;
    background: #929090;
    border: 0;
    color: #ffffff;
	&:focus, &:hover, &:active {
		outline: 0px auto -webkit-focus-ring-color;
	}
    &.active {
        background: #af8c50;
    }
`;

const BTNStateMonth = styled.button`
    font-size: 12px;
    height: 30px;
    width: 90px;
    background: transparent;
    color: #af8c50;
    border: 1px solid #af8c50;
	&:focus, &:hover, &:active {
		outline: 0px auto -webkit-focus-ring-color;
	}
    &.active, &:hover {
        color: #ffffff;
        background: #af8c50;
    }
`;

const RowBTNState = styled.div`
    display: flex;
`;

const RowBTNStateMonth = styled.div`
    display: flex;
    // margin-left: 48px;
`;



function FilterFinances(props) {
    
    return (
        <TitleState>
            <div>
                {props.title}
            </div>
            {/* <BlogSelectFilter className="col-9 d-flex my-auto"> */}
                <RowBTNState>
                    <BTNState className={props.EarningState === "chart" ? 'btn-sm active' : 'btn-sm'} onClick={(text) => props.changedState('chart')}>
                        <img src={`/icon/chart.svg`} alt={'icon'} />
                        <span className="ml-2">{props.t('chart')}</span>
                    </BTNState>
                    <BTNState className={props.EarningState === "table" ? 'btn-sm active ml-2' : 'btn-sm ml-2'} onClick={(text) => props.changedState('table')}>
                        <img src={`/icon/table.svg`} alt={'icon'} />
                        <span className="ml-2">{props.t('table')}</span>
                    </BTNState>
                </RowBTNState>
                <RowBTNStateMonth>
                    <BTNStateMonth className={props.monthState === 3 ? 'btn-sm active' : 'btn-sm'} onClick={(text) => props.changedMonth(3)}>
                        <span>{props.t('month-3')}</span>
                    </BTNStateMonth>
                    <BTNStateMonth className={props.monthState === 6 ? 'btn-sm ml-2 active' : 'btn-sm ml-2'} onClick={(text) => props.changedMonth(6)}>
                        <span>{props.t('month-6')}</span>
                    </BTNStateMonth>
                    <BTNStateMonth className={props.monthState === 12 ? 'btn-sm ml-2 active' : 'btn-sm ml-2'} onClick={(text) => props.changedMonth(12)}>
                        <span>{props.t('lastyear')}</span>
                    </BTNStateMonth>
                </RowBTNStateMonth>
            {/* </BlogSelectFilter> */}
        </TitleState>
    )
}

export default FilterFinances;