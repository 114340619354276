import React, { useState } from "react";
// import Modal from 'react-responsive-modal';
import { Player } from 'video-react';
import { Modal } from "reactstrap";
import styled from 'styled-components';


const CardCover = styled.div`
  position: relative;
  // filter: brightness(0.9);
  height: 100%;
  &:hover {
    // filter: unset;
    .playicon {
      background-color: rgba(0,0,0,0.8);
    }
  }
`

const CoverImg = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  background-clip: border-box;
  border-radius: .25rem;
  box-shadow: 0px 1px 3px rgb(49,49,49);
  cursor: pointer;
  transition: all .3s ease 0s;
  > img {
    border-radius: 0.375rem;
    height: 100%;
  }
  &:hover {
    transform: scale(1.005);
  }

`

const NewIcon = styled.div`
  position: absolute;
  z-index: 1;
  left: -8px;
  img {
    width: 70px;
  }
`

const PlayIcon = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.5);
  cursor: pointer;
  transition: all 0.3s ease;
  
  .btnplay {
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent transparent transparent #f7f7f7;
    border-width: 23px 0px 23px 40px;
    border-radius: 2px;
    margin-left: 8px;
  }
`

const ModalVideo = styled(Modal)`
  .modal-content {
    background-color: transparent;
    border: unset;
  }
`

const IconClose = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.725rem 0;
  filter: brightness(0) opacity(0.5);
  img {
    &:hover {
      cursor: pointer;
      filter: unset;
    }
  }
`

function VideoAcademy() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div className={'h-100'}>
      <CardCover onClick={toggle}>
        <NewIcon>
          <img src={'/academy/new_academy.svg'} alt={'icon'} className={'img-fluid'} />
        </NewIcon>
        <CoverImg>
          <img src={'/academy/cover_intro.jpg'} alt={'cover'} className={'img-fluid'} />
        </CoverImg>
        <PlayIcon className={'playicon'}><div className={'btnplay'}></div></PlayIcon>
      </CardCover>

      <ModalVideo isOpen={modal} className={'modal-dialog-centered'}>
        <IconClose onClick={toggle}>
          <img src={'/icon/icon_close_.svg'} alt={'icon'} className={'img-fluid'} />
        </IconClose>
        <Player loop autoPlay>
          <source src="/academy/hiaff_present.mp4" />
        </Player>
      </ModalVideo>
    </div>
  );
}

export default VideoAcademy;