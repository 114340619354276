import React, { useEffect, useState } from 'react';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import { isLogin, login } from '../../utils/login';
import styled from 'styled-components';
import fetch from 'isomorphic-unfetch';
import ModalSingup from '../../components/Modal/ModalSingUp/ModalSingUp'
import config from "config/default.json"
import LanguageBox from "../../components/Navbar/LanguageBox/LanguageBox";

const LoginContain = styled.div`
    height: 530px;
    width: 900px;
    margin: 200px auto 50px auto;
    background: url(/login_bg/bg_modal.png) no-repeat center center;
    background-size: cover;
    @media screen and (max-width: 900px) and (min-width: 600px) {
        width: 650px;
        height: 630px;
        background: url(/login_bg/bg_modal02.png) no-repeat center center;
    }
    @media screen and (max-width: 599px) {
        background: unset;
        width: 100%;
        height: 100vh;
        margin: auto;
    }
`;

const LoginImage = styled.div`
    position: relative;
    z-index: 1;
`;

const LoginLogo = styled.div`
    text-align: center;
    margin: 20px auto 50px -30px;
    @media screen and (max-width: 900px) and (min-width: 600px) {
        margin: 20px auto 120px -30px;
    }
    @media screen and (max-width: 599px) and (min-width: 320px) {
        margin: 35px auto 100px -30px;
    }
`;

const LoginHL = styled.div`
    position: absolute;
    left: -60px;
    top: 70px;
    width: 492px;
    height: 428px;
    background: url(/login_bg/sign_up_02.png) no-repeat center center;
    background-size: cover;
    @media screen and (max-width: 900px) {
        display: none;
    }
`;

const CustomForm = styled.div`
    padding: 10px 25px;
    @media screen and (max-width: 900px) {
        padding: 30px 25px;
    }
`;

const CustomInputGroup = styled.div`
    position: relative;
    margin-bottom: 25px;
    text-align: ${ props => props.center ? 'center' : 'inherit'};
`;

const CustomInputIcon = styled.div`
    position: absolute;
    width: 50px;
    height: 50px;
`;

const CustomInput = styled.input`
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #828282;
    border-radius: 0;
    padding-left: 30px;
    font-size: 13px;
    color: #FFF;
    &:focus {
        color: #FFF;
        box-shadow: none !important;
        outline: none !important;
        background-color: transparent !important;
        border-bottom: 1px solid #828282;;
    }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
`;

const CustomSelect = styled.select`
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #828282;
    border-radius: 0;
    padding-left: 25px;
    text-indent: 2px;
    font-size: 13px;
    color: #FFF;
`;

const CustomButton = styled.button`
    background-color: #D5A95C;
    width: 140px;
    margin-top: 15px;
    color: #FFF;
    border-radius: 2rem;
    text-transform: uppercase;
`;

const ForgotText = styled.div`
    color: #D5A95C;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 20px;
`;

const ErrorText = styled.div`
    font-size: 13px;
    text-align: center;
    color: ${ props => props.color ? props.color : '#FFF'};
`;

const CustomLink = styled(NavLink)`
    color: rgba(255, 255, 255, .54);
    font-size: 20px;
    text-transform: uppercase;
    margin: 15px;
    padding-top: 25px;
    
    &:hover {
        color: #D5A95C;
        text-decoration: none;
    }
    &.active {
        color: #D5A95C;
        border-bottom: 1px solid rgba(213, 169, 92, 0.8);
    }
`;

const StepBar = styled.ul`
    counter-reset: step;
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    padding-inline-start: 0px;
    height: 30px;
    li {
        list-style-type: none;
        width: 70px;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: rgba(255,255,255,.54);
        z-index: 1;
        &:nth-child(1) {
            &:after {
                content: none;
            }
        }
        &:before {
            width: 33px;
            height: 33px;
            content: counter(step);
            counter-increment: step;
            line-height: 25px;
            border: 4px solid #1c1b1b;
            display: block;
            text-align: center;
            margin: 0 auto 0px auto;
            border-radius: 50%;
            background-color: #242424;
        }
        &:after {
            width: 60%;
            height: 1px;
            content: '';
            position: absolute;
            background-color: #7d7d7d;
            top: 15px;
            left: -25%;
            z-index: -1;
        }
        &.active {
            &:before {
                border-color: #D5A95C;
            }
        }
        &.active + :after {
            background-color: rgba(255,255,255,.54);
        }
    }
`;

const ColumnForm = styled.div`
    @media screen and (max-width: 900px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
`;

const ColumnMenu = styled.div`
    margin-top: 1.5rem;
    margin-left: .5rem;
    @media screen and (max-width: 900px) {
        position: absolute;
        margin-top: 160px;
        top: 50%;
    }
`;

const LangCustom = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    > div {
        border-left: unset;
        min-width: 8rem;
        height: 55px;
        line-height: 55px;
        .dropdown {
            > span {
                color: #fff;
            }
        }
    }
`

function Signup({ i18n, t }) {

    let history = useHistory();
    let query = new URLSearchParams(useLocation().search);
    const [usernameState, setUsernameState] = useState('');
    const [passwordState, setPasswordState] = useState('');
    const [cPasswordState, setCPasswordState] = useState('');
    const [nickNameState, setNickNameState] = useState('');
    const [bankState, setBankState] = useState('');
    const [accountNumberState, setAccountNumberState] = useState('');
    const [accountNameState, setAccountNameState] = useState('');
    // const [ phoneState, setPhoneState ] = useState('');
    const [pathState, setPathState] = useState('');
    const [affCode, setAffCode] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [segment, setSegment] = useState(1);
    const [bankInfoState, setBankInfoState] = useState([]);
    const [errorState, setErrorState] = useState({
        invalid: false,
        message: ''
    });
    const [validUsername, setValidUsername] = useState({
        invalid: false,
        message: ''
    });
    const [validAccount, setValidAccount] = useState({
        invalid: false,
        message: ''
    });
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [subtitle, setSubTitle] = useState('');
    const [imgtitle, setImgTitle] = useState('');
    const [buttonTitle, setButtonTitle] = useState('');

    useEffect(() => {
        document.body.style.background = "url('/login_bg/bg_desktop.png') no-repeat center top";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.height = "100%";
        if (isLogin()) {
            document.body.style.background = "";
            document.body.style.backgroundSize = "";
            document.body.style.backgroundAttachment = "";
            history.push('/');
        }
        const query_string = window.location.search.substr(1);
        const searchParams = new URLSearchParams(window.location.search)
        if (query_string) {
            if (searchParams.get('aff')) {
                setAffCode(searchParams.get('aff'))
            }
            if (searchParams.get('affr')) {
                setAffCode(searchParams.get('affr'))
            }
            if (searchParams.get('lang')) {
                let lang = caseLang(searchParams.get('lang'))
                i18n.changeLanguage(lang);
                localStorage.setItem('lng', lang);
            }
            setPathState(`${window.location.search}`)
            // history.push(`${window.location.search}`)
        }
        _getBankInfo();
    }, []);

    function caseLang(l){
        if(l === 'en'){
            return "English" 
        }else if(l === 'th'){
            return "Thai" 
        }else if (l === 'mm'){
            return "Myanmar" 
        }else if (l === 'cn'){
            return "China" 
        }
    }

    function onCloseModal() {
        history.push('/login');
        setOpen(false)
    }

    function notCloseModal() {
        setOpen(true)
    }

    async function _getBankInfo() {
        const response = await fetch(config.URL_AGENT_API + '/userbanks', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        });
        if (response.ok) {
            const data = await response.json();
            if (data.results.length !== 0) {
                setBankInfoState(data.results);
            } else {
                setBankState(new Array());
            }
        } else {
            setBankState(new Array());
        }
    }

    function _setLogin() {

        if (usernameState.length === 0 || passwordState.length === 0 ||
            nickNameState.length === 0) {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'hint-signup-user'
                }
            });
            return;
        }
        if (validUsername.status) {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'Username invalid !!'
                }
            });
            return;
        }

        if (passwordState.length < 4) {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'hint-password-mininum'
                }
            });
            return;
        }

        if (passwordState !== cPasswordState) {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'hint-password-notmatch'
                }
            });
            return;
        }

        setLoadingState(true);

        setErrorState({
            ...errorState, ...{
                invalid: false,
                message: ''
            }
        });

        setTimeout(function () {
            setSegment(2);
            setLoadingState(false);
        }, 1500);
    }

    function _signup() {
        if (bankState.length === 0 || accountNameState.length === 0 ||
            accountNumberState.length === 0) {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'hint-user-bank'
                }
            });
            return;
        }

        if (validAccount.status) {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'Account Number invalid !!'
                }
            });
            return;
        }

        if (accountNumberState.length < 8) {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'error-signup-bank'
                }
            });
            return;
        }

        if (accountNumberState === '') {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'Account number empty'
                }
            });
            return;
        }

        if (accountNameState === '') {
            setErrorState({
                ...errorState, ...{
                    invalid: true,
                    message: 'Account name empty'
                }
            });
            return;
        }

        setLoadingState(true);

        setErrorState({
            ...errorState, ...{
                invalid: false,
                message: ''
            }
        });

        setTimeout(() => {
            const dataSet = {
                bankId: bankState,
                username: usernameState,
                password: passwordState,
                bankAccount: accountNumberState,
                bankNameAccount: accountNameState,
                nickname: nickNameState
            };

            if (affCode) { dataSet["agentCode"] = affCode.replace(/[^a-zA-Z0-9]/g, ''); }

            _signUpData(dataSet).then(r => {
                if (r) {
                    setTimeout(() => {
                        login(usernameState, passwordState).then(res => {
                            if(res.status){
                                document.body.style.background = "";
                                document.body.style.backgroundSize = "";
                                document.body.style.backgroundAttachment = "";
                                history.push('/');
                            }
                        });
                    }, 1000);
                } else {
                    setOpen(true)
                    setImgTitle('icon_already')
                    setSubTitle('error-user-register')
                    setLoadingState(false);
                    return;
                }
            }).catch(e => {
                setOpen(true)
                setImgTitle('icon_interrupt')
                setSubTitle('error-user-system')
                setLoadingState(false);
                return;
            });
        }, 1500);
    }

    async function _signUpData(dataSet) {
        const response = await fetch(config.URL_AGENT_API + '/agent/create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataSet)
        });
        if (response.ok) {
            const data = await response.json();
            if (data.status) {
                return true;
            }
        } else {
            return false;
        }
    }

    const verifyUsername = async (target) => {
        const value = target.value
        if (value.length <= 11) {
            setUsernameState(value)
            const response = await fetch(config.URL_AGENT_API + '/agent/username', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: value })
            });
            if (response.ok) {
                const data = await response.json();
                setValidUsername({
                    invalid: data.status,
                    message: data.message
                })
                if (data.status) {
                    return true;
                }
            } else {
                return false;
            }
        }
    }

    const verifyAccount = async (target) => {
        const value = target.value
        setAccountNumberState(value)
        const response = await fetch(config.URL_AGENT_API + '/agent/account', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ bankAccount: value })
        });
        if (response.ok) {
            const data = await response.json();
            setLoadingState(data.status)
            setValidAccount({
                invalid: data.status,
                message: data.message
            })
            if (data.status) { return true; }
        } else {
            return false;
        }
    }

    const verifyAcountNumber = (value) => {
        // const checkBackSpace = value.split(" ").length - 1
        // if ((value === '' || value.replace(/[^ก-ฮเ-ไ]/g, "")) && checkBackSpace < 2) {
            setAccountNameState(value)
        // }
    }

    return (
        <>
        <LangCustom>
            <LanguageBox i18n={i18n} />
        </LangCustom>
        <LoginContain>
            <div className={'row m-0'}>
                <LoginImage className={'col-12 col-md-6'}>
                    <ColumnMenu>
                        <CustomLink activeClassName={'active'} to={`/signup${pathState}`}>{t('signup')}</CustomLink>
                        <CustomLink activeClassName={'active'} to={`/login${pathState}`}>{t('login')}</CustomLink>
                    </ColumnMenu>
                    <LoginHL />
                </LoginImage>
                <ColumnForm className={'col-6'}>
                    <LoginLogo>
                        <img src={config.LOGO_AFF} alt={config.OWNER} width="250px" />
                    </LoginLogo>
                    {(() => {
                        switch (segment) {
                            case 1:
                                return <React.Fragment>
                                    <StepBar>
                                        <li className={'active'}></li>
                                        <li></li>
                                    </StepBar>
                                    <CustomForm>
                                        <CustomInputGroup>
                                            <CustomInputIcon>
                                                <img src={'/login_bg/icon/user.svg'} alt={'user'} />
                                            </CustomInputIcon>
                                            <CustomInput
                                                type={'number'}
                                                className={'form-control'}
                                                placeholder={t('placeholder-phonenumber')}
                                                value={usernameState}
                                                onChange={({ target }) => verifyUsername(target)} />
                                            <ErrorText color={'#ff3e3e'}> {validUsername.message}</ErrorText>
                                        </CustomInputGroup>
                                        <CustomInputGroup>
                                            <CustomInputIcon>
                                                <img src={'/login_bg/icon/user.svg'} alt={'user'} />
                                            </CustomInputIcon>
                                            <CustomInput
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={t('placeholder-nickname')}
                                                value={nickNameState}
                                                onChange={({ target }) => setNickNameState(target.value)} />
                                        </CustomInputGroup>
                                        <CustomInputGroup>
                                            <CustomInputIcon>
                                                <img src={'/login_bg/icon/unlock.svg'} alt={'password'} />
                                            </CustomInputIcon>
                                            <CustomInput
                                                type={'password'}
                                                className={'form-control'}
                                                placeholder={t('placeholder-password')}
                                                value={passwordState}
                                                onChange={({ target }) => setPasswordState(target.value)}
                                            />
                                        </CustomInputGroup>
                                        <CustomInputGroup>
                                            <CustomInputIcon>
                                                <img src={'/login_bg/icon/unlock.svg'} alt={'password'} />
                                            </CustomInputIcon>
                                            <CustomInput
                                                type={'password'}
                                                className={'form-control'}
                                                placeholder={t('placeholder-cfpassword')}
                                                value={cPasswordState}
                                                onChange={({ target }) => setCPasswordState(target.value)}
                                            />
                                        </CustomInputGroup>
                                        <ErrorText>{t(errorState.message)}</ErrorText>
                                        <CustomInputGroup center>
                                            <button
                                                type={'button'}
                                                disabled={loadingState}
                                                onClick={() => _setLogin()}
                                                className={'btn btn__next'}>{t(loadingState ? 'loading' : 'next')}</button>
                                        </CustomInputGroup>
                                    </CustomForm>
                                </React.Fragment>;
                            case 2:
                                return <React.Fragment>
                                    <StepBar>
                                        <li></li>
                                        <li className={'active'}></li>
                                    </StepBar>
                                    <CustomForm>
                                        <CustomInputGroup>
                                            <CustomInputIcon>
                                                <img src={'/login_bg/icon/bank.svg'} alt={'user'} />
                                            </CustomInputIcon>
                                            <CustomSelect className={'form-control'} value={bankState}
                                                onChange={({ target }) => setBankState(target.value)}>
                                                <option value={""}>{t('bank')}</option>
                                                {bankInfoState.map((b, bIndex) => (
                                                    <option key={bIndex} value={b._id}>{b.name_en} - {b.name_th}</option>
                                                ))}
                                            </CustomSelect>
                                        </CustomInputGroup>
                                        <CustomInputGroup>
                                            <CustomInputIcon>
                                                <img src={'/login_bg/icon/account_number.svg'} alt={'user'} />
                                            </CustomInputIcon>
                                            <CustomInput
                                                type={'number'}
                                                className={'form-control'}
                                                placeholder={t('account-number')}
                                                value={accountNumberState}
                                                onChange={({ target }) => verifyAccount(target)} />
                                            <ErrorText color={'#ff3e3e'}> {validAccount.message}</ErrorText>
                                        </CustomInputGroup>
                                        <CustomInputGroup>
                                            <CustomInputIcon>
                                                <img src={'/login_bg/icon/account_number.svg'} alt={'password'} />
                                            </CustomInputIcon>
                                            <CustomInput
                                                type={'text'}
                                                className={'form-control'}
                                                placeholder={t('account-name')}
                                                value={accountNameState}
                                                onChange={({ target }) => verifyAcountNumber(target.value)}
                                            />
                                        </CustomInputGroup>
                                        <CustomInputGroup center>
                                            <button
                                                type={'button'}
                                                disabled={loadingState}
                                                onClick={() => _signup()}
                                                className={'btn btn__signup'}>{t(loadingState ? 'loading' : 'signup')}</button>
                                        </CustomInputGroup>
                                    </CustomForm>
                                </React.Fragment>;
                            default:
                                return null;
                        }
                    })()}
                    <ModalSingup open={open} onCloseModal={onCloseModal} notCloseModal={notCloseModal} title={t(title)} subtitle={t(subtitle)} imgtitle={imgtitle} buttonTitle={t(buttonTitle)} />
                </ColumnForm>
            </div>
        </LoginContain>
    </>)
}

export default Signup;
