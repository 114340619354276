import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import moment from 'moment';
import styled from 'styled-components';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DefaultTable from '../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../components/FilterBuilder/FilterMember/FilterMember';
import { getSession } from '../../../utils/login';
import DefaultBadge from '../../../components/Badge/DefaultBadge/DefaultBadge';
import subSTR from '../../../utils/hideText';
import currencyTransform from "../../../utils/currency";
import Back from '../../../components/Button/Back/BankClick';

import config from "config/default.json"

const TrTotal = styled.tr`
  line-height: 2;
  td {
      border-top: 1px solid #af8c50 !important;
      border-bottom: 1px solid #af8c50;
  }
  td:first-child {
      color: #af8c50;
      font-weight: 500;
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-left: 1px solid #af8c50;
  }
  td:last-child {
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-right: 1px solid #af8c50;
  }
`;

const columnList = [
  { title: "date", width: "auto" },
  { title: "order", width: "auto" },
  { title: "user", width: "auto" },
  { title: "product", width: "auto" },
  { title: "quantity", width: "auto" },
  { title: "amount", width: "auto" },
  { title: "shipping-cost", width: "auto" },
  { title: "total", width: "auto" },
  { title: "aff-expenses", width: "auto" }
];

const columnListSlot = [
  { title: "date", width: "auto" },
  { title: "reward", width: "auto" },
  { title: "Proportional Expenses", width: "auto" },
];

const columnListRebate = [
  { title: "date", width: "auto" },
  { title: "Rebate", width: "auto" },
  { title: "Proportional Expenses", width: "auto" },
];

const columnListDaySlot = [
	{ title: "username", width: "auto" },
	{ title: "reward", width: "auto" },
	{ title: "Proportional Expenses", width: "auto" },
];

const columnListDayRebate = [
  { title: "username", width: "auto" },
  { title: "Rebate", width: "auto" },
  { title: "Proportional Expenses", width: "auto" },
];

function ExpensesListDetail ({t}) {

  const [ dataList, setDataList ] = useState([]);
  const [ dataSlotList, setDataSlotList ] = useState([]);
  const [ dataSlotDayList, setDataSlotDayList ] = useState([]);
  const [ dataRebateDayList, setDataRebateDayList ] = useState([]);
  const [ dataRebateList, setDataRebateList ] = useState([]);
  const [ dataTotal, setDataTotal ] = useState([]);
  const [ dataRebateTotal, setDataRebateTotal ] = useState([]);
  const [ dataSlotTotal, setDataSlotTotal ] = useState([]);
  const [ dataSlotDayTotal, setDataSlotDayTotal ] = useState([]);
  const [ dataRebateDayTotal, setDataRebateDayTotal ] = useState([]);
  const [ stats, setStats ] = useState([]);
  const [ statusReward, setStatusReward ] = useState('pending');
  const [ month, setMonth ] = useState(moment().format('MMYYYY'));
  const [agentCode, setAgentCode] = useState('')
  // const [ page, setPage ] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [statusType, setStatusType] = useState("Pending");
  const [tabActive, setTabActive] = useState('REWARD')
  
  useEffect(()=> {
    const session = getSession();
    if (session) {
      setAgentCode(session.agentCode)
      if(tabActive === "REWARD"){
        _fetchDataList(session.agentCode)
      }else if(tabActive === "EVENT"){
        _fetchDataListSlot(session.agentCode)
      }else if(tabActive === "REBATE"){
        _fetchDataListRebate(session.agentCode)
      }
    }
  }, [month,statusReward]);

  async function _fetchDataList (agentCode) {
    const bodySet = {
        agentCode: agentCode,
        status: statusReward,
        date: month
    };
    const response =  await fetch(config.URL_API + '/getProportionalExpensesListDetail', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_data.orderList !== undefined) {
        const result = data.response_data.orderList;
        const total = data.response_data.total;
        setDataTotal(total)
        setDataList(result);
      }
    }
  }

  async function _fetchDataListSlot (agentCode) {
    const bodySet = {
        agentCode: agentCode,
        date: month
    };
    const response =  await fetch(config.URL_API + '/getProportionalExpensesSlotEachDate', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_data.dateList !== undefined) {
        const result = data.response_data.dateList;
        const total = data.response_data.total;
        setDataSlotTotal(total)
        setDataSlotList(result);
      }
    }
  }

  async function _fetchDataListRebate (agentCode) {
    const bodySet = {
        agentCode: agentCode,
        date: month
    };
    const response =  await fetch(config.URL_API + '/getProportionalExpensesRebateEachDate', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_data.dateList !== undefined) {
        const result = data.response_data.dateList;
        const total = data.response_data.total;
        setDataRebateTotal(total)
        setDataRebateList(result);
      }
    }
  }

  const _setStatusReward = async(v)=>{
    setStatusReward(v)
  }

	const actionMonth = (e)=>{
		setMonth(e.target.value)
	}

  	const toggle = (l) => {
		setDropdownOpen(prevState => !prevState);
		setStatusType(l)
		_setStatusReward(l.toLowerCase())
	}

    const activeBtn = (e) => {
		  console.log(e)
      setTabActive(e)
      if(e === "REWARD"){
        _fetchDataList(agentCode)
      }else if(e === "EVENT"){
        _fetchDataListSlot(agentCode)
      }else if(e === "REBATE"){
        _fetchDataListRebate(agentCode)
      }
	}

	const getExpensessByDay = async d =>{
		setTabActive("HISLOTDAY")
		const bodySet = {
			agentCode: agentCode,
			dateDetail: d
		};
		const response =  await fetch(config.URL_API + '/getProportionalExpensesSlotDetail', {
		  method: "POST",
		  headers: { "Content-Type": "application/json" },
		  body: JSON.stringify(bodySet)
		});
		if (response.ok) {
		  const data = await response.json();
		  if (data.response_data.memberList !== undefined) {
			const result = data.response_data.memberList;
			const total = data.response_data.total;
			setDataSlotDayTotal(total)
			setDataSlotDayList(result);
		  }
		}
  }

  const getExpensessRebateByDay = async d =>{
		setTabActive("REBATEDAY")
		const bodySet = {
			agentCode: agentCode,
			dateDetail: d
		};
		const response =  await fetch(config.URL_API + '/getProportionalExpensesRebateDetail', {
		  method: "POST",
		  headers: { "Content-Type": "application/json" },
		  body: JSON.stringify(bodySet)
		});
		if (response.ok) {
		  const data = await response.json();
		  if (data.response_data.memberList !== undefined) {
			const result = data.response_data.memberList;
			const total = data.response_data.total;
			setDataRebateDayTotal(total)
			setDataRebateDayList(result);
		  }
		}
  }

  	return (
      <div>
        <Breadcrumb title={'Proportional Expenses Detail'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
        <FilterMember t={t} data={stats}  
          isShowMonth={true} 
          actionMonth={actionMonth} 
          isShowLevel={true} 
          isShowReward={true} 
          actionReward={_setStatusReward} 
          dropdownOpen={dropdownOpen} 
          statusType={statusType} 
          toggle={(l) => toggle(l)}/>
           {(() => {
                 switch(tabActive) {
                    case "REWARD":
                        return <>
                          <DefaultTable t={t} headers={columnList}>
                          {
                            dataList.map((m, mIndex) => (
                                <tr key={mIndex}>
                                    <td><DefaultBadge text={m.date} /></td>
                                    <td>{m.orderId}</td>
                                    <td>{subSTR(m.username)}</td>
                                    <td><img src={m.productImage} width="100px" /></td>
                                    <td>{m.quantity}</td>
                                    <td>{m.amount}</td>
                                    <td>{m.shippingCost}</td>
                                    <td>{m.total}</td>
                                    <td>{m.affExpenses}</td>
                                </tr>
                            ))
                          }
                          <TrTotal>
                            <td colSpan={4}>{t('total')}</td>
                            <td>{dataTotal.quantity}</td>
                            <td>{currencyTransform(dataTotal.amount)}</td>
                            <td>{currencyTransform(dataTotal.shippingCost)}</td>
                            <td>{currencyTransform(dataTotal.total)}</td>
                            <td>{currencyTransform(dataTotal.affExpenses)}</td>
                          </TrTotal>
                        </DefaultTable>
                    </>
                  case "EVENT":
                    return <>
                    <DefaultTable t={t} headers={columnListSlot}>
                          {
                            dataSlotList.map((m, mIndex) => (
                                <tr key={mIndex}>
                                    <td onClick={() => getExpensessByDay(m.keyDate)}><DefaultBadge text={m.date}/></td>
                                    <td>{currencyTransform(m.slotAmount)}</td>
                                    <td>{currencyTransform(m.slotRevenue)}</td>
                                </tr>
                            ))
                          }
                          <TrTotal>
                            <td>{t('total')}</td>
                            <td>{currencyTransform(dataSlotTotal.slotAmount)}</td>
                            <td>{currencyTransform(dataSlotTotal.slotRevenue)}</td>
                          </TrTotal>
                        </DefaultTable>
                    </>
                    case "REBATE":
                      return <>
                        <DefaultTable t={t} headers={columnListRebate}>
                            {
                              dataRebateList.map((m, mIndex) => (
                                  <tr key={mIndex}>
                                      <td onClick={() => getExpensessRebateByDay(m.keyDate)}><DefaultBadge text={m.date}/></td>
                                      <td>{currencyTransform(m.rebateAmount)}</td>
                                      <td>{currencyTransform(m.rebateRevenue)}</td>
                                  </tr>
                              ))
                            }
                            <TrTotal>
                              <td>{t('total')}</td>
                              <td>{currencyTransform(dataRebateTotal.rebateAmount)}</td>
                              <td>{currencyTransform(dataRebateTotal.rebateRevenue)}</td>
                            </TrTotal>
                          </DefaultTable>
                      </>
                  case "HISLOTDAY":
                    return <>
                      <Back text={t('back')} click={() => activeBtn('EVENT')}/>
                      <DefaultTable t={t} headers={columnListDaySlot}>
                        {
                        dataSlotDayList.map((m, mIndex) => (
                          <tr key={mIndex}>
                            <td>{subSTR(m.username)}</td>
                            <td>{currencyTransform(m.slotAmount)}</td>
                            <td>{currencyTransform(m.slotRevenue)}</td>
                          </tr>
                        ))
                        }
                        <TrTotal>
                          <td>{t('total')}</td>
                        <td>{currencyTransform(dataSlotDayTotal.slotAmount)}</td>
                        <td>{currencyTransform(dataSlotDayTotal.slotRevenue)}</td>
                        </TrTotal>
                      </DefaultTable>
                    </>
                    case "REBATEDAY":
                      return <>
                        <Back text={t('back')} click={() => activeBtn('REBATE')}/>
                        <DefaultTable t={t} headers={columnListDayRebate}>
                          {
                          dataRebateDayList.map((m, mIndex) => (
                            <tr key={mIndex}>
                              <td>{subSTR(m.username)}</td>
                              <td>{currencyTransform(m.rebateAmount)}</td>
                              <td>{currencyTransform(m.rebateRevenue)}</td>
                            </tr>
                          ))
                          }
                          <TrTotal>
                            <td>{t('total')}</td>
                          <td>{currencyTransform(dataRebateDayTotal.rebateAmount)}</td>
                          <td>{currencyTransform(dataRebateDayTotal.rebateRevenue)}</td>
                          </TrTotal>
                        </DefaultTable>
                      </>
                  }
                })()}
          </div>
  );
}

export default ExpensesListDetail;