import React, { useState, useEffect } from "react";
import { useHistory, useLocation, NavLink, Link } from "react-router-dom";
import { UncontrolledCollapse, Collapse, CardBody, Card } from 'reactstrap';
import styled from 'styled-components';
import { detailQuestion } from '../../utils/question';
import SearchBox from '../../components/SearchBox/SearchBox';

const TopQA = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
`;

const TextQA = styled.div`
  font-size: 28px;
`;

const Accordion = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const TitltAccordion = styled.div`
  padding: 15px;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  color: #000;
  position: relative;
  font-size: 14px;
  background: #fff;
  margin-bottom: 3px;
  text-align: left;
  border-radius: .25rem;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.15);

  &::before {
    content: ">";
    font-size: 15px;
    color: #828282;
    transition: transform .3s ease-in-out;
    position: absolute;
    right: 30px;
    font-family: monospace;
  }

  &.is-expanded {
    transition: background .3s;
    
    &::before {
      content: "v";
      transform: rotate(360deg);
    }
  }
`;

const DotCustom = styled.div`
  display: inline-block;
  height: 13px;
  width: 13px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #d5a95c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.div`
  position: relative;
  display: inline-block;
  justify-content: flex-end;
  padding: 0.275rem;
  background-color: transparent;
  width: 100%;
  border: unset;
  cursor: pointer;
  &.active {
    background-color: transparent;
    color: #af8c50;
    border: unset;
    .li__custom {
      background-color: #af8c50;
    }
  }
  &:hover {
    color: #af8c50;
    text-decoration: none;
    .li__custom {
      background-color: #af8c50;
    }
  }
`;

const ContentAccordion = styled.div`
  font-size: 14px;
  color: #828282;
  padding: 15px;
  border: unset;
  border-top: 0;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.15);
  a {
    color: #828282;
  }
`;

function Question({ t }) {

  const [_id, set_id] = useState('');

  const onEntered = (id) => {
    let newId = id === _id ? '' : id
    set_id(newId)
  }

  return (
    <React.Fragment>
      <div className={'container'}>
        <TopQA>
          <TextQA>{t('question-top')}</TextQA>
        </TopQA>
        <Accordion>
          {detailQuestion.map((d, dIndex) => {
            return(
              <div key={dIndex}>
              <div>
                <TitltAccordion id={d.id} className={_id === d.id ? 'is-expanded' : ''} onClick={(id) => onEntered(d.id)}>
                  {t(d.nameTopic)}
                </TitltAccordion>
                  <Collapse isOpen={_id === d.id} >
                  <ContentAccordion>
                    {
                      d.listTopic.map((dl, dlIndex) => (
                        <div key={dlIndex}>
                          <Link to={`/question/detail?id=${d.id}&no=${dlIndex}`}>
                            <ListItem>
                              <DotCustom className={'li__custom'} />
                              {t(dl)}
                            </ListItem>
                          </Link>
                        </div>
                      ))
                    }
                  </ContentAccordion>
                </Collapse>
              </div>
            </div>
            )
          })}
        </Accordion>

      </div>
    </React.Fragment>

  )
}

export default Question;