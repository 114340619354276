import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomBadge = styled.div`
    color: #131212;
    border: 1px solid #AF8C50;
    border-radius: 5px;
    padding: 5px 0px;
    transition: all 350ms ease-in-out;
    cursor: pointer;
    &:hover, &:focus {
      color: #FFFFFF;
      background-color: #AF8C50;
    }
`;

function DefaultBadge ({ text, children}) {
  return (
  <CustomBadge>{ text ? text : children }</CustomBadge>
  );
}

DefaultBadge.propTypes = {
  text: PropTypes.string
};

export default DefaultBadge;