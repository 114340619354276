import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import styled from 'styled-components';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import FilterMember from '../../../../components/FilterBuilder/FilterMember/FilterMember';
import DefaultBadge from "../../../../components/Badge/DefaultBadge/DefaultBadge";
import { getSession } from '../../../../utils/login';
import currencyTransform from "../../../../utils/currency";
import currencyColor from "../../../../utils/numericColor";
import subSTR from '../../../../utils/hideText';
import Back from '../../../../components/Button/Back/Back';
import { menuList } from '../../../../utils/menuList'
import config from "config/default.json"

const TableContain = styled.div``;

const CustomTable = styled.table`
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0 5px;
  thead {
      background-color: #313131;
      color: #FFF;
  }
  thead th {
      border-top: unset;
      border-bottom: unset;
  }
  th {
      text-align: center;
      padding: 5px;
      vertical-align: middle !important;
  }
  
  tbody tr {
      background-color: #FFF;
  }

  thead tr:first-child th:first-child {
      border-radius: 0.5em 0 0 0.5em;
  }
  thead tr:first-child th:last-child {
      border-radius: 0 0.5em 0.5em 0;
  }
  tbody tr td:first-child{
      border-radius: 0.5em 0 0 0.5em;
  }
  tbody tr td:last-child {
      border-radius: 0 0.5em 0.5em 0;
  }

  tbody tr td {
      vertical-align: middle;
      text-align: center;
      padding: .35rem;
      border-top: unset;
  }

  th:nth-child(4)
  {
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      border-right: 1px solid;
  }

  th:nth-child(5)
  {
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      border-top-left-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
  }

  th:nth-child(6)
  {
      border-top-left-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
      border-left: 1px solid;
  }

  th.th-radius{
      border-top-right-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      border-top-left-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
      border: 1px solid;
  }
`;

const TrTotal = styled.tr`
  line-height: 2;
  td {
    border-top: 1px solid #af8c50 !important;
    border-bottom: 1px solid #af8c50;
  }
  td:first-child {
    color: #af8c50;
    font-weight: 500;
    border-top: 1px solid #af8c50;
    border-bottom: 1px solid #af8c50;
    border-left: 1px solid #af8c50;
  }
  td:last-child {
    border-top: 1px solid #af8c50;
    border-bottom: 1px solid #af8c50;
    border-right: 1px solid #af8c50;
  }
`;

function SubAffList(props) {
  const t = props.t
  const [dataList, setDataList] = useState([]);
  const [dataTotal, setDataTotal] = useState([]);
  const [stats, setStats] = useState([]);
  const [date, setDate] = useState([]);
  const [filter, setFilter] = useState([]);
  const [menu, setMenu] = useState('');
  // const [ page, setPage ] = useState(0);

  useEffect(() => {
    const session = getSession();
    if (session) {
      _fetchDataList(session.agentCode);
      setFilter(session)
    }
  }, []);

  async function _fetchDataList(agentCode) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    setMenu(menuList(search) , true)
    let date = params.get('date');
    setDate(date)
    const bodySet = {
      agentCode: agentCode,
      dateDetail: date
    };
    const response = await fetch(config.URL_API + '/getDetailActivityReportEachDate', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.memberList;
        const total = data.response_data.total;
        setDataList(result);
        setDataTotal(total);
      }
    }
  }

  const handleLink = async (username) => {
    window.open(`/report/activity-webdetail?username=${username}&date=${date}`, "_self")
  };

  return (
    <div>
      <Breadcrumb title={'Detailed Activity Report'} menu={menu}/>
      <FilterMember t={t} data={stats} isShowDate={false} isShowLevel={true} />
      <Back text={props.t('back')} link={'/report/detail-activity-report'} />
      <TableContain className={'table-responsive'}>
        <CustomTable className={'table'}>
          <thead className="text-center">
            <tr>
              <th rowSpan="2">{props.t('no')}</th>
              <th rowSpan="2">{props.t('level')}</th>
              <th rowSpan="2">{props.t('user')}</th>
              <th rowSpan="2">{props.t('deposit')}</th>
              <th colSpan="4">{props.t('winloss')}</th>
              <th rowSpan="2">{props.t('bonus')}</th>
              <th rowSpan="2">{props.t('winloss-return')}</th>
              <th rowSpan="2">{props.t('net-winloss')}</th>
              <th rowSpan="2">{props.t('reward')}</th>
            </tr>
            <tr>
              <th className="th-radius">{props.t('sport')}</th>
              <th className="th-radius">{props.t('casino')}</th>
              <th className="th-radius">{props.t('games')}</th>
              <th className="th-radius">{props.t('esport')}</th>
            </tr>
          </thead>

          <tbody>
            {
              dataList.map((m, mIndex) => (
                <tr key={mIndex}>
                  <td>{mIndex + 1}</td>
                  <td>{m.level}</td>
                  <td onClick={() => handleLink(m.username)}><DefaultBadge text={subSTR(m.username)} /></td>
                  <td>{currencyTransform(m.deposit)}</td>
                  <td className={m.winloseSport < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseSport)}</td>
                  <td className={m.winloseCasino < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseCasino)}</td>
                  <td className={m.winloseGame < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseGame)}</td>
                  <td className={m.winloseEsport < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseEsport)}</td>
                  <td>{currencyColor(m.bonus, 'text__danger')}</td>
                  <td className={m.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseReturn)}</td>
                  <td className={m.netWinloss < 0 ? 'text__danger' : ''}>{currencyTransform(m.netWinloss)}</td>
                  <td>{currencyColor(m.reward, 'text__danger')}</td>
                </tr>
              ))
            }
            <TrTotal>
              <td colSpan={3}>{props.t('total')}</td>
              <td>{currencyTransform(dataTotal.deposit)}</td>
              <td className={dataTotal.winloseSport < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseSport)}</td>
              <td className={dataTotal.winloseCasino < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseCasino)}</td>
              <td className={dataTotal.winloseGame < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseGame)}</td>
              <td className={dataTotal.winloseEsport < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseEsport)}</td>
              <td>{currencyColor(dataTotal.bonus, 'text__danger')}</td>
              <td className={dataTotal.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseReturn)}</td>
              <td className={dataTotal.netWinloss < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.netWinloss)}</td>
              <td>{currencyColor(dataTotal.reward, 'text__danger')}</td>
            </TrTotal>
          </tbody>
        </CustomTable>
      </TableContain>
    </div>
  );
}

export default SubAffList;