import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { menuSupportItem } from '../../utils/supports';
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import PageSubSupport from './SubSupport/SubSupport'

const RowMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 3px;
  border-bottom: 1px solid rgba(0,0,0,.1);
`;

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  color: #af8c50;
  font-size: 20px;
`;

const ButtonBack = styled.div`
  margin-right: 20px;
`;

const ButtonQuestion = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #FFF;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 0.35rem 0.75rem;
  font-size: 15px;
  color: #828282;
  border-color: transparent;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;

const ColLeft = styled.div`
  text-align: center;
  margin: auto;
`;

const ColRight = styled.div`
  font-size: 11px;
  text-align: center;
  margin: auto;
  img {
    width: 80%;
  }
  @media (max-width: 768px) {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid #e2e2e2;
    img {
      width: 40%;
    }
  }
`;

const ColRightText = styled.div`
  margin-top: 10px;
`;

const SelectContain = styled.div`
  margin: 0 10px 0 20px;
  cursor: pointer;

  select {
    border: unset;
    font-size: 15px;
    color: #828282;
    border-color: transparent;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.15);
    height: 100%;
    width: 100%;
    &.form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: transparent;
      outline: 0;
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.15);
  }
  }

  // @media (max-width: 767px) {
  //   padding-left: 10px;
  // }
`;

const CustomButton = styled.button`
  font-size: 14px;
  color: #828282;
  border-color: transparent;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.15);
  margin-left: 2px;
  height: 100%;
  padding: 0.35rem 0.75rem;
  img {
    width: 6px;
    transform: rotate(180deg);
    margin-right: 10px;
    margin-top: -2px;
  }
`;

function Home({ t }) {

  const [params, setParams] = useState('')
  const [data, setData] = useState('')
  const [activeReport, setActiveReport] = useState('1')

  useEffect(function () {
    getSearch(window.location.search)

  }, []);

  function getSearch(text) {
    const str = text.split("=", 2)
    setParams(str[1])
    const index = menuSupportItem.findIndex(m => m.text === str[1])
    if (index !== -1) {
      setData(menuSupportItem[index])
    }
  }

  function getActiveReport(e) {
    setActiveReport(e.target.value)
  }

  return (

    <div className={'overflowpage'}>
      <RowMenu>
        <div className={'d-flex'}>
          <ButtonBack>
            <Link to={'/support'}>
              <CustomButton type={'button'} className={'btn'} >
                <img src={'/support/icon/arow.svg'} alt={"icon-support"} />
                {t('back')}
              </CustomButton>
            </Link>
          </ButtonBack>
          <TitleDetail>
            <img src={data.iconPath} alt={"icon"} width={'30px'} height={"30px"} className={'mr-2'} />
            <div>{t(data.text)}</div>

            <SelectContain>
              {data.text === 'report' ? <div>
                <Input type="select" name="select" id="reportSelect" onChange={getActiveReport}>
                  <option value="1">{t('Detailed Activity Report')}</option>
                  <option value="2">{t('earning-report')}</option>
                  <option value="3">{t('Product Report')}</option>
                  <option value="4">{t('Sub Affiliate Report')}</option>
                </Input>
              </div> : ''}
              {data.text === 'marketing-media' ? <div>
                <Input type="select" name="select" id="mediaSelect" onChange={getActiveReport}>
                  <option value="1">{t('Links')}</option>
                  <option value="2">{t('Gallery')}</option>
                </Input>
              </div> : ''}
              {data.text === 'finances' ? <div>
                <Input type="select" name="select" id="mediaSelect" onChange={getActiveReport}>
                  <option value="1">{t('Earning Summary')}</option>
                  <option value="2">{t('Proportional Expenses')}</option>
                </Input>
              </div> : ''}
              {data.text === 'member' ? <div>
                <Input type="select" name="select" id="mediaSelect" onChange={getActiveReport}>
                  <option value="1">{t('Member List')}</option>
                  <option value="2">{t('Member Report')}</option>
                  <option value="3">{t('Member Deposit')}</option>
                </Input>
              </div> : ''}
              {data.text === 'sub-affiliate' ? <div>
                <Input type="select" name="select" id="mediaSelect" onChange={getActiveReport}>
                  <option value="1">{t('Sub Affiliate List')}</option>
                  <option value="2">{t('Sub Affiliate Report')}</option>
                </Input>
              </div> : ''}
            </SelectContain>
          </TitleDetail>
        </div>
        {/* <div>
          <ButtonQuestion>
            <div className={'mr-2'}>{t('question-top')}</div>
            <img src={'/support/icon/question.svg'} alt={"icon"} width={'15px'} />
          </ButtonQuestion>
        </div> */}
      </RowMenu>

      <div className={'container'}>
        {data.text === params ? <PageSubSupport t={t} activeReport={activeReport} page={params} /> : null}
      </div>
    </div>

  )
}

export default Home;