import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomInput = styled.input`
    width: 250px;
    border-radius: 25px;
    font-size: 13px;
    background-image: url("/icon/search.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50px 15px;
    margin: 0 10px 0 0;
`;

function SearchBox ({ placeholder, changed }) {
    return (<CustomInput type={'text'} className={'form-control'} onChange={({ target }) => changed(target.value)} placeholder={placeholder} />);
}

SearchBox.propTypes = {
    placeholder: PropTypes.string
};

export default SearchBox;