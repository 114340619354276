import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomButton = styled.button`
    font-size: 14px;
    color: #828282;
    border-color: transparent;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.15);
    margin-left: 2px;
    height: 100%;
    padding: 0.35rem 0.75rem;
    img {
        width: 6px;
        transform: rotate(180deg);
        margin-right: 10px;
        margin-top: -2px;
    }
`;

function Back ({ text, link }) {
    return (
        <CustomButton type={'button'} className={'btn'} onClick={()=> window.open(`${link}`,"_self")} >
            <img src={'/support/icon/arow.svg'} alt={"icon-support"} />
            {text}
        </CustomButton>
    );
}

Back.propTypes = {
    text: PropTypes.string
};

export default Back;