import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomButton = styled.button`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #828282;
    border-color: #828282;
    // color: #AF8C50;
    // border-color: #AF8C50;
    background-color: transparent;
    img {
        padding-right: 5px;
        filter: grayscale(1) brightness(1);
    }

    &.btn__disabled {
        filter: grayscale(1) brightness(1);
        pointer-events: none; 
        &:hover {
            color: #8f8f8f;
        }
    }

    &:hover, &:focus, &.active {
        color: #AF8C50;
        border-color: #AF8C50;
        box-shadow: unset;
        img {
            filter: unset;
        }
    }

    @media (max-width: 767px) {
        border: unset;
        color: #828282;
        img {
            display: none;
        }
        &:focus, &.active {
            color: #AF8C50;
            border-bottom: 2px solid #AF8C50;
            box-shadow: unset;
            border-radius: unset;
        }
    }
    
`;

const images = {
    'ALL': '',
    'Sup - Affiliate': '',
    HIALLBET: 'hiallbet',
    HIREWARD: 'hirewards_color',
    HISLOT: 'hislot_color',
    ANGPAO: 'angpao_color',
    REWARD: 'reward_color',
    EVENT: 'event_color',
    REBATE: 'rebate_color',
    'ALL GAME': 'allgame_color',
    'COMING SOON': '',
    'SUMMARY': '',
}

function OutlineButton({ text, className, onClick }) {
    return (
        <CustomButton type={'button'} className={className} onClick={() => onClick(text)}>
            {images[text] !== '' ? <img src={`/icon/${images[text]}.svg`} alt={'icon'} width={'25px'} height={'20px'} /> : '' }
            <span>{text}</span>
        </CustomButton>
    );
}

OutlineButton.propTypes = {
    text: PropTypes.string
};

export default OutlineButton;