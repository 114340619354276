export function menuList(param, status) {
  if (param.indexOf("date") > -1 && param.indexOf("username") === -1) {
    return [param.split("?date=")[1], ""];
  } else if (param.indexOf("username") > -1 && status) {
    const data = param.split("&");
    const date = data[1].split("date=");
    const username = data[0].split("?username=");
    return [date[1], " / " + username[1]];
  } else if (!status) {
    const data = param.split("&");
    const username = data[0].split("?username=");
    return [username[1], ""];
  }
}

export function menuAgent(param, status) {
  if (param.indexOf("date") > -1 && param.indexOf("agentCode") === -1) {
    return [param.split("?date=")[1], ""];
  } else if (param.indexOf("agentCode") > -1 && status && param.indexOf("date") > -1) {
    const data = param.split("&");
    const date = data[1].split("date=");
    const agentCode = data[0].split("?agentCode=");
    return [date[1], " / " + agentCode[1]];
  } else if (param.indexOf("agentCode") > -1 && status && param.indexOf("date") === -1) {
    const data = param.split("&");
    const agentCode = data[0].split("?agentCode=");
    return [ agentCode[1] , ""];
  } else if (param.indexOf("date") > -1 && param.indexOf("agentCode") > -1 && param.indexOf("username") > -1) {
    const data = param.split("&");
    const date = data[2].split("date=");
    const agentCode = data[1].split("agentCode=");
    const username = data[0].split("?username=");
    return [date[1] ,  " / " + agentCode[1] ,  " / " + username[1]];
  } else if (param.indexOf("agentCode") > -1 && param.indexOf("username") > -1) {
    const data = param.split("&");
    const agentCode = data[1].split("agentCode=");
    const username = data[0].split("?username=");
    return [agentCode[1] ,  " / " + username[1]];
  }
}