import React from 'react';
import styled from 'styled-components';

const NotifiactionContain = styled.div`
    border-left: 1px solid #e2e2e2;
    height: 75px;
    line-height: 75px;
    padding-left: 10px;
    cursor: pointer;
    @media (max-width: 1023px) {
		border-left: unset;
        height: auto;
        line-height: unset;
        padding-right: 10px;
	}
`;

function NotificationBox () {
    return (
        <NotifiactionContain>
            <img src={'/icon/e_mail.svg'} alt={'icon-email'} />
        </NotifiactionContain>
    );
}

export default NotificationBox;