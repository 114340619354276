import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import moment from 'moment';
import styled from 'styled-components';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import DefaultTable from '../../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../../components/FilterBuilder/FilterMember/FilterMember';
import { getSession } from '../../../../utils/login';
import currencyTransform from "../../../../utils/currency";
import currencyColor from "../../../../utils/numericColor";
import subSTR from '../../../../utils/hideText';
import Back from '../../../../components/Button/Back/Back';
import DefaultBadge from '../../../../components/Badge/DefaultBadge/DefaultBadge';
import MemberBadge from '../../../../components/Badge/MemberBadge/MemberBadge';
import { menuAgent } from '../../../../utils/menuList'
import config from "config/default.json"

const TrTotal = styled.tr`
  line-height: 2;
  td {
      border-top: 1px solid #af8c50 !important;
      border-bottom: 1px solid #af8c50;
  }
  td:first-child {
      color: #af8c50;
      font-weight: 500;
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-left: 1px solid #af8c50;
  }
  td:last-child {
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-right: 1px solid #af8c50;
  }
`;

const columnList = [
  { title: "no", width: "auto" },
  { title: "level", width: "auto" },
  { title: "user", width: "auto" },
  { title: "deposit", width: "auto" },
  { title: "turnover", width: "auto" },
  { title: "winloss", width: "auto" },
  { title: "bonus", width: "auto" },
  { title: "winloss-return", width: "auto" },
  { title: "net-winloss", width: "auto" },
  { title: "reward", width: "auto" },
];

function SubAffList({t}) {

  const [dataList, setDataList] = useState([]);
  const [dataTotal, setDataTotal] = useState([]);
  const [stats, setStats] = useState([]);
  const [filter, setFilter] = useState([]);
  const [agentCode, setAgentCode] = useState('');
  const [menu, setMenu] = useState('');
  // const [ page, setPage ] = useState(0);

  useEffect(() => {
    const session = getSession();
    if (session) {
      _fetchDataList(session.agentCode);
      setFilter(session)
    }
  }, []);

  async function _fetchDataList() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let agentCode = params.get('agentCode');
    let date = params.get('date');
    setMenu(menuAgent(search , true))
    setAgentCode(agentCode)
    const bodySet = {
      agentCode: agentCode,
      date: date,
    };
    const response = await fetch(config.URL_API + '/getSubAffEarningBreakdownActivityReport', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.memberList;
        const total = data.response_data.total;
        setDataList(result);
        setDataTotal(total)
      }
    }
  }

  const handleLink = async (username) => {
    window.open(`/report/sub-affiliate-breakdown-webdetail?username=${username}&agentCode=${agentCode}`, "_self")
  };

  return (
    <div>
      <Breadcrumb title={'Sub-Affiliate Report'} menu={menu}/>
      <FilterMember t={t} data={stats} isShowDate={false} isShowLevel={true} />
      <Back text={t('back')} link={'/report/sub-affiliate-breakdown'} />
      <DefaultTable t={t} headers={columnList}>
        {
          dataList.map((m, mIndex) => (
            <tr key={mIndex}>
              <td>{mIndex + 1}</td>
              <td><MemberBadge text={m.level} color={m.levelColor} /></td>
              <td onClick={() => handleLink(m.username)}><DefaultBadge text={subSTR(m.username)} /></td>
              <td>{currencyTransform(m.deposit)}</td>
              <td>{currencyTransform(m.turnOver)}</td>
              <td className={m.winloss < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloss)}</td>
              <td>{currencyColor(m.bonus, 'text__danger')}</td>
              <td className={m.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseReturn)}</td>
              <td className={m.total < 0 ? 'text__danger' : ''}>{currencyTransform(m.total)}</td>
              <td>{currencyColor(m.reward, 'text__danger')}</td>
            </tr>
          ))
        }
        <TrTotal>
          <td colSpan={3}>{t('total')}</td>
          <td>{currencyTransform(dataTotal.deposit)}</td>
          <td>{currencyTransform(dataTotal.turnOver)}</td>
          <td className={dataTotal.winloss < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloss)}</td>
          <td>{currencyColor(dataTotal.bonus, 'text__danger')}</td>
          <td className={dataTotal.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseReturn)}</td>
          <td className={dataTotal.total < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.total)}</td>
          <td>{currencyColor(dataTotal.reward, 'text__danger')}</td>
        </TrTotal>
      </DefaultTable>
    </div>
  );
}

export default SubAffList;