import React, { useEffect, useState } from 'react';
import { getSession } from '../../../utils/login';
import { Player } from 'video-react';

import styled from 'styled-components';
import BlogFilter from '../../../components/FilterBuilder/FilterGallery/GalleryFilter'
import Breadcrumb from '../../../components/Breadcrumb/BreadcrumbType';
import Paging from '../../../components/Paging/Paging';
import "video-react/dist/video-react.css";
import classnames from 'classnames';
import config from "config/default.json"

const Card = styled.div`
    background-color: #FFF;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    padding-bottom: 15px;
    position: relative;
    width: 100%;
`;

const CardThumbnail = styled.div`
    width: 100%;
`;

const CardTitle = styled.div`
    font-size: 14px;
    height: 42px;
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const CardDescription = styled.div`
    text-align: center;
    margin-top: 5px;
    font-size: 13px;
    padding: 0 10px;
    height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const CardButton = styled.div`
    margin-top: 15px;
    text-align: center;
`;

const CustomButton = styled.button`
    background-color: #AF8C50;
    color: #FFF;
    font-size: 12px;
    width: auto;
    padding: .25rem .75rem;
`;

const BlogDownload = styled.div`
    position: absolute;
    margin-top: -29px;
    text-align: center;
    width: 100%;
    background-image: linear-gradient(#ffffff14,#000000);
    padding-bottom: 5px;
`

const TextDownload = styled.span`
    font-size : 12px;
    margin-left : 5px;
    color : #fff;
    cursor: pointer;
    &:hover, &:focus {
        text-decoration: underline;
    }
`
const BlogStar = styled.div`
    position: absolute;
    width: 100%;
    text-align: right;
    padding-top: 5px;
    padding-right: 5px;
    background-image: linear-gradient(#00000059 ,#ffffff14);
    
    img:hover{
        cursor: pointer;
    }
`

const FilterBox = styled.div`
    display: flex;
    font-size: 13px;
    padding: 20px 15px 5px 15px;
    span {
        color: #AF8C50;
    }
    div {
        cursor: pointer;
        &.active{
            border-bottom: 1px solid #AF8C50;
        }
    }
`;

const demo_img = [
    {
        "img": "/demo_gallery/1.jpg",
        "name": "Birthday Max 500",
        "pinned": false
    },
    {
        "img": "/demo_gallery/2.jpg",
        "name": "E Wallet",
        "pinned": false
    },
    {
        "img": "/demo_gallery/3.jpg",
        "name": "Hiallbet Event",
        "pinned": false
    },
    {
        "img": "/demo_gallery/4.jpg",
        "name": "EZGame Spotlight 1",
        "pinned": false
    },
    {
        "img": "/demo_gallery/5.jpg",
        "name": "EZGame Spotlight 2",
        "pinned": false
    },
    {
        "img": "/demo_gallery/6.jpg",
        "name": "EZGame Spotlight 3",
        "pinned": false
    },
    {
        "img": "/demo_gallery/7.jpg",
        "name": "Friends",
        "pinned": false
    },
    {
        "img": "/demo_gallery/8.jpg",
        "name": "Hey Friends",
        "pinned": false
    },
    {
        "img": "/demo_gallery/9.jpg",
        "name": "E Wallet",
        "pinned": false
    },
    {
        "img": "/demo_gallery/10.jpg",
        "name": "Hi reward",
        "pinned": false
    },
    {
        "img": "/demo_gallery/11.jpg",
        "name": "Friends",
        "pinned": false
    },
    {
        "img": "/demo_gallery/12.jpg",
        "name": "Slots & Games Welcome Bonus 100%",
        "pinned": false
    },
    {
        "img": "/demo_gallery/13.jpg",
        "name": "Work",
        "pinned": false
    }
];


function Gallery({t}) {
    const [data,setData] = useState([])
    const [userId,setUserId] = useState([])
    const [product,setProduct] = useState([])
    const [offer,setOffer] = useState([])
    const [lang,setLang] = useState('th')
    const [format,setFormat] = useState('')
    const [filter,setFilter] = useState({})
    const [favorite,setFavorite] = useState({})
    const [activeName, setActiveName] = useState('')
    const [isFaverite, setIsFaverite] = useState(false)
    const [tabActive, setTabActive] = useState('HIALLBET')
    const [countResult, setCountResult] = useState({
        all: 0,
        pin: 0 
    })
    const check = activeName || ''

    useEffect(() => {
        const session = getSession();
        if (session) {
            setUserId(session.userId)
            _fetchDataList({});
        }
    }, []);

    async function _fetchDataList(search) {
        const response = await fetch(config.URL_AGENT_API + `/media/${getSession().userId}/api`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(search)
        });
        if (response.ok) {
            const datas = await response.json();
            let countDatas = {all:0,pin:0}
            if (datas.results) {
                if(datas.results.fevorite && datas.results.fevorite.media){
                    setFavorite(datas.results.fevorite.media)
                    countDatas.pin = Object.keys(datas.results.fevorite.media).length
                }
                countDatas.all = datas.results.results.length
                setCountResult(countDatas) 
                setData(datas.results.results)
                setProduct(datas.results.product)
                setOffer(datas.results.offer)
            }
        }
    }

    const filterMedia = (e)=>{
        setFormat(e.target.value)
    }

    const filterType = (e)=>{
        let setType = filter
        const val = e.target.value
        if(val === ''){
            delete setType['type']
        }else{
            setType['type'] = e.target.value
        }
        setFilter(setType)
        _fetchDataList(filter)
    }

    const filterProduct = (e)=>{
        let setType = filter
        const val = e.target.value
        if(val === ''){
            delete setType['product']
        }else{
            setType['product'] = e.target.value
        }
        setFilter(setType)
        _fetchDataList(filter)
    }

    const filterOffer = (e)=>{
        let setType = filter
        const val = e.target.value
        if(val === ''){
            delete setType['offer']
        }else{
            setType['offer'] = e.target.value
        }
        setFilter(setType)
        _fetchDataList(filter)
    }

    const filterSize = (e)=>{
        let setType = filter
        const val = e.target.value
        if(val === ''){
            delete setType['size']
        }else{
            setType['size'] = e.target.value
        }
        _fetchDataList(filter)
    }

    const filterSort = (e) => {
        let setType = filter
        const val = e.target.value
        if(val === ''){
            delete setType['sort']
        }else{
            setType['sort'] = e.target.value
        }
        setFilter(setType)
        _fetchDataList(filter)
    }

    const filterLang = (e)=>{
        setLang(e.target.value)
    }

    async function downloadURI(url, name) {
        var link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.target = '_blank';
        link.click();
    }

    const checkFavorite = async(e) => {
        let favor = favorite
        if(favor[`${e}`]){
            delete favor[`${e}`]
            setCountResult({pin : countResult.pin - 1 }) 
        }else{
            favor[`${e}`] = true
            setCountResult({pin : countResult.pin + 1 }) 
        }
        await fetch(config.URL_AGENT_API + `/fevorite/${userId}/update`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(favor)
        });
        _fetchDataList(filter)
    }

    const actionPin = (e) => {
        if(e === 'result' || e === 'pin') {
            setActiveName(e)
            const active = e === 'pin' ? true : false
            setIsFaverite(active)
        }else {
            setActiveName()
        }
    }

    const renderBox = (preview,name,gIndex,description,link,_id) => {
        if(isFaverite){
            return favorite[`${_id}`] ? <div className={'col-2 col-md-3 col-lg-2 p-1'} key={gIndex}>
                <Card key={gIndex}>
                    <CardThumbnail> 
                        <BlogStar onClick={ () => checkFavorite(_id) }>
                            <img src={favorite[`${_id}`] ? `/icon/star_color.svg`:`/icon/star_white.svg`} alt="dl" />
                        </BlogStar>
                            {preview}
                        <BlogDownload onClick={ () => downloadURI(link,name) }> 
                            {/* <a href={link} download={link}> */}
                                <img src={`/icon/download.svg`} alt="dl" />
                                <TextDownload>{t('download')}</TextDownload>
                            {/* </a> */}
                        </BlogDownload>
                    </CardThumbnail>
                    <CardTitle>{name}</CardTitle>
                    <CardDescription>{description}</CardDescription>
                    <CardButton>
                        <CustomButton type={'button'} className={'btn'}>{t('more')}</CustomButton>
                    </CardButton>
                </Card>
            </div>: null
        }else{
            return <div className={'col-2 col-md-3 col-lg-2 p-1'} key={gIndex}>
                <Card key={gIndex}>
                    <CardThumbnail> 
                        <BlogStar onClick={ () => checkFavorite(_id) }>
                            <img src={favorite[`${_id}`] ? `/icon/star_color.svg`:`/icon/star_white.svg`} alt="dl" />
                        </BlogStar>
                            {preview}
                        <BlogDownload onClick={ () => downloadURI(link,name) }> 
                            {/* <a href={link} download={link}> */}
                                <img src={`/icon/download.svg`} alt="dl" />
                                <TextDownload>{t('download')}</TextDownload>
                            {/* </a> */}
                        </BlogDownload>
                    </CardThumbnail>
                    <CardTitle>{name}</CardTitle>
                    <CardDescription>{description}</CardDescription>
                    <CardButton>
                        <CustomButton type={'button'} className={'btn'}>{t('more')}</CustomButton>
                    </CardButton>
                </Card>
            </div>
        }
    }

    const activeBtn = (e) => {
        setTabActive(e)
        // console.log(e)
        // if (e === "HIALLBET") {
        //   _fetchDataList(agentCode)
        // } else if (e === "HISLOT") {
        //   _fetchDataListSlot(agentCode)
        // }
    }

    return (
        
        <div>
            <Breadcrumb title={'Gallery'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
            <BlogFilter 
                t={t} 
                filterMedia={filterMedia}
                filterType={filterType}
                filterProduct={filterProduct}
                filterOffer={filterOffer}
                filterSize={filterSize}
                filterLang={filterLang}
                filterSort={filterSort}
                product={product}
                offer={offer}
            />
            <FilterBox>
                <div className={'mr-2 ' + classnames({ active: check === 'result' })} onClick={() => actionPin('result')}>{t('results')} <span>({countResult.all})</span></div>
                <div className={classnames({ active: check === 'pin' })} onClick={() => actionPin('pin')}>{t('pinned')} <span>({countResult.pin})</span></div>
            </FilterBox>
            <div className={'row no-gutters mt-2'}>
                {data.map((g, gIndex) => {
                        let previewBox = []
                        if((format === 'image' && g.lang[`${lang}`].image) || (g.lang[`${lang}`].image && format === '')){
                            let imgBox = <img className={'img-fluid'} src={g.lang[`${lang}`].image} alt={g.name} />
                            previewBox = [renderBox(imgBox,g.name,gIndex,g.description,g.lang[`${lang}`].image,g._id)]
                        }
                        if((format === 'gif' && g.lang[`${lang}`].gif) || (g.lang[`${lang}`].gif && format === '')){
                            let gifBox = <img className={'img-fluid'} src={g.lang[`${lang}`].gif} alt={g.name} />
                            previewBox = [renderBox(gifBox,g.name,gIndex,g.description,g.lang[`${lang}`].gif,g._id),...previewBox]
                        }
                        if((format === 'vdo' && g.lang[`${lang}`].vdo) || (g.lang[`${lang}`].vdo && format === '')){
                            let vdoBox = <Player poster="/assets/poster.png" width={'300'} src={g.lang[`${lang}`].vdo} fluid={false} />
                            previewBox = [renderBox(vdoBox,g.name,gIndex,g.description,g.lang[`${lang}`].vdo,g._id),...previewBox]
                        }
                        return previewBox
                    }
                )}
            </div>
            <Paging />
        </div>
    )
}

export default Gallery;
