import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';

const TableContain = styled.div``;

const CustomTable = styled.table`
    font-size: 13px;
    border-collapse: separate;
    border-spacing: 0 5px;
    thead {
        background-color: #313131;
        color: #FFF;
    }
    thead th {
        border-top: unset;
        border-bottom: unset;
    }

    th {
        text-align: center;
        padding: 5px;
    }
    
    tbody tr {
        background-color: #FFF;
    }

    tbody tr td {
        vertical-align: middle;
        text-align: center;
        padding: .35rem;
        border-top: unset;
    }

    thead tr:first-child th:first-child {
        border-radius: 0.5em 0 0 0.5em;
    }
    thead tr:first-child th:last-child {
        border-radius: 0 0.5em 0.5em 0;
    }
    tbody tr td:first-child{
        border-radius: 0.5em 0 0 0.5em;
    }
    tbody tr td:last-child {
        border-radius: 0 0.5em 0.5em 0;
    }
`;
// onClick={h.click(h.type)}
function DefaultTable ({ children, headers, t }) {
    return (
        <TableContain className={'table-responsive'}>
            <CustomTable className={'table'}>
                <thead>
                    <tr>
                    { headers.map((h, hIndex) => (
                        <th key={hIndex} width={h.width} 
                            onClick={ h.click ? h.click : null }
                        >{t(h.title)}</th>
                    )) }
                    </tr>
                </thead>
                <tbody>
                    { children }
                </tbody>
            </CustomTable>
        </TableContain>
    );
}

DefaultTable.propTypes = {
    children: PropType.node,
    headers: PropType.array.isRequired
};

export default DefaultTable;