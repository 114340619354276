import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { logout, getSession } from '../../utils/login';

import ProfileBox from './ProfileBox/ProfileBox';
import NotificationBox from './NotificationBox/NotificationBox';
// import SearchBox from './SearchBox/SearchBox';

const NavbarContain = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    height: 75px;
    border-bottom: 1px solid #e2e2e2;
    @media (max-width: 1024px) {
      justify-content: flex-end;
    }
    @media (max-width: 767px) {
      background-color: #f7f7f7;
      position: fixed;
      width: 100%;
      z-index: 99;
    }
`;

const ActionContain = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    @media (max-width: 1023px) {
        display: none;
    }
`;

const ButtonLogout = styled.button`
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    font-size: 14px;
    &:hover {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130;
    }
`;

const NotiTab = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`;

function Navbar({ t, i18n }) {
  let history = useHistory();
  const [userState, setUserState] = useState({
    username: '',
    level: ''
  });

  useEffect(() => {
    const session = getSession();
    if (!session) {
      _logout();
    }
    setUserState({
      ...useState, ...{
        username: session.username,
        level: session.gen
      }
    })
  }, []);

  function _logout() {
    logout();
    history.push('/login');
  }

  const ContainLogout = window.innerWidth > 767 ? <ActionContain>
    <fieldset>
      <ButtonLogout
        type={'button'}
        className={'btn btn"ld'}
        onClick={() => _logout()}>{t('logout')}</ButtonLogout>
    </fieldset>
  </ActionContain> : null

  return <NavbarContain>
    <NotiTab>
      <NotificationBox />
    </NotiTab>
    <ProfileBox user={userState} />
    { ContainLogout }
  </NavbarContain>
}

export default Navbar;