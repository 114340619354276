import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';

import subSTR from '../../../utils/hideText';
import Breadcrumb from '../../../components/Breadcrumb/BreadcrumbType';
import DefaultTable from '../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../components/FilterBuilder/FilterMember/FilterMember';
import ActiveBadge from '../../../components/Badge/ActiveBadge/ActiveBadge';
import { getSession } from '../../../utils/login';
import config from "config/default.json"

function SubAffList ({t}) {

  const [ dataList, setDataList ] = useState([]);
  const [ stats, setStats ] = useState([]);
  const [ sortType, setSortType] = useState(1);
  const [ sortBy, setSortBy ] = useState('');
  const [ search, setSearch ] = useState('');
  const [ filterStatus, setFilterStatus ] = useState([]);
  const [ activeName , setActiveName] = useState('')
  const [tabActive, setTabActive] = useState('HIALLBET')
  // const [ page, setPage ] = useState(0);

   useEffect(() => {
    const session = getSession();
    if (session) {
      _fetchDataList(session.userId);
    }
  }, [sortType,search,filterStatus]);

  async function _fetchDataList (userId) {
    const bodySet = {
      userId: userId,
      skip: "0",
      sortBy: sortBy,
      sortType: sortType,
      search: search,
      filterStatus: filterStatus,
    };
    const response =  await fetch(config.URL_API + '/getAffListDetail', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      let statsGroup = [];
      if (data.response_result) {
        const result = data.response_data.memberList;
        const status = data.response_data.status;
        const totalUnactive = status.unactive ? status.unactive : 0
        statsGroup.push({ "level": "Active", "value": status.active });
        statsGroup.push({ "level": "UnActive", "value": status.unactive ? status.unactive : 0 });
        statsGroup.push({ "level": "All", "value": status.active + totalUnactive });
        setStats(statsGroup);
        setDataList(result);
      }
    }
  }

  function _searchText(keyword) {
    if (keyword.length > 2) {
        setSearch(keyword)
    }else if(keyword.length === 0){
        setSearch("")
    }
  }

  const sortby = (type) => {
      const sort = sortType === 1 ? -1 : 1
      setSortType(sort)
      setSortBy(type)
  }

  const actionLevel = (e)=>{
    console.log(e)
    setActiveName(e)
    if(e === 'Active' || e === 'UnActive'){
        setFilterStatus([e.toLowerCase()])
    }else if(e === 'All'){
        setFilterStatus([])
    }
  }

  const activeBtn = (e) => {
    setTabActive(e)
    // console.log(e)
    // if (e === "HIALLBET") {
    //   _fetchDataList(agentCode)
    // } else if (e === "HISLOT") {
    //   _fetchDataListSlot(agentCode)
    // }
  }

  const columnList = [
    { title: "no", width: "100px" },
    { title: "affcode", width: "150px" },
    { title: "user", width: "200px" },
    { title: "nickname", width: "150px" },
    { title: "status", width: "150px" },
    { title: "last-login", width: "200px", click: () => sortby('lastLogin') },
    { title: "createat", width: "200px", click: () => sortby('createAt') },
  ];

  return (
      <div>
      <Breadcrumb title={'Sub Affiliate List'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
      <FilterMember t={t} data={stats} actionLevel={(data) => actionLevel(data)} isShowDate={false} isShowLevel={true} search={(keyword) => _searchText(keyword)} activeName={activeName} />
        <DefaultTable t={t} headers={columnList}>
          {
            dataList.map((m, mIndex) => (
                <tr key={mIndex}>
                  <td>{mIndex + 1}</td>
                  <td>{m.agentCode}</td>
                  <td className={'border-left border-right'}>{subSTR(m.username)}</td>
                  <td>{m.nickname}</td>
                  <td><ActiveBadge t={t} text={m.status} /></td>
                  <td>{m.lastLogin}</td>
                  <td>{m.createAt}</td>
                </tr>
            ))
          }
        </DefaultTable>
      </div>
  );
}

export default SubAffList;