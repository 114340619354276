import React from 'react';
import styled from 'styled-components';

const FooterContain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #828282;
  padding: 15px 0px;
  font-size: 13px;
  position: sticky;
  margin: auto auto 0 auto;
`;

function Footer() {
  return <FooterContain>
    Copyright 2021 | All Rights Reserved
  </FooterContain>
}

export default Footer;