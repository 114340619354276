import React from 'react';
import styled from 'styled-components';
import config from "config/default.json";

const LINE_BULLET = styled.div`
    position: fixed;
    right: 16px;
    bottom: 0;
    z-index: 1;
`;

const LINE_ICON = styled.img`
    position: fixed;
    right: 16px;
    bottom: 0;
    width: 80px;
    height: auto;
`;

function LineAt () {
    return (
        <LINE_BULLET>
            <a href={config.CONTACT_LINE} target="_blank">
                <LINE_ICON src="/line.svg" alt="affiliate" />
            </a>
        </LINE_BULLET>
    );
}

export default LineAt;