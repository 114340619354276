import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { QRCode } from 'react-qrcode-logo';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getSession } from '../../../utils/login';
import Breadcrumb from '../../../components/Breadcrumb/BreadcrumbType';
import config from "config/default.json";

const Card = styled.div`
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    padding-bottom: 15px;
`;

const CardHeader = styled.div`
    background-color: #AF8C50;
    color: #FFF;
    display: flex;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;

const CardTitle = styled.div`
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const CardTitlePullRight = styled.div`
    margin-left: auto;
`;

const CardBody = styled.div`
    text-align: center;
    width: 200px;
    margin: 0 auto;
    @media (max-width: 768px) {
        width: 100%;
		canvas {
            width: 110px !important;
            height: 110px !important;
        }
	}
`;

const DownloadButton = styled.div`
    display: flex;
    align-items: center;
    font-size: 10px;
    padding: 1px 5px;
    background-color: #fff;
    border-radius: 5px;
    color: #af8c50;

    > div:first-child {
        margin-right: 5px;
    }
`;

const CustomInput = styled.input`
    font-size: 12px;
    background-color: #e9ecef;
    color: #495057;
    padding: 1.5px 5px;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    @media (max-width: 1024px) {
		width: 70%;
	}
`;

const CustomCopyButton = styled.div`
    background-color: #e9ecef !important;
    border: 0px solid transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    color: #495057;
    padding: 1.5px 5px;
    line-height: 1;
    width: 25px;
    height: 21px;
    margin-top: -1px;
    -webkit-appearance: unset;
    margin-left: 1px;
`;

const DivTitleLink = styled.div`
    text-align: center;
    margin-top: 16px;
    @media (max-width: 767px) {
        margin-top: 80px;
    }
`;


const TitleLink = styled.span`
    font-size: 16px;
    font-weight: 600;
    color: #AF8C50;
`;

export default ({t}) => {
    const [agentCode, setAgentCode] = useState('');
    const [copied, setCopied] = useState('');
    const [tabActive, setTabActive] = useState('HIALLBET')
    const DOMAIN = config.AFF_LINK_MEMBER
    const DOMAINSOCIAL = config.AFF_LINK_SOCIAL
    const DOMAINAFF = config.AFF_LINK_AGENT
    const DOMAINAGENTSOCIAL = config.AFF_LINK_AGENT_SOCIAL
    const LOGOBRAND = config.LOGO_QRCODE
    const DOMAIN_MOVIE = config?.DOMAIN_MOVIE
    const DOMAIN_MOVIE_ENCRYPT = config?.DOMAIN_MOVIE_ENCRYPT

    useEffect(function () {
        const session = getSession();
        if (session) {
            setAgentCode(session.agentCode)
        }
    }, []);

    const download = (name, i) => {
        const link = document.createElement('a');
        link.download = name + '.png';
        link.href = document.getElementsByTagName('canvas')[i].toDataURL();
        link.click();
    };

    const activeBtn = (e) => {
        setTabActive(e)
    }

    return (<div className={'overflowpage'}>
        <Breadcrumb title={'Links'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
        <DivTitleLink>
            <TitleLink>{t('links-for-member')}</TitleLink>
        </DivTitleLink>
        <div className={'row no-gutters mt-2'}>
            { DOMAIN !== "" ? <div className={'col-6 col-md-4 col-lg-3 p-2'}>
                <Card>
                    <CardHeader>
                        <CardTitle>Link show</CardTitle>
                        <CardTitlePullRight>
                            <DownloadButton
                                type={'button'}
                                className={'btn'}
                                onClick={() => download('linkShow', 0)}>
                                <div><img src={'/icon/download.svg'} alt={'download'} width={'10px'} /></div>
                                <div>{t('download')}</div>
                            </DownloadButton>
                        </CardTitlePullRight>
                    </CardHeader>
                    {config.OWNER !== "GHM" && config.OWNER !== "VIBET" && config.OWNER !== "ALLTOP" ? <CardBody>
                        <span id="aff">
                            <QRCode value={`${DOMAIN}?aff=${agentCode}`}
                                size="150"
                                padding="0"
                                logoWidth="30"
                                logoHeight="30"
                                logoImage={LOGOBRAND} />
                        </span>
                        <CustomInput
                            type="text"
                            name="linkaff"
                            value={`${DOMAIN}?aff=${agentCode}`}
                            bssize="sm"
                            readOnly
                        />
                        <CopyToClipboard
                            text={`${DOMAIN}?aff=${agentCode}`}
                            onCopy={() => setCopied(true)}>
                            <CustomCopyButton type={'button'} className={'btn btn-sm'}>
                                <img src={'/icon/copy.svg'} alt={'copy'} width={'12px'} />
                            </CustomCopyButton>
                        </CopyToClipboard>
                    </CardBody>:<CardBody>
                        <span id="aff">
                            <QRCode value={`${DOMAIN}/aff=${agentCode}`}
                                size="150"
                                padding="0"
                                logoWidth="30"
                                logoHeight="30"
                                logoImage={LOGOBRAND} />
                        </span>
                        <CustomInput
                            type="text"
                            name="linkaff"
                            value={`${DOMAIN}/aff=${agentCode}`}
                            bssize="sm"
                            readOnly
                        />
                        <CopyToClipboard
                            text={`${DOMAIN}/aff=${agentCode}`}
                            onCopy={() => setCopied(true)}>
                            <CustomCopyButton type={'button'} className={'btn btn-sm'}>
                                <img src={'/icon/copy.svg'} alt={'copy'} width={'12px'} />
                            </CustomCopyButton>
                        </CopyToClipboard>
                    </CardBody>}
                </Card>
            </div> : null }
            { DOMAINSOCIAL !== "" ? <div className={'col-6 col-md-4 col-lg-3 p-2'}>
                <Card>
                    <CardHeader>
                        <CardTitle>Link facebook show</CardTitle>
                        <CardTitlePullRight>
                            <DownloadButton
                                type={'button'}
                                className={'btn'}
                                onClick={() => download('linkSocialShow', 0)}>
                                <div><img src={'/icon/download.svg'} alt={'download'} width={'10px'} /></div>
                                <div>{t('download')}</div>
                            </DownloadButton>
                        </CardTitlePullRight>
                    </CardHeader>
                    <CardBody>
                        <span id="aff">
                            <QRCode value={`${DOMAINSOCIAL}/?aff=${agentCode}`}
                                size="150"
                                padding="0"
                                logoWidth="30"
                                logoHeight="30"
                                logoImage={LOGOBRAND} />
                        </span>
                        <CustomInput
                            type="text"
                            name="linkaff"
                            value={`${DOMAINSOCIAL}/?aff=${agentCode}`}
                            bssize="sm"
                            readOnly
                        />
                        <CopyToClipboard
                            text={`${DOMAINSOCIAL}/?aff=${agentCode}`}
                            onCopy={() => setCopied(true)}>
                            <CustomCopyButton type={'button'} className={'btn btn-sm'}>
                                <img src={'/icon/copy.svg'} alt={'copy'} width={'12px'} />
                            </CustomCopyButton>
                        </CopyToClipboard>
                    </CardBody>
                </Card>
            </div>: null }
        </div>
        <div className="mt-3 text-center">
            <TitleLink>{t('links-for-aff')}</TitleLink>
        </div>
        <div className={'row no-gutters mt-2'}>
            { DOMAINAFF !== "" ? <div className={'col-6 col-md-4 col-lg-3 p-2'}>
                <Card>
                    <CardHeader>
                        <CardTitle>Link Agent show</CardTitle>
                        <CardTitlePullRight>
                            <DownloadButton
                                type={'button'}
                                className={'btn'}
                                onClick={() => download('linkAgentShow', 4)}>
                                <div><img src={'/icon/download.svg'} alt={'download'} width={'10px'} /></div>
                                <div>{t('download')}</div>
                            </DownloadButton>
                        </CardTitlePullRight>
                    </CardHeader>
                    <CardBody>
                        <QRCode value={`${DOMAINAFF}?aff=${agentCode}`}
                            size="150"
                            padding="0"
                            logoWidth="30"
                            logoHeight="30"
                            logoImage={LOGOBRAND} />
                        <CustomInput
                            type="text"
                            name="linkAgentaff"
                            value={`${DOMAINAFF}?aff=${agentCode}`}
                            bssize="sm"
                            readOnly
                        />
                        <CopyToClipboard text={`${DOMAINAFF}?aff=${agentCode}`} onCopy={() => setCopied(true)}>
                            <CustomCopyButton type={'button'} className={'btn btn-sm'}>
                                <img src={'/icon/copy.svg'} alt={'copy'} width={'12px'} />
                            </CustomCopyButton>
                        </CopyToClipboard>
                    </CardBody>
                </Card>
            </div>: null}
            { DOMAINAGENTSOCIAL !== "" ? <div className={'col-6 col-md-4 col-lg-3 p-2'}>
                <Card>
                    <CardHeader>
                        <CardTitle>Link Facebook show</CardTitle>
                        <CardTitlePullRight>
                            <DownloadButton
                                type={'button'}
                                className={'btn'}
                                onClick={() => download('linkAgentSocialShow', 0)}>
                                <div><img src={'/icon/download.svg'} alt={'download'} width={'10px'} /></div>
                                <div>{t('download')}</div>
                            </DownloadButton>
                        </CardTitlePullRight>
                    </CardHeader>
                    <CardBody>
                        <span id="aff">
                            <QRCode value={`${DOMAINAGENTSOCIAL}/?aff=${agentCode}`}
                                size="150"
                                padding="0"
                                logoWidth="30"
                                logoHeight="30"
                                logoImage={LOGOBRAND} />
                        </span>
                        <CustomInput
                            type="text"
                            name="linkAgentSocialShow"
                            value={`${DOMAINAGENTSOCIAL}/?aff=${agentCode}`}
                            bssize="sm"
                            readOnly
                        />
                        <CopyToClipboard
                            text={`${DOMAINAGENTSOCIAL}/?aff=${agentCode}`}
                            onCopy={() => setCopied(true)}>
                            <CustomCopyButton type={'button'} className={'btn btn-sm'}>
                                <img src={'/icon/copy.svg'} alt={'copy'} width={'12px'} />
                            </CustomCopyButton>
                        </CopyToClipboard>
                    </CardBody>
                </Card>
            </div>: null }
            <div className={'col-6 col-md-4 col-lg-3 p-2'}>
                {/* <Card>
                    <CardHeader>
                        <CardTitle>Link Agent hidden</CardTitle>
                        <CardTitlePullRight>
                            <DownloadButton
                                type={'button'}
                                className={'btn'}
                                onClick={() => download('linkAgentHidden', 5)}>
                                <div><img src={'/icon/download.svg'} alt={'download'} width={'10px'} /></div>
                                <div>Download</div>
                            </DownloadButton>
                        </CardTitlePullRight>
                    </CardHeader>
                    <CardBody>
                        <QRCode value={`${DOMAINAFF}?affr=${agentCode}`}
                            size="150"
                            padding="0"
                            logoWidth="30"
                            logoHeight="30"
                            logoImage={LOGOBRAND} />
                        <CustomInput
                            type="text"
                            name="linkAgentaffr"
                            value={`${DOMAINAFF}?affr=${agentCode}`}
                            bssize="sm"
                            readOnly
                        />
                        <CopyToClipboard text={`${DOMAINAFF}?affr=${agentCode}`} onCopy={() => setCopied(true)}>
                            <CustomCopyButton type={'button'} className={'btn btn-sm'}>
                                <img src={'/icon/copy.svg'} alt={'copy'} width={'12px'} />
                            </CustomCopyButton>
                        </CopyToClipboard>
                    </CardBody>
                </Card> */}
            </div>
        </div>
        {DOMAIN_MOVIE && <><div className="mt-3 text-center">
            <TitleLink>{t('Link Live stream')}</TitleLink>
        </div>
        <div className={'row no-gutters mt-2'}>
            <div className={'col-6 col-md-4 col-lg-3 p-2'}>
                <Card>
                    <CardHeader>
                        <CardTitle>Link Direct</CardTitle>
                        <CardTitlePullRight>
                            <DownloadButton
                                type={'button'}
                                className={'btn'}
                                onClick={() => download('link_movie', 4)}>
                                <div><img src={'/icon/download.svg'} alt={'download'} width={'10px'} /></div>
                                <div>{t('download')}</div>
                            </DownloadButton>
                        </CardTitlePullRight>
                    </CardHeader>
                    <CardBody>
                        <QRCode value={`${DOMAIN_MOVIE}/?affCode=${agentCode}`}
                            size="150"
                            padding="0"
                            logoWidth="30"
                            logoHeight="30"
                            logoImage={LOGOBRAND} />
                        <CustomInput
                            type="text"
                            name="linkAgentaff"
                            value={`${DOMAIN_MOVIE}/?affCode=${agentCode}`}
                            bssize="sm"
                            readOnly
                        />
                        <CopyToClipboard text={`${DOMAIN_MOVIE}/?affCode=${agentCode}`} onCopy={() => setCopied(true)}>
                            <CustomCopyButton type={'button'} className={'btn btn-sm'}>
                                <img src={'/icon/copy.svg'} alt={'copy'} width={'12px'} />
                            </CustomCopyButton>
                        </CopyToClipboard>
                    </CardBody>
                </Card>
            </div>
            <div className={'col-6 col-md-4 col-lg-3 p-2'}>
                <Card>
                    <CardHeader>
                        <CardTitle>Link Facebook</CardTitle>
                        <CardTitlePullRight>
                            <DownloadButton
                                type={'button'}
                                className={'btn'}
                                onClick={() => download('link_movie', 4)}>
                                <div><img src={'/icon/download.svg'} alt={'download'} width={'10px'} /></div>
                                <div>{t('download')}</div>
                            </DownloadButton>
                        </CardTitlePullRight>
                    </CardHeader>
                    <CardBody>
                        <QRCode value={`${DOMAIN_MOVIE_ENCRYPT}/?affCode=${agentCode}`}
                            size="150"
                            padding="0"
                            logoWidth="30"
                            logoHeight="30"
                            logoImage={LOGOBRAND} />
                        <CustomInput
                            type="text"
                            name="linkAgentaff"
                            value={`${DOMAIN_MOVIE_ENCRYPT}/?affCode=${agentCode}`}
                            bssize="sm"
                            readOnly
                        />
                        <CopyToClipboard text={`${DOMAIN_MOVIE_ENCRYPT}/?affCode=${agentCode}`} onCopy={() => setCopied(true)}>
                            <CustomCopyButton type={'button'} className={'btn btn-sm'}>
                                <img src={'/icon/copy.svg'} alt={'copy'} width={'12px'} />
                            </CustomCopyButton>
                        </CopyToClipboard>
                    </CardBody>
                </Card>
            </div>
        </div></>}
    </div>)
}