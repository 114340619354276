import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';

import subSTR from '../../../utils/hideText';
import Breadcrumb from '../../../components/Breadcrumb/BreadcrumbType';
import DefaultTable from '../../../components/Table/DefaultTable/DefaultTable';
import FilterMember from '../../../components/FilterBuilder/FilterMember/FilterMember';
import MemberBadge from '../../../components/Badge/MemberBadge/MemberBadge';
import ActiveBadge from '../../../components/Badge/ActiveBadge/ActiveBadge';
import { getSession } from '../../../utils/login';
import config from "config/default.json"

function MemberList ({t}) {

    const [ memberList, setMemberList ] = useState([]);
    const [ stats, setStats ] = useState([]);
    const [ agentCode, setAgentCode ] = useState([]);
    const [ sortType, setSortType] = useState(1);
    const [ sortBy, setSortBy ] = useState('');
    const [ search, setSearch ] = useState('');
    const [ filterLevel, setFilterLevel ] = useState([]);
    const [ filterStatus, setFilterStatus ] = useState([]);
    const [ activeName , setActiveName] = useState('')
    const [tabActive, setTabActive] = useState('HIALLBET')

    // const [ page, setPage ] = useState(0);

    useEffect(function() {
        const session = getSession();
        if (session) {
            _fetchMemberList(session.agentCode);
            setAgentCode(session.agentCode)
        }
    }, [sortType,search,filterLevel,filterStatus]);

    async function _fetchMemberList (agentCode) {
        const bodySet = {
            agentCode: agentCode,
            skip: "0",
            sortBy: sortBy,
            sortType: sortType,
            search: search,
            filterLevel: filterLevel,
            filterStatus: filterStatus
        };
        const response =  await fetch(config.URL_API + '/getMemberListDetail', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            let statsGroup = [];
            if (data.response_result) {
                const result = data.response_data.memberList;
                const gen = data.response_data.gen;
                const status = data.response_data.status;
                let total = 0
                for (let key in gen) {
                    statsGroup.push({ "level": key, "value" : gen[key] });
                    total += gen[key]
                }
                statsGroup.push({ "level": "Active", "value": status.active });
                statsGroup.push({ "level": "UnActive", "value": status.unactive ? status.unactive : 0 });
                statsGroup.push({ "level": "All", "value": total });
                setStats(statsGroup);
                setMemberList(result);
            }
        }
    }

    function _searchText(keyword) {
        if (keyword.length > 2) {
            setSearch(keyword)
        }else if(keyword.length === 0){
            setSearch("")
        }
    }

    const sortby = (type) => {
        const sort = sortType === 1 ? -1 : 1
        setSortType(sort)
        setSortBy(type)
    }

    const actionLevel = (e)=>{
        setActiveName(e)
        if(e === 'Active' || e === 'UnActive'){
            setFilterStatus([e.toLowerCase()])
            setFilterLevel([])
        }else if(e === 'All'){
            setFilterLevel([])
            setFilterStatus([])
        }else{
            setFilterLevel([e])
            setFilterStatus([])
        }
    }

    const activeBtn = (e) => {
        setTabActive(e)
        // console.log(e)
        // if (e === "HIALLBET") {
        //   _fetchDataList(agentCode)
        // } else if (e === "HISLOT") {
        //   _fetchDataListSlot(agentCode)
        // }
    }
    
    const columnList = [
        { title: "no", width: "50px" },
        { title: "level", width: "100px" },
        { title: "user", width: "200px" },
        { title: "status", width: "100px" },
        { title: "last-login", width: "200px", click: () => sortby('lastLogin') },
        { title: "createat", width: "200px", click: () => sortby('createAt') }
    ];

    return (
        <div>
            <Breadcrumb title={'Member List'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
            <FilterMember data={stats} actionLevel={(data) => actionLevel(data)} isShowDate={false} isShowLevel={true} search={(keyword) => _searchText(keyword)} activeName={activeName} t={t} />
                <DefaultTable t={t} headers={columnList}>
                    {
                        memberList.map((m, mIndex) => (
                            <tr key={mIndex}>
                                <td>{mIndex + 1}</td>
                                <td><MemberBadge text={m.level} color={m.levelColor} /></td>
                                <td className={'border-left border-right'}>{subSTR(m.username)}</td>
                                <td><ActiveBadge text={m.status} t={t}/></td>
                                <td>{m.lastLogin}</td>
                                <td>{m.createAt}</td>
                            </tr>
                        ))
                    }
                </DefaultTable>
                {/* <Paging  /> */}
        </div>
    );
}

export default MemberList;