import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import OutlineButton from '../Button/OutlineButton/OutlineButton';
import { useTranslation } from 'react-i18next';
import subSTR from '../../utils/hideText';

const LeftBar = styled.div`
    font-weight: 600;
    margin-left: 7px;
`;

const RightBar = styled.div`
    display: flex;
    margin-left: auto;
    @media (max-width: 767px) {
        // display: none;
        // position: absolute;
        // width: 100%;
        // display: -webkit-box;
        // top: 15%;
        // margin-top: 7px;
        // left: 10px;
    }
`;

const RightBarGroup = styled.div`
    display: flex;
    @media (max-width: 767px) {
        position: absolute;
        display: -webkit-box;
        width: 100%;
        top: 35%;
        margin-top: 35px;
        left: 0px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        overflow: auto;
    }   
`

const BreadcrumbContain = styled.div`
    display: flex;
    align-items: center;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    padding: 7px 15px;
    margin: 5px 0px;
    position: relative;
    width: 100%;
    > div > span {
        font-weight: 500;
        padding-left: 7px;
        font-size: 18px;
    }
`;

const RowIcon = styled.div`
    display: flex;
    align-items: center;
`;

const images = {
    Links: 'qrcode',
    Profile: 'member',
    Gallery: 'images',
    'Earning Summary': 'earning_summary',
    'Proportional Expenses': 'proportional_expenses',
    'Member List': 'list',
    'Sub Affiliate List' : 'list',
    'Member Report': 'report',
    'Member Deposit': 'deposits',
    'Product Report': 'product_ report',
    'Sub Affiliate Report': 'report',
    'Detailed Activity Report': 'search_dollar',
    'Monthly Earning Report' : 'money_check',
    'Earnings Breakdown Report': 'money_check',
    'Proportional Expenses Detail' : 'money_check',
    'Sub-Affiliate Report': 'money_check',
}

const TitleMenu = styled.span`
    font-size: 14px !important;
    // cursor: pointer;
`

const TitleUser = styled.span`
    font-size: 14px !important;
`

function Breadcrumb({ title, menu, onClick, tabActive}) {
    const [t] = useTranslation();
    const menuList = menu ? menu[0].length >= 10 ? ` / ${subSTR(menu[0])} ` : ` / ${menu[0]} ` : ''
    const username = menu ? menu[1].length >= 10 ? ` ${subSTRS(menu[1])} ` : ` ${(menu[1])}` : ''
    const username2 = menu ? menu.length > 2 ? menu[2].length >= 10 ? ` ${subSTRS(menu[2])} ` : ` ${(menu[2])}` : '' : ''

    function subSTRS(str) {
        return str.substring(1, 6) + 'xxx' + str.substring(9, 13);
    }

    function goMenuList () {
    }
    return (
        <BreadcrumbContain>
            <img src={`/icon/${images[title]}.svg`} alt={'icon'} width={'25px'} height={'20px'} />
            <LeftBar>{t(title)} <TitleMenu onClick={() => goMenuList()}>{menuList}</TitleMenu> <TitleUser>{username} {username2}</TitleUser></LeftBar>
            {onClick ? null : <RightBar>
                <RightBarGroup>
                {title === 'Earning Summary' ?
                    <div className={'mr-2'}>
                        <OutlineButton text={'ALL'} className={tabActive === 'ALL' ? "btn active" : "btn"} onClick={onClick} />
                    </div> : ''}
                {title === 'Links' ?
                    <div className={'mr-2'}>
                        <OutlineButton text={'Sup - Affiliate'} className={tabActive === 'Sup - Affiliate' ? "btn active" : "btn"} onClick={onClick} />
                    </div> : ''}
                {title === 'Monthly Earning Report' ? 
                <div className={'mr-2'}>
                    <OutlineButton text={'SUMMARY'} className={tabActive === 'SUMMARY' ? "btn active" : "btn"} onClick={onClick} />
                </div> : ''}
                <div className={'mr-2'}>
                    <OutlineButton text={'HIALLBET'} className={tabActive === 'HIALLBET' ? "btn active" : "btn"} onClick={onClick} />
                </div>
                <div className={'mr-2'}>
                    <OutlineButton text={'ALL GAME'} className={tabActive === 'ALL GAME' ? "btn active" : "btn"} onClick={onClick} />
                </div>
                {title !== 'Gallery' && title !== 'Earning Summary' ? 
                <div>
                    <OutlineButton text={'COMING SOON'} className={tabActive === 'COMING SOON' ? "btn active" : "btn"} onClick={onClick} />
                </div> : '' }
                </RightBarGroup>
            </RightBar> }
        </BreadcrumbContain>
    );
}

Breadcrumb.propTypes = {
    title: PropTypes.string.isRequired
};

export default Breadcrumb;