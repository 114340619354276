import React, { useState, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu  } from 'reactstrap';
import PropsType from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_orange.css';
import _ from 'lodash';
import classnames from 'classnames';
import SearchBox from '../../SearchBox/SearchBox';

const FilterContain = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px 15px 0;
    // overflow: auto;
    @media (max-width: 767px) {
        margin-top: 60px;
    }
`;

const FilterBox = styled.div`
    margin: 0 10px 0 0;
    cursor: pointer;
    color: #AF8C50;
    font-size: 13px;
    &.active {
        border-bottom: 1px solid;
    }
`;

// const SelectBox = styled.select`
//     display: block;
//     margin: 0 10px 0 0;
//     width: 105px;
//     padding: .375rem .75rem;
//     font-size: 13px;
//     line-height: 1.5;
//     color: #495057;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #ced4da;
//     border-radius: 1rem;
//     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
// `;

const SelectMonth = styled.select`
    // -webkit-appearance: none;
    -webkit-border-radius: 20px;
    height: 34px;
    display: block;
    margin: 0 10px 0 0;
    width: 200px;
    padding: .375rem .75rem;
    font-size: 13px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 1rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-image: url("/icon/calendar.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 40px 15px;
    text-align: center;
    text-align-last: center;
    &:focus, &:hover, &:active {
		outline: 0px auto -webkit-focus-ring-color;
	}
`;

const SelectContain = styled.div`
    margin: 0 10px 0 0;
    cursor: pointer;

    > span {
        margin-left: 5px;
        font-size: 12px;
    }
    .dropdown-menu{
        min-width: 130px;
        padding: .5rem 9px;
        background-color: transparent;
        border: unset;
    }

    @media (max-width: 767px) {
        padding-left: 10px;
    }
`;

const SelectDiv = styled.div`
    border-radius: 25px;
    padding: 0.35rem 0.75rem;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    min-width: 130px;
`;

const SelectMenu = styled(DropdownMenu) `
    padding: 0 !important;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
`;

const SelectSub = styled.div`
    padding: 0.35rem 0.75rem;
    border-radius: 25px;
    margin-bottom: 2px;
`;


function FilterMember ({ 
    data, 
    isShowDate, 
    isShowLevel, 
    isShowReward, 
    isShowDateRang, 
    cbDate, 
    search, 
    actionReward, 
    cbDateRang, 
    isShowMonth, 
    actionMonth,
    isShowYear, 
    actionYear,
    actionLevel,
    activeName,
    dropdownOpen,
    statusType,
    toggle,
    t}) {
    
    const SelectIcon = {
        padding: "5px",
        background: "#fff",
        borderRadius: "30px",
        color: `${statusType === 'Pending' ? '#ffc107' : statusType === 'Completed' ? '#28a745' : '#dc3545'}`,
        width: "23px",
        textAlign: "center"
    }
       
    const [ date, setDate ] = useState(new Date());
    const defaultDate = {
        month: parseInt(moment().subtract(1,'months').format('MM')),
        dd: parseInt(moment().add(1,'days').format('DD'))
    }
    const [ dateRang, setDateRang ] = useState([new Date(2019, defaultDate.month, 1),new Date(2019, defaultDate.month, defaultDate.dd)]);

    function _setDate (date) {
        setDate(date);
        cbDate(date);
    }

    const _setDateRang = async(date) => {
        if(moment(date[0]).format('MM') ===  moment(date[1]).format('MM') && new Date(date[0]).getTime() < new Date(date[1]).getTime()){
            setDateRang(date)
            cbDateRang(date)
        }
    }
    const yearStart = parseInt(moment().format('YYYY')) - 2018 
    const check = activeName || ''

    const SelectData = ['Pending', 'Completed' , 'Reject' ] 
    const selects = SelectData.map((m, index) => {
        const icon = m === 'Pending' ? 'fas fa-redo' : m === 'Completed' ? 'fas fa-check' : 'fas fa-times'
        const SelectIconSub = {
            padding: "5px",
            background: "#fff",
            borderRadius: "30px",
            color: `${m === 'Pending' ? '#ffc107' : m === 'Completed' ? '#28a745' : '#dc3545'}`,
            width: "23px",
            textAlign: "center"
        }
        return(
            <React.Fragment key={index}>
                {m !== statusType ? 
                    <SelectSub onClick={(l) => toggle(m)} className={m === 'Pending' ? 'bg-warning' : m === 'Completed' ? 'bg-success' : 'bg-danger' }>
                        <i className={icon +" mr-2"} style={SelectIconSub}></i>{m}
                    </SelectSub> : ''
                }
            </React.Fragment>
            )
    })
    const icon = statusType === 'Pending' ? 'fas fa-redo' : statusType === 'Completed' ? 'fas fa-check' : 'fas fa-times'
    return (
        <FilterContain>
            {!isShowMonth ? null : <SelectMonth key={'month'} onChange={actionMonth} >
                {_.range(0, 12).map(value => <option key={value} value={moment().subtract(value, 'month').format('MMYYYY')}>{moment().subtract(value, 'month').format('MMMM YYYY')}</option>)}
            </SelectMonth>}
            {!isShowYear ? null : <SelectMonth key={'year'} onChange={actionYear} >
                {_.range(0, yearStart).map(value => <option key={value} value={moment().subtract(value, 'year').format('YYYY')}>{moment().subtract(value, 'year').format('YYYY')}</option>)}
            </SelectMonth>}
            { !isShowDate ? null : <Flatpickr
                options={{
                    dateFormat: 'd-m-Y',
                    altInputClass: 'custom__input__date',
                    altInput: true
                }}
                value={date}
                onChange={date => _setDate(date)} /> }
            {<SearchBox placeholder={t('search-list')} changed={search} />}
            { !isShowLevel ? null : data.map((u, uIndex) => (
                <FilterBox key={uIndex} className={classnames({ active: check === u.level })} onClick={() => actionLevel(u.level)}>
                    {u.level === 'Active' ? t('active') : u.level === 'UnActive' ? t('inactive') : u.level === 'All' ? t('all') : u.level} ({u.value})
                </FilterBox>
            )) }
            {/* {!isShowReward ? null : <SelectBox key={'reward'} onChange={actionReward}>
                    <option value="pending">Pending</option>
                    <option value="completed">Complete</option>
                    <option value="reject">Reject</option>
                </SelectBox> } */}
            {!isShowReward ? null : <SelectContain>
                <Dropdown isOpen={dropdownOpen} toggle={(l) => toggle(statusType)}>
                    <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        aria-expanded={dropdownOpen}
                        className="d-flex align-items-center"
                    >
                        <SelectDiv className={statusType === 'Pending' ? 'bg-warning' : statusType === 'Completed' ? 'bg-success' : 'bg-danger'}>
                            <i className={icon + " mr-2"} style={SelectIcon}></i>
                            {statusType}
                        </SelectDiv>
                    </DropdownToggle>
                    <SelectMenu>
                        {selects}
                    </SelectMenu>
                </Dropdown>
            </SelectContain>}
            { !isShowDateRang ? null : <Fragment>
                <Flatpickr
                        options={{
                            mode: "range",
                            dateFormat: 'd-m-Y',
                            altInput: true,
                            altInputClass: 'custom__input__date_rang',
                            defaultDate: dateRang
                        }}
                    // value={defaultDate}
                    onChange={date => _setDateRang(date)} />
            </Fragment> }
        </FilterContain>
    );
}

FilterMember.propTypes = {
    data: PropsType.array,
    isShowDate: PropsType.bool,
};

export default FilterMember;