import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomBagde = styled.div`
    color: ${ props => props.color };
    border: 1px solid #828282;
    border-radius: 5px;
    padding: .25rem;
`;

function ActiveBadge ({ text, t }) {

    function _setColor (text) {
        switch (text) {
            case 'active': return "#26B556";
            case 'suspended': return "#FFBC00";
            case 'unactive': return "#FFBC00";
            case 'lock': return "#D13C3C";
        }
    }

    return (
        <CustomBagde color={_setColor(text)}>{t(text === 'active' ? 'active' : 'inactive')}</CustomBagde>
    );
}

ActiveBadge.propTypes = {
    text: PropTypes.string
};

export default ActiveBadge;