import React from 'react';
import styled from 'styled-components';

const ProfileContain = styled.div`
    display: flex;
    align-items: center;
`;

const ProfileIcon = styled.div`
    > img {
        max-width: 100%;
    }
`;

const ProfileBody = styled.div`
    color: #313131;
    margin-left: 10px;
`;

const ProfileTitle = styled.div`
    font-size: 14px;
    color: #AF8C50;
    font-weight: 600;
`;


const ProfileSubTitle = styled.div`
    font-size: 12px;
    line-height: 1;
    text-transform: capitalize;
`;

function ProfileBox ({ user }) {
    return (
        <ProfileContain>
            <ProfileIcon>
                <img src={'/icon/user.svg'} alt={'hiaff'} />
            </ProfileIcon>
            <ProfileBody>
                <ProfileTitle>{ user.username }</ProfileTitle>
                <ProfileSubTitle>{ user.level }</ProfileSubTitle>
            </ProfileBody>
        </ProfileContain>
    );
}

export default ProfileBox;