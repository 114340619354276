import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

const LanguageContain = styled.div`
    border-left: 1px solid #e2e2e2;
    height: 75px;
    line-height: 75px;
    margin: 0 10px;
    padding-left: 15px;
    cursor: pointer;
    > span {
        margin-left: 5px;
        font-size: 12px;
    }
    .dropdown-menu{
        min-width: 8rem;
        padding: .5rem 9px;
    }
    @media (max-width: 1023px) {
        padding-left: 0px;
        border-left: unset;
        height: auto;
        line-height: unset;
        color: #ffffff;
        display: flex;
        justify-content: center;
    }
`;

const LangSpan = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
`

const LangSelect = styled.div`
    height: 25px;
    line-height: 20px;`

const LangData = ['English', 'Thai', 'China', 'Myanmar']

// , 'China'
function LanguageBox({ i18n }) {
    let lng = localStorage.getItem("lng");
    const [lang, setLang] = useState(lng || 'English');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(()=>{
        const query_string = window.location.search.substr(1);
        const searchParams = new URLSearchParams(window.location.search)
        if (query_string  && searchParams.get('lang')) {
            let lang = caseLang(searchParams.get('lang'))
            changeLang(lang)
            setDropdownOpen(prevState => !prevState)
        }
        
    },[])

    const toggle = (l) => {
        setDropdownOpen(prevState => !prevState);
    }

    function caseLang(l){
        if(l === 'en'){
            return "English" 
        }else if(l === 'th'){
            return "Thai" 
        }else if (l === 'mm'){
            return "Myanmar" 
        }else if (l === 'cn'){
            return "China" 
        }
    }

    const changeLang = (lang) => {
        // console.log(lang)
        i18n.changeLanguage(lang);
        setDropdownOpen(prevState => !prevState);
        localStorage.setItem('lng', lang);
        setLang(lang)
    }

    const selects = LangData.map((m, index) => (
        <React.Fragment key={index}>
            {m !== lang ? <LangSelect onClick={(l) => changeLang(m)} className="mb-2">
                <img src={`/flags/${m}.png`} alt={'flag'} />
                <LangSpan>{m}</LangSpan>
            </LangSelect> : ''}
        </React.Fragment>
    ))
    return (
        <LanguageContain>
            <Dropdown isOpen={dropdownOpen} toggle={(l) => toggle(lang)}>
                <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                    className="d-flex align-items-center"
                >
                    <img src={`/flags/${lang}.png`} alt={'flag'} />
                    <LangSpan>{lang}</LangSpan>
                </DropdownToggle>
                <DropdownMenu>
                    {selects}
                </DropdownMenu>
            </Dropdown>
        </LanguageContain >
    )
}

export default LanguageBox;