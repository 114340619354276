import fetch from 'isomorphic-unfetch';
import config from "config/default.json";

const TOKEN_KEY = 'token';
export const login = (username, password) => {
    return requestLogin(username, password).then(
        response => {
            if (!response.status) {
                return { status: false }
            }
            if (!response.result) {
                return { status: false }
            }
            localStorage.setItem(TOKEN_KEY, response.token);
            localStorage.setItem("user", JSON.stringify(response.result));
            return { status: true }
        }
    ).catch(error => {
        return { status: false }
    });
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("user");
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}

const checkSessionExpire = async () => {

 const response = await fetch(config.URL_AGENT_API + '/profile', {
        method: 'POST',
        headers: { 'x-access-token': localStorage.getItem(TOKEN_KEY) },
        body: {}
    });
    if (response.ok) {
        const data = await response.json();
        if (!data.status) {
            console.log(data)
            localStorage.clear();
            alert('Session ของคุณหมดอายุแล้ว กรุณาลงชื่อเข้าใช้งานใหม่ !');
            window.location.reload();
            return;
        }
    } 
}

export const getSession = () => {
    if (!localStorage.getItem("user")) {
        return;
    }
    checkSessionExpire()
    return JSON.parse(localStorage.getItem("user"));
};

async function requestLogin (username, password) {
    const response = await fetch(config.URL_AGENT_API + '/agent/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: username, password: password })
    });
    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        return { status: false, message: 'Server Error.' };
    }
}
