export const detailQuestion = [
  {
    id: "q1",
    nameTopic: "faq-head-1",
    listTopic: [
      "faq-topic-1-1",
      "faq-topic-1-2",
      "faq-topic-1-3",
      "faq-topic-1-4",
    ],
    contentDetail: [
      {
        Thai: `<div>คือ ระบบสร้างรายได้ที่คุณจะได้รับ % จากยอดเสียของสมาชิกภายใต้คุณ ซึ่งระบบเอเย่นต์หุ้นลมไม่มีค่าใช้จ่ายในการสมัคร และไม่ต้องเสียยอดที่สมาชิกมียอดชนะใด ๆ ทั้งสิ้น ยอดชนะของสมาชิกจะถูกยกยอดไปยังเดือนถัดไปเพื่อหักกับยอดเสียของสมาชิกอีกครั้งหนึ่ง คุณเพียงแค่ทำการหาสมาชิก ทางเราจะเป็นฝ่ายดูแลสมาชิกของคุณทั้งหมด ไม่ว่าจะเป็นการบริการลูกค้าและบัญชีที่ให้สมาชิกทำรายการฝาก-ถอน จะอยู่ภายใต้การดูแลของ Hiallbet ทั้งหมด คุณแค่ต้องทำการตลาดเพื่อหาสมาชิกเพียงเท่านั้น และรอรับผลประกอบการตามขั้นบันไดที่เรากำหนดไว้ได้เลย</div>
        <div>เมื่อคุณได้รับการอนุมัติแล้ว คุณจะได้รับลิงค์ และ คิวอาร์โค้ดของคุณเอง รวมไปถึงสื่อที่ใช้ในการโฆษณา และโปรโมชั่นต่าง ๆ จากทางเราที่จะช่วยให้คุณทำการตลาดออนไลน์ทั้งบนเว็บไซต์ของคุณเอง หรือผ่านทางสื่อออนไลน์ช่องทางต่าง ๆ ได้อย่างง่ายดาย</div>
        <div>เมื่อมีสมาชิกที่เข้ามาผ่านไอดีของคุณ และได้สมัครสมาชิก, มียอดฝาก และยอดเล่นขั้นต่ำกับทางเรา จะนับว่าเป็นลูกค้าที่มียอดเคลื่อนไหวตามวิธีคิดผลประกอบการตามขั้นบันได ซึ่งคุณจะได้รับผลตอบแทนเริ่มต้นที่ 25% และสูงสุดที่ 40% ตามขั้นบันไดที่เรากำหนด โดยคุณจะได้รับผลตอบแทนจากยอดเสียสุทธิของสมาชิกภายใต้คุณ และพิเศษมากกว่านั้น เรายังมี 5% จากยอดฝากครั้งแรกของสมาชิกใหม่ภายใต้คุณ ให้คุณได้รับรายได้อีกช่องทางหนึ่งอีกด้วย</div>`,
        English: `<div>Is an income generating system that you will receive % from the wasted amount of members under you. HiAffiliate is free of charge and does not need to incur any winnings. The member's winnings will be carried forward to the next month to deduct against the lost members again. You just have to look for members We will take care of all your members. Whether it is customer service and accounts that allow members to deposit - withdraw Will be under the care of all Hiallbet. You just have to do marketing to find members only and wait for the results to follow our steps.</div> 
        <div> Once you have been approved You will receive a link and your own QR code.Including media used for advertising and promotions from us that will help you to market online either on your own website or through various online media channels easily.</div> 
        <div>When a member enters through your account and is registered, there is a minimum deposit and play amount with us. Will be counted as customers who have movement balance according to the step calculation method You will receive an initial return of 25% and a maximum of 40% according to the steps that we specify. You will receive compensation from the net loss of members under you. And more specifically, we also have 5% off the first deposit of new members under you, allowing you to receive another revenue channel.</div>`,
      },
      {
        Thai: "<div>เพียงคลิกปุ่ม สมัครเอเย่นต์หุ้นลม และสละเวลาเพียงไม่กี่นาทีในการกรอกข้อมูล จากนั้นอ่านและยอมรับข้อกำหนดของเรา หากคุณยอมรับเงื่อนไขของทางเราแล้ว เพียงแค่คลิกในช่องยอมรับ และกดยืนยันการสมัคร จากนั้นคุณสามารถแจ้งฝ่ายบริการของเราเพื่อทำการอนุมัติการสมัครของคุณ โดยสามารถแจ้งได้ที่ Line : @hiaff</div>",
        English: "<div>Just click the Apply HiAffiliate button and take just a few minutes to fill out the information. Then read and accept our terms.If you accept our terms Just click in the accept box and confirm register.You can then notify our service department to approve your application.Which can be notified at Line: @hiaff</div>",
      },
      {
        Thai: "<div>ใช่แล้ว การสมัครเอเย่นต์หุ้นลมกับทาง Hiallbet ไม่มีค่าใช้จ่ายใดๆทั้งสิ้น เพียงแค่มาตัวเปล่าก็ร่วมเป็นส่วนหนึ่งของครอบครัวเราได้เลย แต่ขอบอกว่าตอนกลับไม่ตัวเปล่าแน่นอน!</div>",
        English: "<div>Yes, signing up for HiAffiliate with Hiallbet is absolutely free. Just come free and be a part of our family. But let me say that when I returned it was definitely not empty !</div>",
      },
      {
        Thai: `<div>1. คุณจะได้รับผลตอบแทนสูงสุดถึง 40% จากยอดเสียของสมาชิกของคุณ ซึ่งไม่มีใครกล้าให้เท่าเรา ยังไม่พอคุณยังได้ 5% จากยอดฝากครั้งแรกของสมาชิกใหม่ภายใต้คุณอีกต่อหนึ่ง นี่คือสิ่งที่เราเรียกว่ารายได้จาก 2 ช่องทาง</div>
        <div>2. เราได้รวบรวมเว็บชั้นนำของโลกกว่า 20 ผลิตภัณฑ์ไว้เพื่อคุณ เพื่อให้คุณได้โปรโมททุกผลิตภัณฑ์ภายใต้แบรนด์เดียว ไม่ต้องเปลี่ยนสิ่งที่สมาชิกชอบ แต่จงเปลี่ยนในสิ่งที่ทำให้คุณได้ผลตอบแทนที่มากกว่า</div>
        <div>3. เรามีสื่อเพื่อใช้ในการโฆษณาพร้อมที่จะให้คุณทำการตลาดได้อย่างง่ายดาย โดยถูกออกแบบจากทีมการตลาดและทีมมีเดียที่เชี่ยวชาญพฤติกรรมของสมาชิกเป็นอย่างดี เพียงแค่คุณโพสต์ เงินก็เข้าแล้ว</div>
        <div>4. ไม่ต้องกังวลที่จะใช้หน้าทำงานยากๆอีกต่อไป เรามีระบบหน้าทำงานที่ใช้ง่าย พร้อมหน้าสถิติแสดงผลให้คุณอย่างระเอียดและเรียลทามเพื่อให้คุณวิเคราะห์การตลาดหรือรายได้ของคุณตลอดเวลา</div>
        <div>5. เรามีฝ่ายบริการที่พร้อมผลักดันให้คุณไปสู่เป้าหมายที่คุณต้องการ ทำงานอย่างมืออาชีพ เข้าใจคุณดั่งคนในครอบครัว</div>`,
        English: `<div>1. You will receive rewards up to 40% from the loss of your members. Which nobody dares to give as much as us Not enough. You still get 5% from the first deposit of new members under you. This is what we call revenue from 2 channels.</div>
        <div>2. We have compiled over 20 of the world's leading websites for you so you can promote every product under one brand. No need to change what members like But change what makes you more rewarding.</div>
        <div>3. We have media for advertising, ready for you to easily market, designed by the marketing team and media team that specializes in member behavior. Just you post Money is in.</div>
        <div>4. No need to worry about using hard work anymore, we have a simple work system. With a statistics page showing you in detail and in real-time so you can analyze your marketing or income at all times.</div>
        <div>5. We have a service department ready to push you to your desired goals. Work professionally, understand you like family members.</div>`,
      }
    ],
  },
  {
    id: "q2",
    nameTopic: "faq-head-2",
    listTopic: [
      "faq-topic-2-1",
      "faq-topic-2-2",
      "faq-topic-2-3",
      "faq-topic-2-4",
      "faq-topic-2-5",
    ],
    contentDetail: [
      {
        Thai: "<div>เรามีช่องทางการตลาดให้คุณเลือกใช้ทั้งหมด 2 ประเภท</div><ol><li>ลิงค์</li><li>คิวอาร์โค้ด</li></ol>",
        English: "<div>We have two types of marketing channels for you to choose.</div><ol><li>Link</li><li>QR Code</li></ol>",
      },
      {
        Thai: "<div>ลิงค์และคิวอาร์โค้ดของคุณ จะถูกล็อครหัสเอเย่นต์หุ้นลมของคุณไว้แล้ว เพียงแค่ส่งหรือนำลิงค์ไปโพสต์เพื่อให้สมาชิกเข้าสมัครผ่านลิงค์และคิวอาร์โค้ดของคุณเพียงเท่านี้สมาชิกที่สมัครก็จะอยู่ภายใต้คุณทันที</div>",
        English: "<div>Your links and QR codes will be locked. Your HiAffiliate code is already locked. Just send or post a link to allow members to apply via your link and QR code. That's it. Subscribers will immediately be under you.</div>",
      },
      {
        Thai: "<div>ได้ครับ คุณสามารถใช้คอนเทนต์และโปรโมชั่นที่ทางเราจัดทำขึ้นมาให้เท่านั้น คุณไม่สามารถปรับเปลี่ยนตัวอักษรหรือโปรโมชั่นที่เราจัดทำขึ้น หากคุณสงสัยหรือมีคำถามในข้อบังคับนี้สามารถติดต่อฝ่ายบริการของเราได้ที่ @hiaff</div>",
        English: "<div>Yes, you can only use content and promotions that we have created. You cannot change the character or promotion that we create. If you have any questions or queries regarding these regulations, you can contact our service department at @hiaff</div>",
      },
      {
        Thai: "<div>เรามีฝ่ายบริการลูกค้าที่มากประสบการณ์พร้อมสอนวิธีการทำการตลาดให้คุณ และพร้อมเติบโตไปพร้อมๆกับคุณให้คุณได้ร่วมเป็นส่วนหนึ่งของครอบครัว Hiallbet เพราะเราเข้าใจสมาชิกและเข้าใจคุณ</div>",
        English: "<div>We have an experienced customer service department ready to teach you how to market. And ready to grow with you, to be part of the Hiallbet family, because we understand members and understand you.</div>",
      },
      {
        Thai: "<div>การที่เรามีฝ่ายโปรดักชั่นที่ออกแบบโปรโมชั่นหรือการออกแบบตัวอักษรให้ทันสมัยและร่วมสมัยอยู่ตลอดนั้นไม่ใช่เรื่องง่าย แต่เราพร้อมที่จะบริการคุณอย่างเต็มที่และปล่อยให้เรื่องยากเป็นหน้าที่ของเราเพื่อให้คุณได้ทำงานง่ายขึ้นและสะดวกยิ่งขึ้น เพราะ Hiallbet ใส่ใจและเข้าใจ เพราะเราเป็นมากกว่าพันธมิตรแต่เราคือ ครอบครัว</div>",
        English: "<div>We have productions that design promotions or character designs that are up to date and contemporary. But we are ready to serve you fully and let difficult matters is our duty to make your work easier and more convenient because Hiallbet cares and understands. Because we are more than partners, but we are families.</div>",
      }
    ],
  },
  {
    id: "q3",
    nameTopic: "faq-head-3",
    listTopic: [
      "faq-topic-3-1",
      "faq-topic-3-2",
      "faq-topic-3-3",
      "faq-topic-3-4",
      "faq-topic-3-5",
      "faq-topic-3-6",
      "faq-topic-3-7",
      "faq-topic-3-8",
      "faq-topic-3-9",
      "faq-topic-3-10",
    ],
    contentDetail: [
      {
        Thai: "<div>อันดับแรกคุณต้องล็อคอินสู่หน้าทำงานเอเย่นต์หุ้นลม เมื่อล็อคอินแล้วให้คุณคลิกที่ รายงานรายได้ หลังจากนั้นหน้าแสดงผลตอบแทนจะแสดงขึ้นให้คุณทราบรายละเอียดทั้งหมด</div>",
        English: "<div>First, you must log in to the HiAffiliate page. Once you have logged in, click on the earnings report. After that, the rewards page will show you all the details.</div>",
      },
      {
        Thai: "<div>โปรแกรมแสดงผลตอบแทนของ Hiallbet นั้นจะอัพเดทให้คุณแบบเรียลไทม์(ยอดปัจจุบัน) เพื่อให้คุณสามารถเข้ามาตรวจสอบยอดของคุณได้ตลอดเวลา</div>",
        English: "<div>Hiallbet rewards program will update for you in real time (current balance) so you can check your balance at any time.</div>",
      },
      {
        Thai: `<div>1. ผลตอบแทนสุทธิประจำเดือน = ยอดได้/เสียของสมาชิกในแต่ละเดือน - [โบนัส, ค่าธรรมเนียม, ค่าคอมมิชชั่นการเดิมพัน, ยอดที่สมาชิกแลกสินค้าใน Hireward, ยอดยกมาจากเดือนก่อน(ถ้ามี)] และนำยอดมาหักกับ % ที่คุณได้รับตามระดับขั้นบันไดของคุณ</div>
        <div>เช่น : ยอดได้เสียของสมาชิก = 10, 000, โบนัส = 500, ค่าธรรมเนียม = 0, ค่าคอมมิชชั่นเดิมพัน = 0, ได้ % ตามขั้นบันได = 50 %</div>
        <div>วิธีคิด : 10,000 - (500 , 0 , 0) = 9,500 , นำยอดมาหักกับ % ที่คุณได้รับตามขั้นบันได = 9,500 - 50% = 4,750</div>
        <div>ผลตอบแทนสุทธิประจำเดือน = 4,750 บาท</div>`,
        English: `<div>1. Monthly net return = Member's gain / loss in each month - [Bonus, Fees, Betting Commissions, Total amount that members redeem products in Hireward, the balance carried over from the previous month (if any)] and Deduct the% you receive according to your steps.</div>
        <div>For example: Member's interest = 10, 000, Bonus = 500, Fees = 0, Bet commission = 0,% According to steps = 50%</div>
        <div>Method: 10,000 - (500, 0, 0) = 9,500, deducted from the balance The% that you get by steps = 9,500 - 50% = 4,750</div>
        <div>4,750 baht monthly net.</div>`,
      },
      {
        Thai: `<div>ในกรณีที่สมาชิกภายใต้คุณมียอดได้หรือยอดชนะ คุณจะไม่มียอดเสียใด ๆ ทั้งสิ้น ระบบจะตั้งยอดที่ชนะไปเป็นค่าใช้จ่ายในเดือนถัดไปโดยอัตโนมัติ โดยยอดชนะที่ตั้งนั้นจะถูกตัดกับยอดเสียของสมาชิกในเดือนถัดไปเรื่อย ๆ จนเป็นผลแพ้ จึงจะมีการแบ่งผลตอบแทนสุทธิประจำเดือนให้กับคุณอีกครั้งนึง</div>
        <div>เช่น : ในเดือนที่ 6 สมาชิกภายใต้คุณมียอดชนะ 10,000 คุณได้ผลตอบแทน 40% เท่ากับเดือนที่ 6 คุณมียอดแพ้สุทธิ 4,000 โดยยอดนี้จะยกไปเป็นค่าใช้จ่ายสำหรับเดือนถัดไป ถัดมาในเดือนที่ 7 สมาชิกภายใต้คุณมียอดแพ้ 20,000 คุณได้ผลตอบแทน 40% เท่ากับเดือนที่ 7 คุณมียอดชนะ 8,000 โดยจะนำมาลบกับยอดลบเดือนที่แล้วจึงจะได้ยอดสุทธิ เท่ากับ 8,000 - 4,000 = 4,000 (ยอดที่ชนะเดือนนี้ - ยอดที่แพ้จากเดือนที่แล้ว = ผลตอบแทนสุทธิ)</div>
        <div>สรุป ในเดือนที่ 7 คุณจะได้ผลตอบแทนสุทธิ 4,000 บาท</div>`,
        English: `<div>In the event that the members under you have earned or win. You will not have any losses at all. The system will automatically set the winning amount to be the next month's expenses. The winnings will be deducted from the membership losses in the following months until losing, so your monthly return will be shared again.</div>
        <div>For example: in the 6th month, members under you have a win amount of 10,000. You get a return of 40% equal to the 6th month. You have a net loss of 4,000, which this amount will carry over as expenses for the next month. Next, in the 7th month, members under you have a loss of 20,000. You get a 40% return equal to the 7th month. You have a win of 8,000, which will be subtracted from the last month's negative amount to get a net balance of 8,000 - 4,000 = 4,000 (Total win this month - Loss from last month = net return)</div>
        <div>Summary in month 7, you will receive a net return of 4,000 baht.</div>`,
      },
      {
        Thai: "<div>คุณต้องทำตามขั้นบันไดตามที่ Hiallbet กำหนดจึงจะได้รับผลตอบแทน</div>",
        English: "<div>You must follow the steps set by Hiallbet in order to receive rewards.</div>",
      },
      {
        Thai: "<div>สมาชิกภายใต้คุณจะต้องมียอดฝากขั้นต่ำ 100 บาท และมียอดเล่น(Turnover)ขั้นต่ำ 500 บาท จึงจะนับเป็นสมาชิกที่นำมาคิดผลตอบแทนของคุณ</div>",
        English: "<div>Members under you must have a minimum deposit of 100 baht and a minimum turnover of 500 baht in order to count as members to calculate your rewards.</div>",
      },
      {
        Thai: "<div>ระบบเอเย่นต์หุ้นลมของ Hiallbet ไม่มียอดขั้นต่ำในการถอนผลตอบแทนรายได้</div>",
        English: "<div>HiAffiliate is no minimum amount of withdrawal of income returns.</div>",
      },
      {
        Thai: "<div>คุณจะได้รับผลตอบแทนในวันอังคารแรกของทุก ๆ เดือนในเดือนถัดไป</div>",
        English: "<div>You will receive rewards on the first Tuesday of every month the following month.</div>",
      },
      {
        Thai: "<div>หากคุณไม่ผ่านเกณฑ์การรับผลตอบแทน รายได้สุทธิที่ได้ในเดือนนั้น ๆ จะถูกยกยอดไปยังเดือนถัดไป และหากเดือนต่อมาคุณสามารถทำได้ตามเกณฑ์ ยอดที่ถูกยกมาจะถูกรวมกับยอดปัจจุบันแล้วนำมาคิดผลตอบแทนให้กับคุณ</div>",
        English: "<div>If you do not meet the compensation criteria The net income earned in that month will be carried forward to the next month and if the following month you can meet the criteria. The amount quoted will be combined with the current balance and will be used to calculate your return.</div>",
      },
      {
        Thai: "<div>ใช่ครับ ยอดได้เสียของสมาชิกคิดจากทุกเว็บในเครือ Hiallbet ที่สมาชิกแต่ละท่านเลือกเล่น</div>",
        English: "<div>Yes, the balance of members is calculated from every website in Hiallbet that each member chooses to play.</div>",
      },
    ],
  },
  {
    id: "q4",
    nameTopic: "faq-head-4",
    listTopic: [
      "faq-topic-4-1",
      "faq-topic-4-2",
      "faq-topic-4-3",
      "faq-topic-4-4",
      "faq-topic-4-5",
      "faq-topic-4-6",
    ],
    contentDetail: [
      {
        Thai: "<div>เราจะใช้เวลาไม่เกิน 24 ชั่วโมงในการตรวจสอบ ในกรณีที่คุณได้ทำการสมัครแล้วให้คุณติดต่อไปที่ @hiaff จะมีฝ่ายบริการของเราสอบถามข้อมูลเพิ่มเติมเพื่ออนุมัติบัญชีทำงานของคุณ</div>",
        English: "<div>We will take no more than 24 hours to verify. If you have already applied, contact @hiaff. Our service department will ask for more information to approve your work account.</div>",
      },
      {
        Thai: "<div>ในกรณีที่คุณต้องการเปลี่ยนรหัสผ่านให้คุณทำการล็อคอิน กดเข้าสู่หน้าโปรไฟล์ และกดเปลี่ยนรหัสผ่าน</div>",
        English: "<div>In case you need to change your password, log in. Click to enter the profile page and click to change password.</div>",
      },
      {
        Thai: "<div>คุณสามารถแจ้งเปลี่ยนข้อมูลที่คุณต้องการ โดยแจ้งเข้ามาที่ฝ่ายบริการ @hiaff ของเราได้เลย</div>",
        English: "<div>You can change the information you need by reporting to our @hiaff service.</div>",
      },
      {
        Thai: "<div>หากคุณลืมรหัสผ่านให้แจ้งฝ่ายบริการ @hiaff ของเราเพื่อขอเปลี่ยนได้เลย โดยเราจะทำการสอบถามข้อมูลของคุณเพื่อยืนยันบัญชีผู้ใช้ของคุณ</div>",
        English: "<div>If you forget your password, notify our @hiaff service to request a change. We will ask for your information to confirm your account.</div>",
      },
      {
        Thai: "<div>หากไม่สามารถทำผ่านเกณฑ์ตามขั้นบันไดในเวลา 3 เดือน ฝ่ายบริการจะทำการติดต่อไปเพื่อแจ้งเตือนในเดือนที่ 1 และ 2 ก่อนการปิดบัญชีจริงในเดือนที่ 3 ตามลำดับ</div>",
        English: "<div>If failing to meet the steps in 3 months, the service department will contact to notify you in the 1st and 2nd months before the actual account closing in the 3rd month respectively.</div>",
      },
      {
        Thai: "<div>เราคืออาณาจักรเดิมพัน เจ้าแรกและเจ้าเดียวในประเทศไทยที่สามารถนำผลิตภันฑ์กว่า 20 มารวมกันได้ภายในเว็บเดียว ครบวงจรภายใต้บริษัทคุณภาพสากล คุณสามารถเลือกเว็บชั้นนำที่ต้องการได้ทั้งหมดในเว็บเดียว เพราะเรารวมทุกอย่างไว้ให้คุณ</div>",
        English: "<div>We are the betting empire The first and only one in Thailand that can bring more than 20 products together in one website. Complete under the international quality company. You can select all leading websites in one website. Because we include everything for you.</div>",
      },
    ],
  },
  {
    id: "q5",
    nameTopic: "faq-head-5",
    listTopic: [
      "faq-topic-5-1",
    ],
    contentDetail: [
      {
        Thai: "<ul><li>ไม่มีขั้นต่ำในการเบิกส่วนแบ่งรายได้ และสามารถถอนได้ในทุกวันอังคารแรกของเดือนถัดไป โดยระบบจะทำการตัดยอดทุก ๆ สิ้นเดือน (เมื่อผ่านเกณฑ์)</li><li>คุณไม่สามารถเป็นสมาชิกภายใต้ตัวเองได้ หากเราตรวจสอบพบ ทางเราจะทำการตักเตือนในครั้งแรก หากพบว่าทำการฝ่าฝืนอีก เราจะทำการปิดยูสเซอร์เอเย่นต์ของคุณในทันที</li></ul>",
        English: "<ul><li>There is no minimum revenue share withdrawal and can be withdrawn on the first Tuesday of the following month. The system will deduct the amount at the end of every month (when the criteria are met)</li><li>You cannot be a member under yourself. If we investigate We will give a warning for the first time. If found to violate again We will close your agency immediately.</li></ul>",
      },
    ],
  },
]