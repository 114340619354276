export const editProfile = (data) => {
  return requestEditProfile(data).then(
    response => {
      if (!response.status) {
        return { status: false }
      }
      if (!response.result) {
        return { status: false }
      }
      return { status: true }
    }
  ).catch(error => {
    return { status: false }
  });
}

async function requestEditProfile(data) {
  return true
}
