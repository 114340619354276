import React from 'react';
import styled from 'styled-components';

const BlogContent = styled.div`
    background-color: #FFF;
    display: flex;
    height: 46.45px;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    align-items: center;
    font-size: 14px;
    width: 49.5%;
    max-width: 49.5%;
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
`
const BlogMonth = styled.div`
    border-right: 1px solid #e2e2e2;
    height: 100%;
    line-height: 45px;
`

const TitleMonth = styled.span`
    font-size: 13px;
    font-weight: 600;
`
const TextMonth = styled.span`
    color: #AF8C50;
`

const TextIncome = styled.div`
    border: 1px solid #dee2e6;
    width: 150px;
    text-align: center;
    border-radius: 5px;
    height: 23px;
    color: #AF8C50;
`

function ExpensesTableSlot(props) {
    console.log(props)
    return <React.Fragment>
        <div className="row no-gutters">
        {(props.data).map((d,i)=>{
                return(<BlogContent className="d-flex mr-1" key={i}>
                    <BlogMonth className="col-4">
                        <TitleMonth>Month :</TitleMonth>
                        <TextMonth className="ml-2">
                            {d.monthName}
                        </TextMonth>
                    </BlogMonth>
                    <div className="col-8 text-center d-flex justify-content-center">
                        <TitleMonth>Expenses :</TitleMonth>
                        <TextIncome className="ml-2">{d.slotRevenue.toFixed(2)}</TextIncome>
                    </div>
                </BlogContent>)
           })}
        </div>
    </React.Fragment>
}

export default ExpensesTableSlot;