import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);


class EarningState extends React.Component {
    componentDidMount() {
        this.renderAm4(this.props.data)
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    renderAm4(data) {
        let chart = am4core.create("EarningState", am4charts.XYChart);
        // Add data
        chart.data = data

        chart.colors.list = [
            am4core.color("#929090"),
        ];

        // Create category axis
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "monthName";
        categoryAxis.renderer.opposite = false;

        // Create value axis
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inversed = false;
        valueAxis.title.text = '';
        valueAxis.renderer.minLabelPosition = 0.01;

        // Create series
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "netInCome";
        series.dataFields.categoryX = "monthName";
        series.name = '';
        series.strokeWidth = 1;
        series.bullets.push(new am4charts.CircleBullet());
        series.tooltipText = "{categoryX}: {valueY}";
        series.legendSettings.valueText = "{categoryX}: {valueY}";
        series.visible = false;

        chart.colors.list = [
            am4core.color("#929090"),
        ];
        // Add chart cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomY";

        // Add legend
        chart.legend = new am4charts.Legend();

    }

    render() {
        return (
            <div id="EarningState" style={{ width: "100%", height: "350px" ,fontSize : "12px" }}></div>
        );
    }
}

export default EarningState;