import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { getSession } from '../../utils/login';
import { editProfile } from '../../utils/profile';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import LoginHistory from './LoginHistory';
import ModalAlertProfile from '../../components/Modal/ModalAlertProfile/ModalAlertProfile';
import BoxAcademy from '../../components/Academy/BoxAcademy';
import config from "config/default.json";

// import ModalSingup from '../../components/Modal/ModalSingUp/ModalSingUp'
// import { Modal } from "../../../components/Modal/Modal";

function Profile({ t }) {

    const [profile, setProfile] = useState([]);
    const [edit, setEdit] = useState(false);
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);
    const [modalProfileOpen, setModalProfileOpen] = useState(false);

    const getProfile = async () => {
        const session = await getSession();
        if (session) {
            setProfile(session);
            if(!session.bankNameAccount){ return setModalProfileOpen(true) }
            if(
                !session.phone && 
                !session.email && 
                !session.line && 
                !session.whatapp && 
                !session.wechat && 
                !session.subdistrict && 
                !session.district && 
                !session.province && 
                !session.postcode && 
                !session.country && 
                !session.address
                ){setModalProfileOpen(true) }
        }
    }
    useEffect(() =>{
        getProfile()
    }, []);

    const TitleBar = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        background-color: #313131;
        height: 35px;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 5px;
        color: #FFFFFF;
        font-weight: 700;
        text-align: left;
        border-radius: 3px;
        margin-top: 15px;
    `;

    const ItemBox = styled.div`
        background-color: #FFF;
        margin: 5px 0;
        border-radius: 5px;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
        align-items: center;
        font-size: 14px;
        padding: 24px;
        height: 100%;
    `;

    const Item = styled.div`
        width: 100%;
        font-weight: 600;
        text-align: center;
        align-items: center;
        color: ${ props => props.color};
        font-size: ${ props => props.fontSize ? props.fontSize : "14px"};
    `;

    const BadgeProfile = styled.div`
        color: #828282;
        width: auto;
        text-align: center;
        border-radius: 5px;
        height: 35px;
        padding: 4px;
        color: ${ props => props.color};
        font-size: ${ props => props.fontSize ? props.fontSize : "12px"};
        display: block;
        margin-left: auto;
        margin-right: auto;
        background: #f3f3f3;
        box-shadow: 0px 3px 6px 0px #e3e3e3;
        line-height: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `;

    const LabelProfile = styled.div`
        text-align: left;
    `;

    const ItemBoxContact = styled.div`
        background-color: #FFF;
        margin: 5px 0;
        border-radius: 5px;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
        align-items: center;
        font-size: 14px;
        padding: 24px;
        height: auto;
    `;

    const ItemContact = styled.div`
        display: flex;
        width: 100%;
        flex: 0 0 150px;
        height: auto;
        border-radius: 4px;
        padding: 5px 10px;
        cursor: pointer;
        background-color: #f3f3f3;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
        border-radius: 4px;
        margin: 0px 0 10px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #828282;
        font-size: 12px;
    `;


    const SlideItem = styled.div`
        font-size: 13px;
        padding: 4px;
        width: 100%;
        .form-control {
            padding: 0rem;
            font-size: 13px;
            height: auto;
            color: #828282;
            background-color: #ebebeb;
            border: unset;
            border-bottom: 1px solid #af8c5030;
            border-radius: unset;
            margin-top: 2px;
            &:focus {
                box-shadow: unset;
                background-color: #ffffff;
                color: #af8c50;
            }
            &:disabled, .form-control[readonly] {
                background-color: transparent;
                border-bottom: unset;
                opacity: 1;
            }
        }
    `;

    const Label = styled.div`
        color: #4e5665;
        font-size: 12px;
        font-weight: bold;
    `;

    const ContactBox = styled.div`
        // width: 20%;
    `

    const EditDiv = styled.div`
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 190px;
        right: 0;
        img {
            &:hover {
                cursor: pointer;
            }
        }
    `
        
    const AlertSpecial = styled.div`
        position: absolute;
        width: 100%;
        left: 10px;
        right: 0;
        top: -40px;
        animation: blink 2.5s linear infinite;
        .alert {
            font-size: 12px;
            padding: 0.35rem;
            margin-bottom: 0;
            button {
                padding: 0px;
                font-size: 14px;
            }
        }
    `

    const ButtonSave = styled.button`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-color: #AF8C50;
        color: #AF8C50;
        box-shadow: unset;
        font-size: 14px;
        width: 100px;
        padding: 0.275rem .75rem;
        &:active, &:hover {
            color: #828282;
        }
    `

    const ButtonCancel = styled.button`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-color: #828282;
        color: #828282;
        box-shadow: unset;
        font-size: 14px;
        width: 100px;
        padding: 0.275rem .75rem;
        &:active, &:hover {
            color: #AF8C50;
        }
    `
    // console.log(profile)

    const editPorfile = ()=>{
        setEdit(!edit)
    }

    const changedProfile = (e) => {
        const u = profile
        u[e.target.id] = e.target.value
        setProfile(u)
    }

    const saveProfile = () => {
        setEdit(!edit)
        editProfile(profile)
    }

    const cancelProfile = () => {
        const session = getSession();
        if (session) {
            setProfile(session);
        }
        setEdit(false)
    }

    function onCloseModal() {
        setModalProfileOpen(false)
    }

    function notCloseModal() {
        setModalProfileOpen(true)
    }

    return (
        
        <div>
            {config.ACADEMY ? <BoxAcademy t={t} />: null}
            <ModalAlertProfile open={modalProfileOpen} onCloseModal={onCloseModal} notCloseModal={notCloseModal} t={t} />
            <Breadcrumb title={'Profile'} />
            <div className={'row no-gutters'}>
                <div className={'col-6 col-lg-3 col-md-6 p-1'}>
                    <ItemBox>
                        <Item color="#AF8C50">{t('account-detail')}</Item>
                        <Item className="mt-1"><img src={'/icon/user.svg'} alt={'Profile Icon'} /></Item>
                        <Item className="mt-1">{t('affiliate-code')}</Item>
                        <Item fontSize="11px">{profile.agentCode}</Item>
                        <Item fontSize="11px" className="mt-3">
                            <LabelProfile className="mb-1">{t('username')}</LabelProfile>
                            <BadgeProfile>{profile.username}</BadgeProfile>
                        </Item>
                        <Item fontSize="11px" className="mt-3">
                            <LabelProfile className="mb-1">{t('fullname')}</LabelProfile>
                            <BadgeProfile>{profile.bankNameAccount}</BadgeProfile>
                        </Item>
                    </ItemBox>
                </div>
                <div className={'col-6 col-lg-3 col-md-6 p-1'}>
                    <ItemBox>
                        <Item color="#AF8C50">{t('account-info')}</Item>
                        <Item className="mt-1"><img src={profile.bankImg} alt={'Profile Icon'} width="51px" /></Item>
                        <Item className="mt-1">{t('bank')}</Item>
                        <Item fontSize="11px">{profile.bankName}</Item>
                        <Item fontSize="11px" className="mt-3">
                            <LabelProfile className="mb-1">{t('account-number')}</LabelProfile>
                            <BadgeProfile>{profile.bankAccount}</BadgeProfile>
                        </Item>
                        <Item fontSize="11px" className="mt-3">
                            <LabelProfile className="mb-1">{t('account-name')}</LabelProfile>
                            <BadgeProfile>{profile.bankNameAccount}</BadgeProfile>
                        </Item>
                    </ItemBox>
                </div>
            </div>
            <TitleBar>
                <div>
                    <img src={'/icon/contact.svg'} alt={'icon'} width={'25px'} height={'20px'} className="mr-1" />
                    {t('contact')}
                </div>
            </TitleBar>
            <ItemBoxContact>
                <div className="row">
                    <ContactBox className={'col-6 col-md-6 col-lg-3'}>
                        <ItemContact>
                            <div>
                                <img src={'/icon/phone_ number.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('phonenumber')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100">
                                            <Input type="tel" name="phone" id="phone" defaultValue={profile.phone !== undefined ? profile.phone : ''} disabled={!edit} onChange={(e) => changedProfile(e)} />
                                        </div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                        <ItemContact>
                            <div>
                                <img src={'/icon/icon_email.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('email')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="email" name="email" id="email" defaultValue={profile.email !== undefined ? profile.email : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                        <ItemContact>
                            <div>
                                <img src={'/icon/line.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('line')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="line" id="line" defaultValue={profile.line !== undefined ? profile.line : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                    </ContactBox>
                    {/* ..  */}
                    <ContactBox className={'col-6 col-md-6 col-lg-3'}>
                        <ItemContact>
                            <div>
                                <img src={'/icon/whatapp.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('whatsapp')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="whatsapp" id="whatsapp" defaultValue={profile.whatsapp !== undefined ? profile.whatsapp : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                        <ItemContact>
                            <div>
                                <img src={'/icon/wechat.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('wechat')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="wechat" id="wechat" defaultValue={profile.wechat !== undefined ? profile.wechat : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                    </ContactBox>
                    {/* ..  */}
                    <ContactBox className={'col-6 col-md-6 col-lg-3'}>
                        <ItemContact>
                            <div>
                                <img src={'/icon/address.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('address')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="address" id="address" defaultValue={profile.address !== undefined ? profile.address : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                        <ItemContact>
                            <div>
                                <img src={'/icon/map_marker.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('subdistrict')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="subdistrict" id="subdistrict" defaultValue={profile.subdistrict !== undefined ? profile.subdistrict : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                        <ItemContact>
                            <div>
                                <img src={'/icon/map_marker.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('district')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="district" id="district" defaultValue={profile.district !== undefined ? profile.district : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                    </ContactBox>
                    {/* ..  */}
                    <ContactBox className={'col-6 col-md-6 col-lg-3'}>
                        <ItemContact>
                            <div>
                                <img src={'/icon/map_marker.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('province')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="province" id="province" defaultValue={profile.province !== undefined ? profile.province : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                        <ItemContact>
                            <div>
                                <img src={'/icon/map_marker.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('postcode')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="postcode" id="postcode" defaultValue={profile.postcode !== undefined ? profile.postcode : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                        <ItemContact>
                            <div>
                                <img src={'/icon/map_marker.svg'} alt={'Profile Icon'} />
                            </div>
                            <SlideItem>
                                <div>
                                    <div>
                                        <Label>{t('country')}</Label>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mr-auto w-100"><Input type="text" name="country" id="country" defaultValue={profile.country !== undefined ? profile.country : ''} disabled={!edit} onChange={(e) => changedProfile(e)} /></div>
                                    </div>
                                </div>
                            </SlideItem>
                        </ItemContact>
                    </ContactBox>
                </div>
                <div className={edit ? "d-flex justify-content-center" : "d-none"}>
                    <ButtonSave type={'button'} className={"btn mr-2"} onClick={() => saveProfile()}>Save</ButtonSave>
                    <ButtonCancel type={'button'} className={"btn"} onClick={() => cancelProfile()}>Cancel</ButtonCancel>
                </div>
            </ItemBoxContact>
        </div>
    );
}

export default Profile;