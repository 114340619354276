import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import moment from 'moment';
import styled from 'styled-components';

import Breadcrumb from '../../../../components/Breadcrumb/BreadcrumbType';
import FilterMember from '../../../../components/FilterBuilder/FilterMember/FilterMember';
import { getSession } from '../../../../utils/login';
import currencyTransform from "../../../../utils/currency";
import currencyColor from "../../../../utils/numericColor";
import DefaultBadge from '../../../../components/Badge/DefaultBadge/DefaultBadge';
import config from "config/default.json"

const TableContain = styled.div``;

const CustomTable = styled.table`
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0 5px;
  thead {
    background-color: #313131;
    color: #FFF;
  }
  thead th {
    border-top: unset;
    border-bottom: unset;
  }
  th {
    text-align: center;
    padding: 5px;
    vertical-align: middle !important;
  }
  
  tbody tr {
    background-color: #FFF;
  }

  thead tr:first-child th:first-child {
    border-radius: 0.5em 0 0 0.5em;
  }
  thead tr:first-child th:last-child {
    border-radius: 0 0.5em 0.5em 0;
  }
  tbody tr td:first-child{
    border-radius: 0.5em 0 0 0.5em;
  }
  tbody tr td:last-child {
    border-radius: 0 0.5em 0.5em 0;
  }

  tbody tr td {
    vertical-align: middle;
    text-align: center;
    padding: .35rem;
    border-top: unset;
  }

  th:nth-child(6)
  {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-right: 1px solid;
  }

  th:nth-child(7)
  {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }

  th:nth-child(8)
  {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    border-left: 1px solid;
  }

  th.th-radius{
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    border: 1px solid;
  }
`;

const TrTotal = styled.tr`
  line-height: 2;
  td {
      border-top: 1px solid #af8c50 !important;
      border-bottom: 1px solid #af8c50;
  }
  td:first-child {
      color: #af8c50;
      font-weight: 500;
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-left: 1px solid #af8c50;
  }
  td:last-child {
      border-top: 1px solid #af8c50;
      border-bottom: 1px solid #af8c50;
      border-right: 1px solid #af8c50;
  }
`;

function SubAffList({t}) {

  const [dataList, setDataList] = useState([]);
  const [dataTotal, setDataTotal] = useState([]);
  const [stats, setStats] = useState([]);
  const [filter, setFilter] = useState([]);
  const [month, setMonth] = useState(moment().format('MMYYYY'));
  const [tabActive, setTabActive] = useState('HIALLBET')

  useEffect(() => {
    const session = getSession();
    if (session) {
    console.log(session)
      _fetchDataList(session.agentCode);
      setFilter(session)
    }
  }, [month]);

  async function _fetchDataList(agentCode) {
    console.log(agentCode)
    const bodySet = {
      agentCode: agentCode,
      date: month
    };
    const response = await fetch(config.URL_API + '/getSubAffDetailActivityReport', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.dateList;
        setDataList(result);
        const total = data.response_data.total;
        setDataTotal(total)
      }
    }
  }

  const actionMonth = (e) => {
    setMonth(e.target.value)
  }

  const handleLink = async (date) => {
    window.open(`/report/sub-affiliate-activity-report-eachdate?date=${date}`, "_self")
  };

  const activeBtn = (e) => {
    setTabActive(e)
    // console.log(e)
    // if (e === "HIALLBET") {
    //   _fetchDataList(agentCode)
    // } else if (e === "HISLOT") {
    //   _fetchDataListSlot(agentCode)
    // }
  }

  console.log(dataList)
  return (
    <div>
      <Breadcrumb title={'Detailed Activity Report'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
      <FilterMember t={t} data={stats} isShowDate={false} isShowMonth={true} actionMonth={actionMonth} isShowLevel={true} />
      <TableContain className={'table-responsive'}>
        <CustomTable className={'table'}>
          <thead className="text-center">
            <tr>
              <th rowSpan="2">{t('date')}</th>
              <th rowSpan="2">{t('hits')}</th>
              <th rowSpan="2">{t('signup')}</th>
              <th rowSpan="2" width={'120px'}>{t('first-time-depositors')}</th>
              <th rowSpan="2" width={'100px'}>{t('active-players')}</th>
              <th rowSpan="2">{t('deposit')}</th>
              <th colSpan="4">{t('winloss')}</th>
              <th rowSpan="2">{t('bonus')}</th>
              {/* <th rowSpan="2">{t('brought-forward')}</th> */}
              <th rowSpan="2">{t('winloss-return')}</th>
              <th rowSpan="2">{t('net-winloss')}</th>
              <th rowSpan="2">{t('revenue')}</th>
            </tr>
            <tr>
              <th className="th-radius">{t('sport')}</th>
              <th className="th-radius">{t('casino')}</th>
              <th className="th-radius">{t('games')}</th>
              <th className="th-radius">{t('esport')}</th>
            </tr>
          </thead>

          <tbody>
            {
              dataList.map((m, mIndex) => (
                <tr key={mIndex}>
                  <td onClick={() => handleLink(m.keyDate)}><DefaultBadge text={m.date} /></td>
                  <td>{0}</td>
                  <td>{m.signUps}</td>
                  <td>{m.firstDeposit}</td>
                  <td>{m.activePlayer}</td>
                  <td>{currencyTransform(m.deposit)}</td>
                  <td className={m.winloseSport < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseSport)}</td>
                  <td className={m.winloseCasino < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseCasino)}</td>
                  <td className={m.winloseGame < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseGame)}</td>
                  <td className={m.winloseEsport < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseEsport)}</td>
                  <td>{currencyColor(m.transInBonus, 'text__danger')}</td>
                  {/* <td>{currencyColor(m.carryForwardWinlose, 'text__danger')}</td> */}
                  <td className={m.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseReturn)}</td>
                  <td className={m.netWinlose < 0 ? 'text__danger' : ''}>{currencyTransform(m.netWinlose)}</td>
                  <td>{currencyColor(m.inCome)}</td>
                </tr>
              ))
            }
            <TrTotal>
              <td colSpan={1}>{t('total')}</td>
              <td>{0}</td>
              <td>{dataTotal.signUps}</td>
              <td>{dataTotal.firstDeposit}</td>
              <td>{dataTotal.activePlayer}</td>
              <td>{currencyTransform(dataTotal.deposit)}</td>
              <td className={dataTotal.winloseSport < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseSport)}</td>
              <td className={dataTotal.winloseCasino < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseCasino)}</td>
              <td className={dataTotal.winloseGame < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseGame)}</td>
              <td className={dataTotal.winloseEsport < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseEsport)}</td>
              <td>{currencyColor(dataTotal.transInBonus, 'text__danger')}</td>
              <td className={dataTotal.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseReturn)}</td>
              <td className={dataTotal.netWinlose < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.netWinlose)}</td>
              <td>{currencyColor(dataTotal.inCome)}</td>
            </TrTotal>
          </tbody>
        </CustomTable>
      </TableContain >
    </div >
  );
}

export default SubAffList;