import React, { useEffect, useState } from 'react';
import fetch from 'isomorphic-unfetch';
import moment from "moment";
import styled from 'styled-components';
import currencyTransform from '../../../../utils/currency';
import currencyColor from "../../../../utils/numericColor";

import Breadcrumb from '../../../../components/Breadcrumb/BreadcrumbType';
import ReportDepositTable from '../../../../components/Table/ReportDepositTable/ReportDepositTable';
import FilterMember from '../../../../components/FilterBuilder/FilterMember/FilterMember';
import DefaultBadge from "../../../../components/Badge/DefaultBadge/DefaultBadge";
import { getSession } from '../../../../utils/login';
import config from "config/default.json"

const columnList = [
  { title: "month", width: "100px" },
  { title: "hits", width: "auto" },
  { title: "signup", width: "auto" },
  { title: "first-time-depositors", width: "auto" },
  { title: "active-players", width: "auto" },
  { title: "deposit", width: "auto" },
  { title: "sport", width: "auto" },
  { title: "casino", width: "auto" },
  { title: "games", width: "auto" },
  { title: "esport", width: "auto" },
  { title: "bonus", width: "auto" },
  { title: "winloss-return", width: "auto" },
  { title: "net-winloss", width: "auto" },
  { title: "percent-deposit", width: "auto" },
  { title: "net-income", width: "auto" },
  { title: "reward", width: "auto" },
];

const TrTotal = styled.tr`
  line-height: 2;
  td {
    border-top: 1px solid #af8c50 !important;
    border-bottom: 1px solid #af8c50;
  }
  td:first-child {
    color: #af8c50;
    font-weight: 500;
    border-top: 1px solid #af8c50;
    border-bottom: 1px solid #af8c50;
    border-left: 1px solid #af8c50;
  }
  td:last-child {
    border-top: 1px solid #af8c50;
    border-bottom: 1px solid #af8c50;
    border-right: 1px solid #af8c50;
  }
`;

function DetailActivityReport({t}) {

  const [dataList, setDataList] = useState([]);
  const [date, setDate] = useState("");
  const [dataTotal, setDataTotal] = useState([]);
  const [month, setMonth] = useState(moment().format('MMYYYY'));
  const [tabActive, setTabActive] = useState('HIALLBET')

  useEffect(() => {
    const session = getSession();
    if (session) {
      _fetchDataList(session.agentCode, date);
    }
  }, [date, month]);

  async function _fetchDataList(agentCode, d) {

    const bodySet = {
      agentCode: agentCode,
      date: month
    };
    const response = await fetch(config.URL_API + '/getDetailActivityReport', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodySet)
    });
    if (response.ok) {
      const data = await response.json();
      if (data.response_result) {
        const result = data.response_data.dateList;
        if (result) {
          const total = data.response_data.total;
          // console.log(result)
          setDataTotal(total)
          setDataList(result);
        } else {
          setDataList(new Array());
        }
      }
    }
  }

  const actionMonth = (e) => {
    setMonth(e.target.value)
  }

  const handleLink = async (date) => {
    window.open(`/report/activity-eachdate?date=${date}`, "_self")
  };

  const activeBtn = (e) => {
    setTabActive(e)
    // console.log(e)
    // if (e === "HIALLBET") {
    //   _fetchDataList(agentCode)
    // } else if (e === "HISLOT") {
    //   _fetchDataListSlot(agentCode)
    // }
  }

  return (
    <div>
      <Breadcrumb title={'Detailed Activity Report'} onClick={(e) => activeBtn(e)} tabActive={tabActive} />
      <FilterMember t={t} isShowMonth={true} actionMonth={actionMonth} />
      <ReportDepositTable t={t} headers={columnList}>
        {dataList.map((m, mIndex) => (
          <tr key={mIndex}>
            <td onClick={() => handleLink(m.keyDate)}><DefaultBadge text={m.date} /></td>
            <td>{0}</td>
            <td>{m.signup}</td>
            <td>{m.firstDeposit}</td>
            <td>{m.activePlayer}</td>
            <td className={m.depositAmount < 0 ? 'text__danger' : ''}>{currencyTransform(m.depositAmount)}</td>
            <td className={m.winloseSport < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseSport)}</td>
            <td className={m.winloseCasino < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseCasino)}</td>
            <td className={m.winloseGame < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseGame)}</td>
            <td className={m.winloseEsport < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseEsport)}</td>
            <td>{currencyColor(m.transInBonus, 'text__danger')}</td>
            <td className={m.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(m.winloseReturn)}</td>
            <td className={m.netWinlose < 0 ? 'text__danger' : ''}>{currencyTransform(m.netWinlose)}</td>
            <td className={'text__success'}>{currencyTransform(m.revenueFirstDeposit)}</td>
            <td>{currencyColor(m.inCome)}</td>
            <td>{currencyColor(m.rewardAmount, 'text__danger')}</td>
          </tr>
        ))}
        <TrTotal>
          <td colSpan={1}>{t('total')}</td>
          <td>{currencyTransform(0)}</td>
          <td>{dataTotal.signup}</td>
          <td>{dataTotal.firstDeposit}</td>
          <td>{dataTotal.activePlayer}</td>
          <td className={dataTotal.depositAmount < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.depositAmount)}</td>
          <td className={dataTotal.winloseSport < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseSport)}</td>
          <td className={dataTotal.winloseCasino < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseCasino)}</td>
          <td className={dataTotal.winloseGame < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseGame)}</td>
          <td className={dataTotal.winloseEsport < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseEsport)}</td>
          <td>{currencyColor(dataTotal.transInBonus, 'text__danger')}</td>
          <td className={dataTotal.winloseReturn < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.winloseReturn)}</td>
          <td className={dataTotal.netWinlose < 0 ? 'text__danger' : ''}>{currencyTransform(dataTotal.netWinlose)}</td>
          <td className={'text__success'}>{currencyTransform(dataTotal.revenueFirstDeposit)}</td>
          <td>{currencyColor(dataTotal.inCome)}</td>
          <td>{currencyColor(dataTotal.rewardAmount, 'text__danger')}</td>
        </TrTotal>
      </ReportDepositTable>
    </div>
  );
}

export default DetailActivityReport;