import React, { useState, useEffect } from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import styled from "styled-components";
import DateBox from "../Navbar/DateBox/DateBox";
import LanguageBox from "../Navbar/LanguageBox/LanguageBox";
import { logout, getSession } from "../../utils/login";
import config from "config/default.json"
import ModalContact from '../../components/Modal/ModalContact/ModalContact';

// console.log(config)
let menuItem = [
  {
    id: 0,
    text: "home",
    value: "menu.home",
    link: "/",
    path: "",
    iconPath: "/icon/home.png",
    iconActive: "/icon/home_color.png",
    subMenu: []
  },
  {
    id: 1,
    text: "dashboard",
    value: "menu.dashboard",
    link: "/dashboard",
    path: "dashboard",
    iconPath: "/icon/dashboard.png",
    iconActive: "/icon/dashboard_color.png",
    subMenu: []
  },
  {
    id: 2,
    text: "marketing-media",
    value: "menu.marketingMedia",
    link: "/marketing-media",
    path: "marketing-media",
    iconPath: "/icon/marketing_media.png",
    iconActive: "/icon/marketing_media_color.png",
    subMenu: [
      {
        text: "Links",
        value: "marketingMedia.link",
        link: "/link"
      },
      {
        text: "Gallery",
        value: "marketingMedia.gallery",
        link: "/gallery"
      }
    ]
  },
  {
    id: 3,
    text: "finances",
    value: "menu.finances",
    link: "finances/finances",
    path: "finances",
    iconPath: "/icon/finances.png",
    iconActive: "/icon/finances_color.png",
    subMenu: [
      {
        text: "Earning Summary",
        value: "finances.summary",
        link: "/summary"
      },
      {
        text: "Proportional Expenses",
        value: "finances.expenses",
        link: "/proportional-expenses"
      }
    ]
  },
  {
    id: 4,
    text: "member",
    value: "menu.member",
    link: "/member/member-list",
    path: "member",
    iconPath: "/icon/member.png",
    iconActive: "/icon/member_color.png",
    subMenu: [
      {
        text: "Member List",
        value: "meme.member.list",
        link: "/member-list"
      },
      {
        text: "Member Report",
        value: "meme.member.report",
        link: "/member-report"
      },
      {
        text: "Member Deposit",
        value: "meme.member.deposit",
        link: "/member-deposit"
      }
    ]
  },
  {
    id: 5,
    text: "sub-affiliate",
    value: "menu.subAff",
    link: "/",
    path: "sub-aff",
    iconPath: "/icon/sub_affiliate.png",
    iconActive: "/icon/sub_affiliate_color.png",
    subMenu: [
      {
        text: "Sub Affiliate List",
        value: "sub.affiliate.list",
        link: "/sub-affiliate-list"
      },
      {
        text: "Sub Affiliate Report",
        value: "sub.affiliate.report",
        link: "/sub-affiliate-report"
      }
    ]
  },
  {
    id: 6,
    text: "report",
    value: "menu.report",
    link: "/",
    path: "report",
    iconPath: "/icon/reports.png",
    iconActive: "/icon/reports_color.png",
    subMenu: [
      {
        text: "Detailed Activity Report",
        value: "report.detail_activity",
        link: "/detail-activity-report",
        statusInSub: false
      },
      {
        text: "earning-report",
        value: "report.earning.monthly",
        link: "/earning-monthly-report",
        statusInSub: true,
        subInSub: [
          {
            text: "Monthly Earning Report",
            value: "report.earning.monthly",
            link: "/earning-monthly-report"
          },
          {
            text: "Earnings Breakdown Report",
            value: "report.earning.breakdown",
            link: "/earning-breakdown-report"
          },
          {
            text: "Proportional Expenses Detail",
            value: "report.earning.expenses",
            link: "/expenseslist-detail"
          }
        ]
      },
      {
        text: "Product Report",
        value: "report.product.member",
        link: "/member-product",
        statusInSub: true,
        subInSub: [
          {
            text: "member",
            value: "report.product.member",
            link: "/member-product"
          },
          {
            text: "sub-affiliate",
            value: "report.product.subaff",
            link: "/subaff-product"
          }
        ]
      },
      {
        text: "Sub Affiliate Report",
        value: "sub.affiliate.activity",
        link: "/sub-affiliate-activity",
        statusInSub: true,
        subInSub: [
          {
            text: "Detailed Activity Report",
            value: "sub.affiliate.activity",
            link: "/sub-affiliate-activity"
          },
          {
            text: "Earnings Breakdown Report",
            value: "sub.affiliate.breakdown",
            link: "/sub-affiliate-breakdown"
          }
        ]
      }
    ]
  },
  {
    id: 7,
    text: "Academy",
    value: "menu.academy",
    link: "/academy",
    path: 'academy',
    iconPath: "/icon/course_dlop.svg",
    iconActive: "/icon/course_color.svg",
    subMenu: []
  },
  {
    id: 8,
    text: "help-support",
    value: "menu.support",
    link: "/support",
    path: "support",
    iconPath: "/icon/help_support.png",
    iconActive: "/icon/help_support_color.png",
    subMenu: []
  }
];

const Logo = styled.div`
  padding-top: 25px;
  text-align: center;
  display: flex;
  > img {
    max-width: 100%;
    width: 168px;
  }
`;

const MenuContain = styled.div`
  margin-top: 35px;
  > div:nth-child(8) {
    &:hover {
      cursor: no-drop;
    }
  }
`;

const Menu = styled.div`
  font-size: 14px;
  line-height: 2;
  cursor: pointer;
  padding-left: 25px;
  padding: 5px 22px;
  text-align: left;
  display: flex;
  height: 40px;
  > span {
    margin-left: 10px;
    color: #828282;
    transition: all 350ms ease-out;
  }

  &:hover > span {
    color: #fff;
  }

  &:hover > span.linkdisabled {
    color: #828282;
  }

  &.active {
    background-color: #1a1a1a;
    span {
      color: #d5a95c;
    }
  }
`;

const SubMenu = styled.div`
  font-size: 13px;
`;

const CustomLink = styled(NavLink)`
  color: #828282;
  display: list-item;
  &:focus,
  &:hover,
  &:active {
    color: #fff;
    text-decoration: none;
  }

  &.active {
    color: #d5a95c;
  }

  ul {
    padding-inline-start: 0px;
    margin-bottom: 0rem;
  }
`;

const CustomLinkInSub = styled(NavLink)`
  color: #828282;
  &:focus,
  &:hover,
  &:active {
    color: #fff;
    text-decoration: none;
  }

  &.active {
    color: #d5a95c;
  }

  ul {
    padding-inline-start: 0px;
    margin-bottom: 0rem;
  }
`;

const IconNav = styled.span`
  display: block;
  width: 30px;
  height: 2px;
  margin: 5px auto;
  background-color: #d5a95c;
  border-radius: 1px;
  transition: all 0.15s;
  opacity: 0.7;
`;

const LogoImgPC = styled.div`
  display: none;
  @media (min-width: 1025px) {
    display: block;
  }
`;

const ButtonNav = styled.button`
  line-height: 1;
  height: 30px;
  background-color: transparent;
  padding: 0.25rem 0.75rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-radius: 0.25rem;
  display: none;
  margin-left: 5px;
  margin-right: 5px;
  &:focus,
  &:hover,
  &:active {
    outline: 0px auto -webkit-focus-ring-color;
  }
  @media (max-width: 1024px) {
    display: block;
  }
`;

const ActionContain = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 10px;
  > div {
    padding: 0px;
  }
  > div > input {
    width: 100%;
    background-color: #828282;
    border: unset;
    font-size: 12px;
    height: calc(1.2em + 0.75rem);
    margin: 0px;
    background-size: 35px 12px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const ButtonLogout = styled.button`
  color: #fff;
  background-color: #d13c3c;
  border-color: #d13c3c;
  font-size: 14px;
  margin: 35px 0;
  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
`;

function Sidebar({ t, i18n, Nav, setNav }) {
  let history = useHistory();
  let location = useLocation();
  const [active, setActive] = useState("");
  const [open, setOpen] = useState(false);
  const [subs, setSub] = useState("");
  const [search, setSearch] = useState("");
  const [userState, setUserState] = useState({
    username: "",
    level: ""
  });

  const SidebarContain = styled.div`
    background-color: #131212;
    width: 270px;
    position: static;
    min-height: 100%;
    margin-right: 12px;
    @media (max-width: 1024px) {
      width: ${Nav ? "65px" : "270px"};
    }
    @media (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      background-color: ${Nav ? "#f7f7f7" : "#131212"};
      height: ${Nav ? "74px" : "100vh"};
      min-height: ${Nav ? "74px" : "100%"};
      overflow: ${Nav ? "hidden" : "auto"};
      padding-bottom: ${Nav ? "0" : "150px;"};
    }
  `;

  const TextMenu = styled.span`
    @media (max-width: 1024px) {
      display: ${Nav ? "none" : "block"};
    }
  `;

  const SubMenuList = styled.div`
    padding-left: 70px;
    @media (max-width: 1024px) {
      display: ${Nav ? "none" : "block"};
    }
  `;

  const LogoImg = styled.div`
	padding-right: 15px
	display: none;
	@media (max-width: 1024px) {
		display: ${Nav ? "none" : "block"};
	}
	`;

  const ProfileBoxContain = styled.div`
    display: ${Nav ? "none" : "flex"};
    justify-content: center;
    margin-top: 25px;
    > div > div {
      color: #fff;
    }
    @media (min-width: 768px) {
      display: none;
    }
  `;

  const DateBoxContain = styled.div`
    display: ${Nav ? "none" : "flex"};
    justify-content: center;
    margin-top: 15px;
    > div {
      color: #828282;
    }
    @media (min-width: 1024px) {
      display: none;
    }
  `;

  const TimeLog = styled.fieldset`
    display: none;
    @media (max-width: 1023px) {
      display: ${Nav ? "none" : "block"};
      justify-content: center;
      text-align: center;
      padding-top: 20px;
      border-top: 1px solid #9b7b45;
      margin-top: 20px;
    }
  `;

  useEffect(() => {
    const session = getSession();
    if (!session) {
      _logout();
    }
    setUserState({
      ...useState,
      ...{
        username: session.username,
        level: session.gen
      }
    });
    if(!config.ACADEMY){
      menuItem = menuItem.filter(d=> d.text !== "Academy")
    }
    const currentPath = location.pathname.split("/")[1];
    setActive(currentPath);
  }, []);

  function _setActive(path, link, isSubMenu) {
    setActive(path);
    if (!config.DASHBOARD && path === "support") {
      setActive(false)
      if(config.LINE){
        window.open(config.CONTACT_LINE);
      }else if(config.VIBER){
        setOpen(true)
      }
      return;
    }
    if (!config.DASHBOARD && path === "dashboard") {
      setActive(false)
      return;
    }
    if (path === "help") {
      window.open(config.CONTACT_LINE);
      return;
    }
    if (isSubMenu) {
      history.push(link);
    } else {
      setNav(false);
    }
  }

  function BtnNav() {
    setNav(!Nav);
  }

  function _setSub(value) {
    setSub(value);
  }

  function _logout() {
    logout();
    history.push("/login");
  }

  function _searchText(keyword) {
    if (keyword.length > 2) {
      setSearch(keyword);
    } else if (keyword.length === 0) {
      setSearch("");
    }
  }

  return (
    <SidebarContain>
      <Logo>
        <ButtonNav onClick={BtnNav}>
          <IconNav></IconNav>
          <IconNav></IconNav>
          <IconNav></IconNav>
        </ButtonNav>
        <LogoImg>
          <img
            src={config.LOGO_AFF}
            alt={"hiaff"}
            width={"150px"}
          />
        </LogoImg>
      </Logo>
      <LogoImgPC className="pl-4">
        <img src={config.LOGO_AFF} alt={"hiaff"} width={"150px"} />
      </LogoImgPC>
      <MenuContain>
        {menuItem.map((m, mIndex) => {
          const sub =
            active === m.path ? (
              <SubMenuList>
                {m.subMenu.map((sm, smIndex) => {
                  const subInSub =
                    sm.statusInSub && subs === sm.value ? (
                      <div>
                        {sm.subInSub.map((insub, insubIndex) => (
                          <SubMenu key={insubIndex}>
                            <CustomLinkInSub
                              activeClassName={"active"}
                              to={"/" + m.path + insub.link}
                            >
                              {" "}
                              - {t(insub.text)}
                            </CustomLinkInSub>
                          </SubMenu>
                        ))}
                      </div>
                    ) : (
                      ""
                    );
                  return (
                    <div key={smIndex}>
                      <SubMenu>
                        <CustomLink
                          className={subs === sm.value ? "active" : ""}
                          to={"/" + m.path + sm.link}
                          onClick={() => _setSub(sm.value)}
                        >
                          {t(sm.text)}
                        </CustomLink>
                      </SubMenu>
                      {subInSub}
                    </div>
                  );
                })}
              </SubMenuList>
            ) : null;
          return (
            <div className="mb-2" key={mIndex}>
              <Menu
                className={active === m.path ? "active" : ""}
                key={mIndex}
                onClick={() =>
                  _setActive(m.path, m.link, m.subMenu.length === 0)
                }
                style={(m.path === "dashboard" && config.DASHBOARD === false) ? { cursor: 'no-drop' } : null}
              >
                <img
                  src={active === m.path ? m.iconActive : m.iconPath}
                  alt={m.text}
                  className="my-auto"
                  width={"20px"}
                  height={"20px"}
                />
                <TextMenu className={active === m.path ? "active my-auto" : m.path === "academy" ? "linkdisabled" : ""}>
                  {t(m.text)}
                </TextMenu>
              </Menu>
              {sub}
            </div>
          );
        })}
      </MenuContain>
      {!Nav ? (
        <TimeLog>
          <div>
            <LanguageBox i18n={i18n} />
            <DateBoxContain>
              <DateBox />
            </DateBoxContain>
          </div>
          <ButtonLogout
            type={"button"}
            className={'btn btn"ld'}
            onClick={() => _logout()}
          >
            {t("logout")}
          </ButtonLogout>
        </TimeLog>
      ) : null}
      <ModalContact open={open} onCloseModal={()=>setOpen(false)} />
    </SidebarContain>
  );
}

export default Sidebar;
