import React from "react";
import styled from "styled-components";
import VideoAcademy from '../../components/Academy/VideoAcademy';
import config from "config/default.json";

const TitleBar = styled.div`
position: relative;
  display: flex;
  align-items: center;
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
  padding: 7px 15px;
  margin: 5px 0px;
  width: 100%;
  > span {
      font-weight: 600;
      padding-left: 9px;
  }
`;

const RowCustom = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0px 15px 0px;
  @media (max-width: 767px) {
    display: flex;
    width: 52.5vh;
  }  
  @media (width: 375px) and (height: 812px) {
    width: 43.5vh;
  }  
  @media (width: 411px) and (height: 823px) {
    width: 47vh;
  }
`;

const DivVideo = styled.div`
  height: 225px
  margin: 0 9px;
  @media (min-width: 768px) and (max-width: 1059px) {
    width: 49%;
    height: 214px;
    margin: 0 0 0 9px;
  }  
  @media (max-width: 767px) {
    width: 100%;
    height: 175px;
    margin: 10px 0px 10px 9px;
  }   
`;

const CardJoin = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: 100%;
  color: #828282;
  width: 380px;
  font-size: 15px;

  .aff-subject {
    font-size: 20px;
    font-weight: 600;
    color: #212529;
    letter-spacing: 1.5px;
    line-height: 2;
  }
  .aff-title {
    font-weight: 600;
    font-size: 16px;
    color: #af8c50;
  }
  .aff-subtitle {
    font-weight: 600;
    font-size: 16px;
    color: #212529;
  }

  @media (max-width: 931px) {
    padding: 10px;
    font-size: 14px;
    width: 100%;
  }  
 
`;

const DivCardJoin = styled.div`
  height: 228px
  margin: 0 4px;
  @media (min-width: 768px) and (max-width: 1059px) {
    width: 49%;
    height: 217px;
    margin: 0 0 0 4px;
  }  
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    margin: 10px 0px 10px 9px;
  } 
`;

const ButtonJoin = styled.a`
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #FFFFFF;
  border-color: transparent;
  background-color: #b48b4d;
  padding: 0.275rem .75rem;
  width: 130px
  margin: 10px auto 0;
  transition: all .3s ease 0s;

  &:hover {
    color: #b48b4d;
    background-color: transparent;
    border-color: #b48b4d;
    box-shadow: unset;
    transform: scale(1.005);
  }
  
  @media (max-width: 767px) {
    position: unset;
  }  
`;


function BoxAcademy({ t }) {
  return (
    <React.Fragment>
      <TitleBar>
        <img src={'/icon/academy_gray.svg'} alt={'icon'} width={'25px'} height={'20px'} />
        <span> Hiaffacademy </span>
      </TitleBar>
      <RowCustom>
        <DivVideo>
          <VideoAcademy />
        </DivVideo>
        <DivCardJoin>
          <CardJoin>
            <div className={'aff-subject'}>{t('aff-academy-subject')}</div>
            <div className={'aff-title'}>{t('aff-academy-title')}</div>
            <div className={'aff-subtitle'}>{t('aff-academy-subtitle')}</div>
            <div className={'aff-desc1'}>{t('aff-academy-desc1')}</div>
              <ButtonJoin  href={config.CONTACT_LINE} target="_blank" className={'btn'}>{t('aff-academy-joinnow')}</ButtonJoin>          
          </CardJoin>
        </DivCardJoin>
      </RowCustom>
    </React.Fragment>
  );
}

export default BoxAcademy;
