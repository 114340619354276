import React from 'react';
import config from "config/default.json";

function Dashboard() {
    return (
        <div className={'overflowpage'}>
            <div className="jumbotron mt-3">
                <h1 className="display-4">{config.DASHBOARD_TITLE}</h1>
                <p className="lead">{config.DASHBOARD_SUBTITLE}<b>{config.OWNER}</b></p>
                <hr className="my-4" />
                <p>{config.DASHBOARD_CONTENT}</p>
            </div>
        </div>
    )
}

export default Dashboard;