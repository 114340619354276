import React from 'react';
import PropTypes from 'prop-types';

const CurrencyColor = (value,colorFix) => {
	let colorCurrent = 'text__dark'
	if(value !== 0){
		colorCurrent = value < 0 ? 'text__danger' : 'text__success'
	}
	if(colorFix){colorCurrent = "text__danger"}
	return <span className={colorCurrent}>{!value ? 0 : new Intl.NumberFormat('en-US', { minimumFractionDigits: 1 }).format(value.toFixed(2))}</span>;
};

CurrencyColor.propTypes = {
    text: PropTypes.string
};

export default CurrencyColor;