import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import fetch from 'isomorphic-unfetch';
import { getSession } from '../../../utils/login';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
// import currencyTransform from '../../../utils/currency';
import moment from 'moment';
import FilterFinances from '../../../components/FilterBuilder/FilterFinances/FilterFinances'
import EarningChartExpensess from '../../../components/Chart/EarningChart/EarningChartExpensess'
import EarningChartExpensessSlot from '../../../components/Chart/EarningChart/EarningChartExpensessSlot'
import EarningChartExpensessRebate from '../../../components/Chart/EarningChart/EarningChartExpensessRebate'
import ExpensesTable from '../../../components/Table/FinancesTable/ExpensesTable'
import ExpensesTableSlot from '../../../components/Table/FinancesTable/ExpensesTableSlot'
import ExpensesTableRebate from '../../../components/Table/FinancesTable/ExpensesTableRebate'
import config from "config/default.json"

const TitleBar = styled.div`
    background-color: #313131;
    height: 35px;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 5px;
    color: #FFFFFF;
    border-radius: 5px;
    margin-top: 10px;
    @media (max-width: 767px) {
        margin-top: 80px;
    }
`;

const ItemBox = styled.div`
    background-color: #FFF;
    height: ${props => props.primary ? '55.45px' : '35px'};
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    align-items: center;
    font-size: 14px;
`;

const ItemTop = styled.div`
    color: #131212;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
`;

const ItemBottom = styled.div`
    border: 1px solid #dee2e6;
    width: 150px;
    text-align: center;
    border-radius: 5px;
    height: 23px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: ${ props => props.color };
    border-color: #AF8C50;
    margin-bottom: 5px;
    & > span {
        color: #828282;
        font-size: 12px;
    }
`;


const BlogState = styled.div`
    background-color: #FFF;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
`

function Expensess ({t}) {

    const [ financesData, setData ] = useState({
        pendingOrder: 0,
        completeOrder: 0,
        pendingExpenses: 0,
        rejectedTotal: 0,
        completeTotal: 0,
        rejectedOrder: 0,
        pendingTotal: 0,
        completeExpenses: 0,
        rejectedExpenses: 0,
        slotCount: 0,
        slotAmount: 0,
        slotRevenue: 0
    });

    const [chart, setChart] = useState([])
    const [contentState, setContentState] = useState('')
    const [agentCode, setAgentCode] = useState('')
    const [earningState, setEarningState] = useState('chart')
    const [monthState, setMonthState] = useState(3)
    const [tabActive, setTabActive] = useState('REWARD')

    useEffect(function() {
        const session = getSession();
        if (session) {
            setAgentCode(session.agentCode)
            if(tabActive === "REWARD"){
                _fetchData(session.agentCode)
            }else if(tabActive === "EVENT"){
                _fetchDataSlot(session.agentCode)
            }else if(tabActive === "REBATE"){
                _fetchDataRebate(session.agentCode)
            }
        }
    }, [earningState,monthState]);

    async function _fetchData (agentCode) {
        const bodySet = {
            agentCode: agentCode,
            date: moment().format('MMYYYY')
        };
        const response =  await fetch(config.URL_API + '/getProportionalExpenses', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            let arrayData = {};
            if (data.response_result) {
                const result = data.response_data;
                for (let key in result) {
                    arrayData[key] = result[key];
                }
                setData(arrayData);
                _fetchChart(agentCode)
            }
        }
    }

    async function _fetchDataSlot (agentCode) {
        const bodySet = {
            agentCode: agentCode,
            date: moment().format('MMYYYY')
        };
        const response =  await fetch(config.URL_API + '/getProportionalExpensesSlot', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            let arrayData = {};
            if (data.response_result) {
                const result = data.response_data;
                for (let key in result) {
                    arrayData[key] = result[key];
                }
                setData(arrayData);
                _fetchChartSlot(agentCode)
            }
        }
    }

    async function _fetchDataRebate (agentCode) {
        const bodySet = {
            agentCode: agentCode,
            date: moment().format('MMYYYY')
        };
        const response =  await fetch(config.URL_API + '/getProportionalExpensesRebate', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            let arrayData = {};
            if (data.response_result) {
                const result = data.response_data;
                for (let key in result) {
                    arrayData[key] = result[key];
                }
                setData(arrayData);
                _fetchChartRebate(agentCode)
            }
        }
    }

    const changedState = (text) => {
        setEarningState(text)
    }

    const changedMonth = text =>{
        setMonthState(text)
    }

    async function _fetchChart(agentCode) {
        const bodySet = {
            agentCode: agentCode,
            size: monthState
        };
        const response = await fetch(config.URL_API + '/getProportionalExpensesState', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            if (data.response_result) {
                let chartState =  earningState === "chart" ? <BlogState><EarningChartExpensess data={data.response_data.listMonth} /></BlogState> : <ExpensesTable data={data.response_data.listMonth} />
                setContentState(chartState)
                setChart(data.response_data.listMonth)
            }
        }
    }

    async function _fetchChartSlot(agentCode) {
        const bodySet = {
            agentCode: agentCode,
            size: monthState
        };
        const response = await fetch(config.URL_API + '/getProportionalExpensesSlotState', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            if (data.response_result) {
                let chartState =  earningState === "chart" ? <BlogState><EarningChartExpensessSlot data={data.response_data.listMonth} /></BlogState> : <ExpensesTableSlot data={data.response_data.listMonth} />
                setContentState(chartState)
                setChart(data.response_data.listMonth)
            }
        }
    }

    async function _fetchChartRebate(agentCode) {
        const bodySet = {
            agentCode: agentCode,
            size: monthState
        };
        const response = await fetch(config.URL_API + '/getProportionalExpensesRebateState', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodySet)
        });
        if (response.ok) {
            const data = await response.json();
            if (data.response_result) {
                let chartState =  earningState === "chart" ? <BlogState><EarningChartExpensessRebate data={data.response_data.listMonth} /></BlogState> : <ExpensesTableRebate data={data.response_data.listMonth} />
                setContentState(chartState)
                setChart(data.response_data.listMonth)
            }
        }
    }

    const activeBtn = (e) => {
        if(e === "REWARD"){
            _fetchData(agentCode)
        }else if(e === "EVENT"){
            _fetchDataSlot(agentCode)
        }else if(e === "REBATE"){
            _fetchDataRebate(agentCode)
        }
        setTabActive(e)
    }

    return (
        <div>
            <Breadcrumb title={'Proportional Expenses'} onClick={(e) => activeBtn(e)} tabActive={tabActive}/>
            <TitleBar>{t('current-month')}</TitleBar>
            {(() => {
                switch(tabActive) {
                    case "REWARD":
                        return <>
                            <div className={'row no-gutters'}>
                                <div className={'col-4 col-md-4 pr-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('order-completed')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.completeOrder}<span className={'float-right pr-1'} color={'black'}>{t('list')}</span></ItemBottom>
                                    </ItemBox>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('order-pending')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.pendingOrder}<span className={'float-right pr-1'} color={'black'}>{t('list')}</span></ItemBottom>
                                    </ItemBox>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('order-reject')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.rejectedOrder}<span className={'float-right pr-1'} color={'black'}>{t('list')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                                <div className={'col-4 col-md-4 pl-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('total-expenses')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.completeTotal}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('pending-expensess')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.pendingTotal}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('reject-expenses')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.rejectedTotal}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                                <div className={'col-4 col-md-4 pl-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('proportional-expenses')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.completeExpenses}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('unrealized-expenses')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.pendingExpenses}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('cost-canceled')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.rejectedExpenses}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                            </div>
                            <div className="mt-3 mb-5">
                                <FilterFinances changedState={(text) => changedState(text)} changedMonth={(text) => changedMonth(text)} monthState={monthState} EarningState={earningState} title={t('proportional-expenses')} t={t} />
                                {contentState}
                            </div>
                        </>
                    case "EVENT":
                        return <>
                            <div className={'row no-gutters'}>
                                <div className={'col-4 col-md-4 pr-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('item-number')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.slotCount}<span className={'float-right pr-1'} color={'black'}>{t('list')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                                <div className={'col-4 col-md-4 pl-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('total-cost')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.slotAmount}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                                <div className={'col-4 col-md-4 pl-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('proportional-expenses')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.slotRevenue}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                            </div>
                            <div className="mt-3 mb-5">
                                <FilterFinances changedState={(text) => changedState(text)} changedMonth={(text) => changedMonth(text)} monthState={monthState} EarningState={earningState} title={t('proportional-expenses')} t={t} />
                                {contentState}
                            </div>
                     </>
                     case "REBATE":
                        return <>
                            <div className={'row no-gutters'}>
                                <div className={'col-4 col-md-4 pr-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('item-number')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.rebateAmount}<span className={'float-right pr-1'} color={'black'}>{t('list')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                                <div className={'col-4 col-md-4 pl-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('total-cost')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.rebateCount}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                                <div className={'col-4 col-md-4 pl-1'}>
                                    <ItemBox primary={true}>
                                        <ItemTop className={'text-capitalize'}>{t('proportional-expenses')}</ItemTop>
                                        <ItemBottom color={'#AF8C50'}>{financesData.rebateRevenue}<span className={'float-right pr-1'} color={'black'}>{t('thb')}</span></ItemBottom>
                                    </ItemBox>
                                </div>
                            </div>
                            <div className="mt-3 mb-5">
                                <FilterFinances changedState={(text) => changedState(text)} changedMonth={(text) => changedMonth(text)} monthState={monthState} EarningState={earningState} title={t('proportional-expenses')} t={t} />
                                {contentState}
                            </div>
                     </>
                    }
                })()
            }
           
        </div>
    );
}

export default Expensess;