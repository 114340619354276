import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomBadge = styled.div`
    color: ${ props => props.color };
    border: 1px solid ${ props => props.color };
    border-radius: 5px;
    padding: 5px 0px;
`;

function MemberBadge ({ text, color }) {
    return (
        <CustomBadge color={color}>{ text }</CustomBadge>
    );
}

MemberBadge.propTypes = {
    text: PropTypes.string
};

export default MemberBadge;